package com.meistercharts.charts.lizergy.modulePlanning

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.algorithms.painter.fillAndStroke
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.DomainRelative
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.axis.OrientationAwareDirection
import com.meistercharts.axis.OrientationAwareDirection.Companion.calculate
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.canvas.events.CanvasKeyEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandlerBroker
import com.meistercharts.canvas.layout.cache.BoundsMultiCache
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.resize.ResizeHandler
import com.meistercharts.canvas.resizeHandlesSupport
import com.meistercharts.canvas.saved
import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.ModuleAreaPainter
import com.meistercharts.charts.lizergy.roofPlanning.PvElementType
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningLayer
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.roofPlanning.Resizable
import com.meistercharts.charts.lizergy.roofPlanning.RoofRelative
import com.meistercharts.charts.lizergy.roofPlanning.RoofSelection
import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import com.meistercharts.charts.lizergy.roofPlanning.UnusableAreaPainter
import com.meistercharts.color.Color
import com.meistercharts.color.withAlpha
import com.meistercharts.events.EventConsumption
import com.meistercharts.events.EventConsumption.Consumed
import com.meistercharts.events.EventConsumption.Ignored
import com.meistercharts.events.gesture.CanvasDragSupport
import com.meistercharts.events.gesture.connectedMouseEventHandler
import com.meistercharts.range.LinearValueRange
import com.meistercharts.resources.Icons
import it.neckar.events.KeyCode
import it.neckar.events.KeyStroke
import it.neckar.events.KeyUpEvent
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Distance
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.kotlin.lang.getAndSet
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm
import it.neckar.open.unit.si.ms
import kotlin.contracts.contract

/**
 * Layer that supports planning of  photo voltaic modules for *one* roof
 */
class PvModulePlanningLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(pvRoofPlanningLayer: PvRoofPlanningLayer, additionalConfiguration: Configuration.() -> Unit = {}) : this(
    model = pvRoofPlanningLayer.configuration.model,
    getValueRangeX = pvRoofPlanningLayer::getValueRangeX,
    getValueRangeY = pvRoofPlanningLayer::getValueRangeY,
    getModuleAreaAtLocation = pvRoofPlanningLayer::getModuleAreaAtLocation,
    getBoundsForModuleArea = pvRoofPlanningLayer::getBoundsForModuleArea,
    getBoundsForModule = pvRoofPlanningLayer::getBoundsForModule,
    getUnusableAreaAtLocation = pvRoofPlanningLayer::getUnusableAreaAtLocation,
    getBoundsForUnusableArea = pvRoofPlanningLayer::getBoundsForUnusableArea,
    additionalConfiguration = additionalConfiguration,
  )

  constructor(
    model: PvRoofPlanningModel,
    getValueRangeX: () -> LinearValueRange,
    getValueRangeY: () -> LinearValueRange,
    getModuleAreaAtLocation: (@Window Coordinates) -> ModuleArea?,
    getBoundsForModuleArea: (ModuleArea) -> @Window Rectangle,
    getBoundsForModule: (module: Module) -> @Window Rectangle,
    getUnusableAreaAtLocation: (@Window Coordinates) -> UnusableArea?,
    getBoundsForUnusableArea: (UnusableArea) -> @Window Rectangle,
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(
    Configuration(
      model = model,
      getValueRangeX = getValueRangeX,
      getValueRangeY = getValueRangeY,
      getModuleAreaAtLocation = getModuleAreaAtLocation,
      getBoundsForModuleArea = getBoundsForModuleArea,
      getBoundsForModule = getBoundsForModule,
      getUnusableAreaAtLocation = getUnusableAreaAtLocation,
      getBoundsForUnusableArea = getBoundsForUnusableArea,
    ), additionalConfiguration
  )

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  /**
   * The drag support is registered as first mouse handler
   */
  private val dragSupport: CanvasDragSupport = CanvasDragSupport().also {
    it.handle(object : CanvasDragSupport.Handler {
      override fun isDraggingAllowedFromHere(source: CanvasDragSupport, location: @Window Coordinates, chartSupport: ChartSupport): Boolean {
        //Find possible elements that can be dragged

        //Check for unusable areas
        configuration.getUnusableAreaAtLocation(location)?.let { unusableArea ->
          uiState = uiState.startDragging(unusableArea)
          return true
        }

        //Check for module grids
        configuration.getModuleAreaAtLocation(location)?.let { moduleArea ->
          uiState = uiState.startDragging(moduleArea)
          return true
        }

        //Nothing found to be dragged
        return false
      }

      override fun onDrag(source: CanvasDragSupport, @Window location: Coordinates, @Zoomed distance: Distance, @ms deltaTime: Double, chartSupport: ChartSupport): EventConsumption {
        uiState.let { uiState ->
          require(uiState is Dragging) {
            "Invalid state. Expected Dragging but was <$uiState>"
          }

          @RoofRelative @mm val deltaXDomain = configuration.getValueRangeX().deltaToDomain(chartSupport.chartCalculator.zoomedDelta2domainRelativeX(distance.x))
          @RoofRelative @mm val deltaYDomain = configuration.getValueRangeY().deltaToDomain(chartSupport.chartCalculator.zoomedDelta2domainRelativeY(distance.y))

          val model = configuration.model

          when (uiState.activeElementType) {
            PvElementType.ModuleArea -> {
              val area = if (uiState.clickableNonNull is Module) (uiState.clickableNonNull as Module).modulePlacement.moduleArea else uiState.clickableNonNull as ModuleArea
              area::location.getAndSet { oldValue ->
                oldValue.plus(
                  deltaXDomain, deltaYDomain
                ).coerceIn(
                  minimum = model.suggestedRoofInsets.bottomLeft,
                  maximumX = model.roofSize.width - model.suggestedRoofInsets.right - area.size.width,
                  maximumY = model.roofSize.height - model.suggestedRoofInsets.top - area.size.height,
                )
              }

              area.fillGrid(model.modulesSize)
            }

            PvElementType.UnusableArea -> {
              val unusableArea = uiState.clickableNonNull as UnusableArea
              unusableArea::location.getAndSet { oldValue ->
                oldValue.plus(
                  deltaXDomain, deltaYDomain
                ).coerceIn(
                  minimum = model.suggestedRoofInsets.bottomLeft,
                  maximumX = model.roofSize.width - model.suggestedRoofInsets.right - unusableArea.size.width,
                  maximumY = model.roofSize.height - model.suggestedRoofInsets.top - unusableArea.size.height,
                )
              }

              model.updateModuleAreas(model.modulesSize)
            }
          }

          chartSupport.markAsDirty(DirtyReason.UserInteraction)
          return Consumed
        }
      }

      override fun onFinish(source: CanvasDragSupport, location: Coordinates, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        return Consumed
      }
    })
  }

  override val mouseEventHandler: CanvasMouseEventHandler = CanvasMouseEventHandlerBroker().also { broker ->
    /**
     * Handle the actions first
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        //This method is called *after* the dragging events have been handled
        val coordinates = event.coordinates
        val model = configuration.model

        //check if over delete area of any unusable area
        ifUnusableAreaDeleteHit(coordinates) { index ->
          uiState = uiState.downOnAction(model.unusableAreas[index])
          return Consumed
        }

        ifModuleDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(model.modules()[index])
          return Consumed
        }

        ifModuleAreaDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(model.moduleAreas[index])
          return Consumed
        }

        ifModuleAreaRotateHit(coordinates) { index ->
          //mouse over delete button of module
          uiState = uiState.downOnAction(model.moduleAreas[index])
          return Consumed
        }

        return Ignored
      }
    })

    /**
     * Handle dragging now
     */
    dragSupport.connectedMouseEventHandler().let {
      broker.delegate(it)
    }

    /**
     * All other events are handled here
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
        val coordinates = event.coordinates

        if (coordinates == null) {
          //Moved out of the window
          uiState = uiState.movedOutOfCanvas()
          return Ignored
        }

        val model = configuration.model

        ifModuleAreaRotateHit(coordinates) { index ->
          //mouse over rotate button of module area
          val module = model.moduleAreas[index]
          uiState = uiState.hoveringOverRotateAction(module)
          return Consumed
        }

        //check if over delete area of any unusable area
        ifUnusableAreaDeleteHit(coordinates) { index ->
          val unusableArea = model.unusableAreas[index]
          uiState = uiState.hoveringOverDeleteAction(unusableArea)
          return Consumed
        }

        ifModuleDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          val module = model.modules()[index]
          uiState = uiState.hoveringOverDeleteAction(module)
          return Consumed
        }

        ifModuleAreaDeleteHit(coordinates) { index ->
          //mouse over delete button of module
          val module = model.moduleAreas[index]
          uiState = uiState.hoveringOverDeleteAction(module)
          return Consumed
        }

        //check if over unusableArea
        configuration.getUnusableAreaAtLocation(coordinates)?.let { unusableArea ->
          uiState = uiState.hoveringOver(unusableArea)
          return Consumed
        }

        //check if over module
        configuration.getModuleAreaAtLocation(coordinates)?.let { moduleArea ->
          //mouse over module
          uiState = uiState.hoveringOver(moduleArea)
          return Consumed
        }

        //Nothing found under the mouse
        uiState = uiState.moveOverNothing()
        return Consumed
      }

      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        //This method is called *after* the dragging events have been handled
        //All downs on modules and unusable areas have already been consumed
        //remove the selection if necessary
        uiState = uiState.downOnNothing()
        return Ignored
      }

      override fun onUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        //TODO return Consumed???
        return super.onUp(event, chartSupport)
      }
    })
  }

  /**
   * Executes the given lambda if hovering above an unusable area but *within* the delete action
   */
  private inline fun ifUnusableAreaDeleteHit(location: @Window Coordinates, action: (unusableAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    paintingVariables().unusableAreaDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Executes the given lambda if hovering above a module grid but *within* the action area
   */
  private inline fun ifModuleAreaDeleteHit(location: @Window Coordinates, action: (moduleAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    paintingVariables().moduleAreasDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Executes the given lambda if hovering above a module grid but *within* the action area
   */
  private inline fun ifModuleAreaRotateHit(location: @Window Coordinates, action: (moduleAreaIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    paintingVariables().moduleAreaRotateButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Executes the given lambda if hovering above a module but *within* the action area
   */
  private inline fun ifModuleDeleteHit(location: @Window Coordinates, action: (moduleIndex: Int) -> Unit) {
    contract {
      callsInPlace(action, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
    }

    paintingVariables().moduleDeleteButtonsBounds.findLastIndex(location)?.let { foundIndex ->
      action(foundIndex)
    }
  }

  /**
   * Contains the current ui state
   */
  private var uiStateProperty: ObservableObject<RoofPlanningUiState> = ObservableObject(DefaultState(configuration, RoofSelection.Companion.empty))
  var uiState: RoofPlanningUiState by uiStateProperty
    private set

  override val keyEventHandler: CanvasKeyEventHandler = object : CanvasKeyEventHandler {
    override fun onUp(event: KeyUpEvent, chartSupport: ChartSupport): EventConsumption {
      if (event.keyStroke == KeyStroke(KeyCode.Delete)) {
        //Delete if a module has been selected
        val model = configuration.model
        model.moduleAreas.removeAll { uiState.roofSelection.isSelected(it) }

        //Delete if an unusable area has been selected
        val unusableAreas = model.unusableAreas.unusableAreas
        unusableAreas.fastForEach { unusableArea ->
          val selected = uiState.roofSelection.isSelected(unusableArea)
          if (selected) {
            model.removeUnusableArea(unusableArea)
          }
        }

        uiState = DefaultState(configuration, RoofSelection.Companion.empty)
        chartSupport.markAsDirty(DirtyReason.UserInteraction)
        return Consumed
      }

      return Ignored
    }
  }

  override fun initialize(paintingContext: LayerPaintingContext) {
    val chartSupport = paintingContext.chartSupport

    //Update the mouse cursor depending on the state
    uiStateProperty.consumeImmediately {

      //Update the mouse cursor
      chartSupport.cursor = when (it) {
        is DefaultState, is HoveringAndButtonsVisible, is HoveringOverDeletedGridModuleAddButtonVisible, is Resizing -> null
        is ArmedForDelete, is ArmedForRotate, is ArmedForAdd -> MouseCursor.Hand
        is Dragging -> MouseCursor.Move
      }

      //Always mark as dirty on state change
      chartSupport.markAsDirty(DirtyReason.UiStateChanged)

      //TODO update the selection model
      //data.selectionModel.select(data.roofPlanningModel.unusableAreas[unusableAreaIndex])
    }

    chartSupport.resizeHandlesSupport.onResize(this, object : ResizeHandler {
      override fun beginResizing(handleDirection: Direction) {
        val resizable = uiState.roofSelection.resizable
        requireNotNull(resizable) {
          "Invalid state - expected selected Resizable"
        }
        uiState = uiState.startResizing(resizable)
      }

      override fun resizingFinished() {
        uiState = uiState.resizingFinished()
      }

      override fun resizing(rawDistance: Distance, handleDirection: Direction, deltaX: Double, deltaY: Double) {
        val model = configuration.model
        uiState.let { uiState ->
          val resizable = uiState.roofSelection.selection as Resizable

          val chartCalculator = chartSupport.chartCalculator
          @DomainRelative val deltaXDomainRelative = chartCalculator.zoomedDelta2domainRelativeX(deltaX)
          @DomainRelative val deltaYDomainRelative = chartCalculator.zoomedDelta2domainRelativeY(deltaY)

          @Domain val deltaXDomain = configuration.getValueRangeX().deltaToDomain(deltaXDomainRelative)
          @Domain val deltaYDomain = configuration.getValueRangeY().deltaToDomain(deltaYDomainRelative)

          //Calculate the delta for the location and resize - depending on the axis orientation and handle direction
          val directionX: OrientationAwareDirection = OrientationAwareDirection.calculate(chartCalculator.chartState.axisOrientationX, handleDirection.horizontalAlignment)
          val directionY: OrientationAwareDirection = OrientationAwareDirection.calculate(chartCalculator.chartState.axisOrientationY, handleDirection.verticalAlignment)

          val deltaLocationX: @Domain Double
          val deltaLocationY: @Domain Double
          val resizeX: @Domain Double
          val resizeY: @Domain Double

          if (directionX == OrientationAwareDirection.TowardsSmaller) {
            deltaLocationX = deltaXDomain
            resizeX = -deltaXDomain
          } else {
            deltaLocationX = 0.0
            resizeX = deltaXDomain
          }

          if (directionY == OrientationAwareDirection.TowardsSmaller) {
            deltaLocationY = deltaYDomain
            resizeY = -deltaYDomain
          } else {
            deltaLocationY = 0.0
            resizeY = deltaYDomain
          }

          resizable.updateSizeAndLocation(deltaLocationX, deltaLocationY, resizeX, resizeY, model)
          model.updateModuleAreas(model.modulesSize)
          chartSupport.markAsDirty(DirtyReason.UserInteraction)
        }
      }
    })
  }

  override fun layout(paintingContext: LayerPaintingContext) {
    super.layout(paintingContext)

    //Register the resizable area as resizable
    val resizeHandlesSupport = paintingContext.chartSupport.resizeHandlesSupport

    uiState.roofSelection.resizable?.let { resizable ->
      val resizingRect = when (resizable) {

        is UnusableArea -> configuration.getBoundsForUnusableArea(resizable)

        is ModuleArea -> {
          val chartCalculator = paintingContext.chartCalculator
          val valueRangeX = configuration.getValueRangeX()
          val valueRangeY = configuration.getValueRangeY()

          val x = chartCalculator.domain2windowX(resizable.location.x, valueRangeX)
          val y = chartCalculator.domain2windowY(resizable.location.y, valueRangeY)
          val width = chartCalculator.domainDelta2zoomedX(resizable.size.width, valueRangeX)
          val height = chartCalculator.domainDelta2zoomedY(resizable.size.height, valueRangeY)

          Rectangle(x, y, width, height)
        }

      }
      resizeHandlesSupport.setResizable(this, resizingRect)
    } ?: resizeHandlesSupport.clear(this)
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    if (configuration.mode == Mode.Rendering) return

    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator

    //Paint all modules
    val model = configuration.model
    val modules = model.modules().visibleModules

    modules.fastForEachIndexed { index, module ->

      val bounds = configuration.getBoundsForModule(module)

      uiState.let { uiState ->
        when (uiState) {
          is DefaultState -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is HoveringAndButtonsVisible -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            } else if (uiState.matchesLoose(module)) {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              gc.fillAndStroke(configuration.hoverHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }

            //Paint the delete icon - only for the exact module
            if (uiState.clickable == module) {
              gc.saved {
                configuration.deleteIcon.paint(paintingContext, bounds.centerX, bounds.centerY)
              }
            }
          }

          is ArmedForDelete -> {
            if (uiState.clickable == module) {
              gc.fillAndStroke(if (module.deleted.not()) configuration.deleteArmedHighlightStyle else configuration.undeleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)

              gc.saved {
                if (module.deleted.not()) {
                  configuration.deleteIconArmed.paint(paintingContext, bounds.centerX, bounds.centerY)
                } else {
                  configuration.undeleteIconArmed.paint(paintingContext, bounds.centerX, bounds.centerY)
                }
              }
            } else if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is ArmedForRotate -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is Dragging -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            } else if (uiState.matchesLoose(module)) {
              gc.fillAndStroke(configuration.draggingHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is HoveringOverDeletedGridModuleAddButtonVisible -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is ArmedForAdd -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }

          is Resizing -> {
            if (module.deleted.not() && model.modules().overlaps(module)) {
              gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
              gc.fillRect(bounds)
              gc.strokeRect(bounds)
            }
          }
        }
      }

    }

    //Paint the unusable areas
    val unusableAreas = model.unusableAreas.unusableAreas
    unusableAreas.fastForEachIndexed { index, unusableArea ->

      gc.saved {
        //Identify the mode

        val selected = uiState.roofSelection.isSelected(unusableArea)
        val fallbackMode = if (selected) UnusableAreaPainter.Mode.Selected else null

        val mode = uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> fallbackMode

            is HoveringAndButtonsVisible -> {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              if (uiState.isActive(unusableArea)) {
                if (selected) {
                  UnusableAreaPainter.Mode.SelectedHovering
                } else {
                  UnusableAreaPainter.Mode.Hover
                }
              } else {
                fallbackMode
              }
            }

            is ArmedForDelete -> {
              if (uiState.isActive(unusableArea)) {
                UnusableAreaPainter.Mode.DeleteArmed
              } else {
                fallbackMode
              }
            }

            is ArmedForRotate -> fallbackMode

            is Dragging -> {
              if (uiState.isActive(unusableArea)) {
                UnusableAreaPainter.Mode.Dragging
              } else {
                fallbackMode
              }
            }

            is HoveringOverDeletedGridModuleAddButtonVisible -> fallbackMode

            is ArmedForAdd -> fallbackMode

            is Resizing -> fallbackMode
          }
        }

        mode?.let {
          val bounds = configuration.getBoundsForUnusableArea(unusableArea)
          configuration.unusableAreaPainter.paint(paintingContext, bounds.getX(), bounds.getY(), bounds.getWidth(), bounds.getHeight(), unusableArea, mode)
        }
      }
    }

    val moduleAreas = model.moduleAreas
    moduleAreas.moduleAreas.fastForEachIndexed { index, moduleArea ->

      gc.saved {
        //Identify the mode

        val selected = uiState.roofSelection.isSelected(moduleArea)
        val fallbackMode = if (selected) ModuleAreaPainter.Mode.Selected else ModuleAreaPainter.Mode.Invisible

        val mode: ModuleAreaPainter.Mode = uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> {
              fallbackMode
            }

            is HoveringAndButtonsVisible -> {
              //Over this module? Or somewhere else on the grid, if this is a grid module?
              if (selected) {
                ModuleAreaPainter.Mode.SelectedHovering
              } else {
                if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
              }
            }

            is ArmedForDelete -> {
              if (uiState.clickable == moduleArea) {
                ModuleAreaPainter.Mode.DeleteArmed
              } else {
                if (selected) {
                  ModuleAreaPainter.Mode.SelectedHovering
                } else {
                  if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
                }
              }
            }

            is ArmedForRotate -> {
              if (uiState.clickable == moduleArea) {
                ModuleAreaPainter.Mode.RotateArmed
              } else {
                if (selected) {
                  ModuleAreaPainter.Mode.SelectedHovering
                } else {
                  if (uiState.isActive(moduleArea)) ModuleAreaPainter.Mode.Hover else ModuleAreaPainter.Mode.Invisible
                }
              }
            }

            is Dragging -> {
              if (uiState.isActive(moduleArea)) {
                ModuleAreaPainter.Mode.Dragging
              } else {
                fallbackMode
              }
            }

            is HoveringOverDeletedGridModuleAddButtonVisible -> {
              fallbackMode
            }

            is ArmedForAdd -> {
              fallbackMode
            }

            is Resizing -> {
              fallbackMode
            }
          }
        }

        val bounds = configuration.getBoundsForModuleArea(moduleArea)
        configuration.moduleAreaPainter.paint(paintingContext, bounds.getX(), bounds.getY(), bounds.getWidth(), bounds.getHeight(), moduleArea, chartCalculator, mode)
      }
    }
  }

  override fun paintingVariables(): PvModulePlanningPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables: PvModulePlanningPaintingVariables = object : PvModulePlanningPaintingVariables {
    /**
     * The bounds for the delete buttons for the modules
     */
    override val moduleDeleteButtonsBounds: @Window BoundsMultiCache = BoundsMultiCache()

    /**
     * The bounds for the delete buttons for the unusable areas
     */
    override val unusableAreaDeleteButtonsBounds: @Window BoundsMultiCache = BoundsMultiCache()

    override val moduleAreasDeleteButtonsBounds: @Window BoundsMultiCache = BoundsMultiCache()

    override val moduleAreaRotateButtonsBounds: @Window BoundsMultiCache = BoundsMultiCache()


    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartCalculator = paintingContext.chartCalculator
      val model = configuration.model

      val valueRangeX = configuration.getValueRangeX()
      val valueRangeY = configuration.getValueRangeY()

      //The bounding box for the delete icon
      val deleteIconBoundingBox = configuration.deleteIcon.boundingBox(paintingContext)

      //Calculate the module bounds
      moduleDeleteButtonsBounds.ensureSize(model.modules().visibleModulesCount) //ensure array sizes
      model.modules().visibleModules.fastForEachIndexed { index, module ->
        val moduleLocation: @RoofRelative Coordinates = module.location

        val x = chartCalculator.domain2windowX(moduleLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(module.width.toDouble(), valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(module.height.toDouble(), valueRangeY)

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        moduleDeleteButtonsBounds.x(index, centerX + deleteIconBoundingBox.getX())
        moduleDeleteButtonsBounds.y(index, centerY + deleteIconBoundingBox.getY())
        moduleDeleteButtonsBounds.width(index, deleteIconBoundingBox.getWidth())
        moduleDeleteButtonsBounds.height(index, deleteIconBoundingBox.getHeight())
      }

      //Calculate the bounds for the unusable areas
      unusableAreaDeleteButtonsBounds.ensureSize(model.unusableAreas.count) //ensure array sizes
      model.unusableAreas.unusableAreas.fastForEachIndexed { index, unusableArea ->
        val unusableAreaLocation: @RoofRelative Coordinates = unusableArea.location

        val x = chartCalculator.domain2windowX(unusableAreaLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(unusableAreaLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(unusableArea.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(unusableArea.size.height, valueRangeY)

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        unusableAreaDeleteButtonsBounds.x(index, centerX + deleteIconBoundingBox.getX())
        unusableAreaDeleteButtonsBounds.y(index, centerY + deleteIconBoundingBox.getY())
        unusableAreaDeleteButtonsBounds.width(index, deleteIconBoundingBox.getWidth())
        unusableAreaDeleteButtonsBounds.height(index, deleteIconBoundingBox.getHeight())
      }

      val moduleAreaDeleteIconBoundingBox = configuration.moduleAreaPainter.configuration.deleteIcon.boundingBox(paintingContext)
      val moduleAreaRotateIconBoundingBox = configuration.moduleAreaPainter.configuration.rotateIcon.boundingBox(paintingContext)

      moduleAreasDeleteButtonsBounds.ensureSize(model.moduleAreas.count) //ensure array sizes
      moduleAreaRotateButtonsBounds.ensureSize(model.moduleAreas.count) //ensure array sizes
      model.moduleAreas.moduleAreas.fastForEachIndexed { index, moduleGrid ->
        val moduleGridLocation: @RoofRelative Coordinates = moduleGrid.location

        val x = chartCalculator.domain2windowX(moduleGridLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleGridLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(moduleGrid.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(moduleGrid.size.height, valueRangeY)

        //Calculate the buttons - in the center of the modules
        val centerX = x + width / 2.0
        val centerY = y + height / 2.0

        moduleAreasDeleteButtonsBounds.x(index, centerX + moduleAreaDeleteIconBoundingBox.getX())
        moduleAreasDeleteButtonsBounds.y(index, centerY + moduleAreaDeleteIconBoundingBox.getY())
        moduleAreasDeleteButtonsBounds.width(index, moduleAreaDeleteIconBoundingBox.getWidth())
        moduleAreasDeleteButtonsBounds.height(index, moduleAreaDeleteIconBoundingBox.getHeight())

        val rightX = x + width - moduleAreaRotateIconBoundingBox.getWidth()
        val topY = y + height + moduleAreaRotateIconBoundingBox.getHeight()

        moduleAreaRotateButtonsBounds.x(index, rightX + moduleAreaRotateIconBoundingBox.getX())
        moduleAreaRotateButtonsBounds.y(index, topY + moduleAreaRotateIconBoundingBox.getY())
        moduleAreaRotateButtonsBounds.width(index, moduleAreaRotateIconBoundingBox.getWidth())
        moduleAreaRotateButtonsBounds.height(index, moduleAreaRotateIconBoundingBox.getHeight())
      }
    }
  }

  /**
   * The mode
   */
  enum class Mode {
    /**
     * The roof is rendered (e.g. to display to the customer)
     */
    Rendering,

    /**
     * We are in planning mode - additional information is painted
     */
    Planning,
  }

  @ConfigurationDsl
  class Configuration(
    val model: PvRoofPlanningModel,
    val getValueRangeX: () -> @Window LinearValueRange,
    val getValueRangeY: () -> @Window LinearValueRange,
    val getModuleAreaAtLocation: (@Window Coordinates) -> ModuleArea?,
    val getBoundsForModuleArea: (ModuleArea) -> @Window Rectangle,
    val getBoundsForModule: (module: Module) -> @Window Rectangle,
    val getUnusableAreaAtLocation: (@Window Coordinates) -> UnusableArea?,
    val getBoundsForUnusableArea: (UnusableArea) -> @Window Rectangle,
  ) {
    /**
     * The current mode
     */
    var mode: Mode = Mode.Planning //TODO remove mouse over effects when rendering!

    /**
     * The line style for the roof insets
     */
    val suggestedRoofInsets: LineStyle = LineStyle(Color.red)

    /**
     * The style when the mouse over an element
     */
    val hoverHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.web("#75b72633"), Color.web("#75b726"))

    /**
     * When hovering above the delete button
     */
    val deleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.red().withAlpha(0.3), Color.red())

    val undeleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.green().withAlpha(0.3), Color.green())

    /**
     * Highlighting during drag operation
     */
    val draggingHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.white.withAlpha(0.2), Color.white)

    /**
     * The delete icon that is painted on hover
     */
    val deleteIcon: Paintable = Icons.delete(Size.PX_24)

    /**
     * The delete icon while hovering above it
     */
    val deleteIconArmed: Paintable = Icons.delete(Size.PX_24, Color.red)

    val undeleteIconArmed: Paintable = Icons.delete(Size.PX_24, Color.green)

    /**
     * The paintable for unusable areas
     */
    var unusableAreaPainter: UnusableAreaPainter = UnusableAreaPainter()

    var moduleAreaPainter: ModuleAreaPainter = ModuleAreaPainter()
  }
}
