package components.project.configuration.layout.strings

import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.addClass
import it.neckar.react.common.form.EditableStatus
import it.neckar.react.common.form.EditableStatus.ReadOnly
import it.neckar.react.common.setter
import it.neckar.react.common.value
import kotlinx.html.DIV
import kotlinx.html.ThScope
import kotlinx.html.js.onClickFunction
import react.StateInstance
import react.dom.RDOMBuilder
import react.dom.attrs
import react.dom.b
import react.dom.button
import react.dom.p
import react.dom.span
import react.dom.table
import react.dom.tbody
import react.dom.td
import react.dom.th
import react.dom.thead
import react.dom.tr

fun RDOMBuilder<DIV>.editFacilityConfiguration(
  moduleLayouts: List<ResolvedModuleLayout>,
  inverterSelection: ResolvedInverterSelection,
  inverterConfigurationsToSave: StateInstance<List<ResolvedInverterConfiguration>>,
  editInverterConfigurations: StateInstance<Boolean>,
  editableStatus: EditableStatus,
) {
  val numberOfModules = moduleLayouts.sumOf { it.modulesCount }
  val numberOfOptimalModules = inverterConfigurationsToSave.value.sumOf { it.numberOfOptimalModules }

  table("table table-hover table-responsive align-middle") {
    thead {
      tr {
        th(ThScope.col, "text-center align-middle") {
          attrs {
            colSpan = "4"
          }

          button(classes = "btn") {
            attrs {
              onClickFunction = {
                editInverterConfigurations.setter { !it }
              }
              disabled = editableStatus == ReadOnly
            }

            if (editInverterConfigurations.value) {
              addClass("btn-primary")
              +"Optimale Module bestätigen"
            } else {
              addClass("btn-outline-primary")
              +"Optimale Module bearbeiten"
            }
          }

          if (numberOfOptimalModules != numberOfModules) {
            p("form-text text-danger") {
              +"Die Anzahl der geplanten Module (${numberOfOptimalModules}) stimmt nicht mit der Anzahl der Module auf den Dächern (${numberOfModules}) überein."
            }
          }
        }

        moduleLayouts.fastForEach { moduleLayout ->
          val facilityConfigurationForLayout = inverterConfigurationsToSave.value.map { it.forTheseLayouts(listOf(moduleLayout.id)) }
          val numberOfPlannedModules = facilityConfigurationForLayout.sumOf { it.numberOfPlannedModules }

          th(ThScope.col) {
            p {
              +moduleLayout.layoutName
              span("ms-2") {
                if (numberOfPlannedModules == moduleLayout.modulesCount) {
                  addClass("text-success")
                  faCircleCheck()
                } else {
                  addClass("text-danger")
                  faCircleExclamation()
                }
              }
            }
            p {
              span("form-text") { +"$numberOfPlannedModules / ${moduleLayout.modulesCount} Module geplant" }
            }
          }
        }
      }
    }

    tbody {

      inverterConfigurationsToSave.value.fastForEachIndexed { inverterIndex, inverterConfiguration ->
        val inverter = inverterConfiguration.inverter

        tr {
          th(ThScope.row) {
            +"${inverterIndex + 1}"
          }

          td {
            attrs {
              colSpan = "3"
            }

            b("me-2") {
              +inverter.description
            }
            if (inverter is Inverter && inverterSelection[inverter] > 1) +"#${inverterConfiguration.inverterIndex + 1}"
          }

          moduleLayouts.fastForEach {
            td {}
          }
        }

        inverterConfiguration.mpptInputConfigurations.fastForEachIndexed { mpptInputIndex, mpptInputConfiguration ->

          tr {
            td {}
            th(ThScope.row) {
              +"${'A' + mpptInputConfiguration.inputIndex}"
            }

            td {
              attrs {
                colSpan = "2"
              }

              b("me-2") {
                +"MPP-Eingang"
              }
              +"${mpptInputConfiguration.inputIndex + 1}"

              if (mpptInputConfiguration.hasLegalOptimalModuleCount.not()) {
                p("form-text text-danger") {
                  +"Pro MPP-Eingang müssen alle Strings die gleiche Anzahl an Modulen haben oder leer sein."
                }
              }
            }

            moduleLayouts.fastForEach {
              td {}
            }
          }


          mpptInputConfiguration.stringConfigurations.fastForEachIndexed { stringIndex, stringConfiguration ->

            tr {
              td {
                attrs {
                  colSpan = "2"
                }
              }
              th(ThScope.row) {
                +"${stringIndex + 1}"
              }

              td {
                EditInverterConfiguration {
                  attrs {
                    this.inverterConfigurationsToSave = inverterConfigurationsToSave
                    this.stringConfiguration = stringConfiguration
                    this.inverter = inverter
                    this.inverterIndex = inverterConfiguration.inverterIndex
                    this.inputIndex = mpptInputConfiguration.inputIndex
                    this.multipleStrings = mpptInputConfiguration.stringConfigurations.size > 1
                    this.first = stringIndex == 0
                    this.last = stringIndex == mpptInputConfiguration.stringConfigurations.size - 1
                    this.editableStatus = editableStatus
                  }
                }
              }

              moduleLayouts.fastForEach { moduleLayout ->
                val modulesString = stringConfiguration.modulesStrings?.firstOrNull { it.moduleLayoutId == moduleLayout.id }

                td {
                  span("form-text") {
                    if (modulesString != null) {
                      +"${modulesString.numberOfModules} Module"
                    } else {
                      +"-"
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
