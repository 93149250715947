package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleAreas
import com.meistercharts.charts.lizergy.stringsPlanning.ModuleReference
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.charts.lizergy.stringsPlanning.RoofString
import com.meistercharts.charts.lizergy.stringsPlanning.StringIndex
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString.StringId
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.toRestApiModel
import kotlinx.serialization.Serializable
import kotlin.Int

/**
 * Serializable model for the roof planning model
 * This model contains exactly one plan for one roof.
 *
 * It can be converted from/to a [PvStringsPlanningModel] using methods defined in this class ([toRestApiModel] and [toMutableModel]/[applyToMutableModel])
 */
@Serializable
data class PvStringsPlanningModelInformation(val inverterConfigurations: List<ResolvedInverterConfiguration>) {
  /**
   * Creates a new planning model with the same settings
   */
  fun toMutableModel(forModuleLayout: ResolvedModuleLayout, moduleAreas: ModuleAreas): PvStringsPlanningModel {
    return PvStringsPlanningModel().also { targetModel ->
      applyToMutableModel(targetModel, forModuleLayout, moduleAreas)
    }
  }

  /**
   * Applies the settings from this module plan to the given ui model
   */
  fun applyToMutableModel(targetModel: PvStringsPlanningModel, forModuleLayout: ResolvedModuleLayout, moduleAreas: ModuleAreas) {
    targetModel.roofStringsConfiguration.set(inverterConfigurations.toRoofStringsConfiguration(forModuleLayout, moduleAreas))
  }
}

fun List<ResolvedInverterConfiguration>.toRoofStringsConfiguration(forModuleLayout: ResolvedModuleLayout, moduleAreas: ModuleAreas): List<RoofString> {
  return this.flatMapIndexed { inverterIndex, inverterConfiguration ->
    val previousInverterStringCount = this.take(inverterIndex).sumOf {
      it.mpptInputConfigurations.sumOf {
        it.stringConfigurations.count {
          val optimalModuleCount = it.optimalModuleCount
          optimalModuleCount != null && optimalModuleCount > 0
        }
      }
    }

    val relevantMpptConfigurations = inverterConfiguration.mpptInputConfigurations.filter {
      it.stringConfigurations.any {
        val optimalModuleCount = it.optimalModuleCount
        optimalModuleCount != null && optimalModuleCount > 0
      }
    }
    relevantMpptConfigurations.flatMapIndexed { mpptInputIndex, mpptInputConfiguration ->
      val previousMpptInputStringCount = relevantMpptConfigurations.take(mpptInputIndex).sumOf {
        it.stringConfigurations.count {
          val optimalModuleCount = it.optimalModuleCount
          optimalModuleCount != null && optimalModuleCount > 0
        }
      }

      val relevantStringConfigurations = mpptInputConfiguration.stringConfigurations.filter {
        val optimalModuleCount = it.optimalModuleCount
        optimalModuleCount != null && optimalModuleCount > 0
      }
      relevantStringConfigurations.mapNotNull { it.modulesStrings }.flatMapIndexed { stringIndex, modulesStrings ->
        val allRoofStringsForModuleLayout = modulesStrings.filter { it.moduleLayoutId == forModuleLayout.id }
        val stringColorIndexOverride = StringIndex(previousInverterStringCount + previousMpptInputStringCount + stringIndex)
        allRoofStringsForModuleLayout.map { roofString ->
          val roofStringIndex = modulesStrings.indexOf(roofString)
          val previousModulesCount = modulesStrings.take(roofStringIndex).sumOf { it.numberOfModules }
          val followingModulesCount = modulesStrings.drop(roofStringIndex + 1).sumOf { it.numberOfModules }
          roofString.toMeisterChartsRoofString(inverterIndex, mpptInputIndex, StringIndex(stringIndex), stringColorIndexOverride, previousModulesCount, followingModulesCount, moduleAreas)
        }
      }
    }
  }
}

fun ModulesString.toMeisterChartsRoofString(inverterIndex: Int, mpptInputIndex: Int, stringIndex: StringIndex, stringColorIndexOverride: StringIndex, previousModulesCount: Int, followingModulesCount: Int, moduleAreas: ModuleAreas): RoofString {
  val initialModules = this.stringOfModules.mapNotNull { moduleString ->
    moduleAreas.moduleAreas.firstOrNull { it.id == moduleString.moduleAreaId }
      ?.getModule(moduleString.moduleIndex)
  }
  return RoofString(
    uuid = this.uuid,
    moduleLayoutUuid = this.moduleLayoutId.uuid,
    inverterIndex = inverterIndex,
    mpptInputIndex = mpptInputIndex,
    stringIndex = stringIndex,
    stringColorIndexOverride = stringColorIndexOverride,
    previousModulesCount = previousModulesCount,
    followingModulesCount = followingModulesCount,
    initialModules = initialModules,
  )
}

fun RoofString.toRestApiModel(): ModulesString {
  return ModulesString(
    id = StringId(this.uuid),
    moduleLayoutId = ModuleLayoutId(this.moduleLayoutUuid),
    stringOfModules = this.modules.map { module ->
      ModuleReference(
        moduleAreaId = module.modulePlacement.moduleArea.id,
        moduleIndex = module.modulePlacement.moduleIndex,
      )
    }
  )
}

/**
 * Converts the planning model to an immutable model that can be used for the REST API
 */
fun PvStringsPlanningModel.toRestApiModel(inverterConfigurations: List<ResolvedInverterConfiguration>): PvStringsPlanningModelInformation {
  return PvStringsPlanningModelInformation(
    inverterConfigurations = this.roofStringsConfiguration.toRestApiModel(inverterConfigurations),
  )
}
