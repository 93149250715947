package it.neckar.common.auth

import it.neckar.rest.RestResponse
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.user.UserLoginName
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Response for a login request
 */
@Serializable
sealed interface LoginResponse : RestResponse<LoginResponseData> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: LoginResponseData) : LoginResponse, RestResponse.SuccessResponse<LoginResponseData>

  @Serializable
  @SerialName("failure")
  data object Failure : LoginResponse, RestResponse.FailureResponse<LoginResponseData>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: LoginResponseData): LoginResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): LoginResponse = Failure
  }
}

/**
 * The data for a login response
 */
@Serializable
data class LoginResponseData(
  /**
   * The login name of the user that has been logged in.
   * The login name is required for requests that do not contain the login name (e.g., when using some kind of token)
   */
  val loginName: UserLoginName,
  /**
   * The JWS token pair
   */
  val jwsTokens: JwtTokenPair,
)
