package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.charts.lizergy.stringsPlanning.RoofString
import com.meistercharts.model.Insets
import it.neckar.geometry.Size
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.toRestApiModel
import it.neckar.open.unit.si.mm
import kotlinx.serialization.Serializable

/**
 * Serializable model for the roof planning model
 * This model contains exactly one plan for one roof.
 *
 * It can be converted from/to a [PvStringsPlanningModel] using methods defined in this class ([toRestApiModel] and [toMutableModel]/[applyToMutableModel])
 */
@Serializable
data class PvStringsPlanningModelInformation(
  val moduleSize: @mm ModuleSize,

  val roofSize: @mm Size,
  val suggestedRoofInsets: @mm Insets,

  val moduleAreasInformation: ModuleAreasInformation,

  val unusableAreasInformation: UnusableAreasInformation,

  val inverterConfigurations: List<ResolvedInverterConfiguration>,
) {
  /**
   * Creates a new planning model with the same settings
   */
  fun toMutableModel(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also { targetModel ->
      applyToMutableModel(targetModel)
    }
  }

  /**
   * Applies the settings from this module plan to the given ui model
   */
  fun applyToMutableModel(targetModel: PvStringsPlanningModel) {
    targetModel.roofSize = roofSize
    targetModel.modulesSize = moduleSize
    targetModel.suggestedRoofInsets = suggestedRoofInsets

    targetModel.unusableAreas.set(unusableAreasInformation.toUnusableAreas())
    val moduleAreas = moduleAreasInformation.toModuleAreas(targetModel.unusableAreas, targetModel.modulesSize)
    targetModel.moduleAreas.set(moduleAreas)
    targetModel.roofStringsConfiguration.set(inverterConfigurations.toInverterConfigurations(moduleAreas))
  }
}

fun List<ResolvedInverterConfiguration>.toInverterConfigurations(moduleAreas: List<ModuleArea>): List<RoofString> {
  return this.flatMap { inverterConfiguration ->
    inverterConfiguration.mpptInputConfigurations.flatMap { mpptInputConfiguration ->
      mpptInputConfiguration.stringConfigurations.flatMap { stringConfiguration ->
        (stringConfiguration.modulesStrings ?: emptyList()).flatMap { modulesString ->
          modulesString.roofStrings.map { roofString ->
            RoofString(
              uuid = roofString.uuid,
              roofUuid = roofString.roofId.uuid,
              initialModules = roofString.stringOfModules.map { moduleString ->
                moduleAreas.first { it.id == moduleString.moduleAreaId }
                  .getModule(moduleString.moduleIndex)
              }
            )
          }
        }
      }
    }
  }
}

/**
 * Converts the planning model to an immutable model that can be used for the REST API
 */
fun PvStringsPlanningModel.toRestApiModel(): PvStringsPlanningModelInformation {
  return PvStringsPlanningModelInformation(
    this.modulesSize,
    this.roofSize,
    this.suggestedRoofInsets,
    this.moduleAreas.toRestApiModel(),
    this.unusableAreas.toRestApiModel(),
    this.roofStringsConfiguration.toRestApiModel(),
  )
}
