package components.project.configuration

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.toNewProcessStateEntry
import it.neckar.open.collections.fastForEach
import it.neckar.processStates.current
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import router.toConfiguration
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useRequireCompanyForLoggedInUser
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectAvailableProducts
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectPriceList

/**
 * Visualizes all quotes.
 * Shows the list for all quotes
 */
val ConfigurationsOverviewFromUrl: FC<Props> = fc("ConfigurationsOverviewFromUrl") {
  val project = useLoadResolvedProjectFromUrl()
  val loggedInUser = useRequireLoggedInUser()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Angebote", allProcessStatesForComponents?.let { project?.getDisplayName(it) })

  busyIfNull(project, allProcessStatesForComponents) { loadedProject, loadedAllProcessStatesForComponents ->
    ConfigurationsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfigurations = loadedProject.quoteConfigurations.validElements.sortedByDescending { loadedAllProcessStatesForComponents[it.uuid].validProcessStateEntries.current() as? LizergyProcessStateEntry ?: it.processState?.currentEdit?.toNewProcessStateEntry(loggedInUser) }
      }
    }
  }

}


val ConfigurationsOverview: FC<ConfigurationsOverviewProps> = fc("ConfigurationsOverview") { props ->
  val navigate = useNavigateUrl()
  val availableProducts = useSelectAvailableProducts()
  val priceList = useSelectPriceList()
  val loggedInUser = useRequireLoggedInUser()
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()
  val quoteConfigurations = props::quoteConfigurations.safeGet()


  div("my-4") {
    actionButtonWithConfirmationModal(
      icon = FontAwesomeIcons.add,
      buttonClasses = "btn btn-primary",
      buttonContent = { span("ps-2") { +"Neues Angebot" } },
      modalTitle = "Neues Angebot anlegen?",
    ) {
      UiActions.addQuoteConfiguration(project, availableProducts, priceList, companyResolver, loggedInUser).also { newQuoteConfiguration ->
        navigate.toConfiguration(project.projectId, newQuoteConfiguration)
      }
    }

  }

  div("row row-cols-1 row-cols-lg-2 g-4") {
    quoteConfigurations.fastForEach { quoteConfiguration ->
      ConfigurationPreview {
        attrs {
          this.project = project
          this.quoteConfiguration = quoteConfiguration
        }
      }
    }
  }

}

external interface ConfigurationsOverviewProps : Props {
  var project: ResolvedProject
  var quoteConfigurations: List<QuoteConfiguration>
}
