package components.project.configuration.layout.strings

import components.form.commentSection
import components.project.configuration.layout.moduleLayout.planning.moduleStringsPlanning
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvRoofPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvStringsPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRestApiModel
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*

val EditModuleStrings: FC<EditModuleStringsProps> = fc("EditModuleStrings") { props ->
  val project = props::project.safeGet()
  val moduleLayout = props::moduleLayout.safeGet()
  val inverterConfigurationsToSave = props::inverterConfigurationsToSave.getNotNull()
  val stringConfigurationToEdit = props::stringConfigurationToEdit.getNotNull()
  val editableStatus = props::editableStatus.safeGet()

  val roof = moduleLayout.roof

  val roofSize = roof.size
  val moduleType = moduleLayout.moduleType
  val modulesCount = moduleLayout.modulesCount
  val moduleAreasInformation = moduleLayout.moduleAreasInformation
  val unusableAreasInformation = moduleLayout.unusableAreasInformation
  val suggestedRoofInsets = moduleLayout.suggestedRoofInsets


  val currentRoofPlanningModel = useMemo(
    moduleType.size,
    roofSize,
    suggestedRoofInsets,
    moduleAreasInformation,
    unusableAreasInformation,
  ) {
    if (roofSize != null) {
      PvRoofPlanningModelInformation(
        moduleSize = moduleType.size,
        roofSize = roofSize,
        suggestedRoofInsets = suggestedRoofInsets,
        moduleAreasInformation = moduleAreasInformation,
        unusableAreasInformation = unusableAreasInformation,
      )
    } else null
  }

  val currentStringPlanningModel = useMemo(inverterConfigurationsToSave.value) {
    PvStringsPlanningModelInformation(inverterConfigurations = inverterConfigurationsToSave.value)
  }


  h2 {
    +moduleLayout.layoutName
  }

  div {

    currentRoofPlanningModel?.let {
      div("row mb-3") {
        div("col-sm-12") {
          moduleStringsPlanning(
            currentModuleLayout = moduleLayout,
            stringsPlanningModel = currentStringPlanningModel,
            roofPlanningModel = currentRoofPlanningModel,
            inverterConfigurations = inverterConfigurationsToSave.value,
            stringConfigurationToEdit = stringConfigurationToEdit,
            editableStatus = editableStatus,
          ) { updated ->
            //Update grid, unusable areas and modules when changed
            inverterConfigurationsToSave.setter.invoke { old ->
              updated.toRestApiModel(old).inverterConfigurations.also { new ->
                val relevantStringConfiguration = new.flatMap { it.mpptInputConfigurations }.flatMap { it.stringConfigurations }.firstOrNull { it.modulesStrings?.any { it.uuid == updated.selectedString?.uuid } == true }
                stringConfigurationToEdit.setter(relevantStringConfiguration)
              }
            }
          }
        }
      }
    }

    p("form-text") {
      +"$modulesCount Module | Leistung: ${(moduleType.powerRating * modulesCount).formatKiloWattPeak(plannerI18nConfiguration)}"
    }
  }

  div("mb-5") {
    h3("mb-2") {
      +"Bemerkungen Dachfläche"
    }
    project.blueprint.roofAnnotations.firstOrNull { it.id == roof.id }?.let {
      it.annotation?.let { p { +it } }
    }
    commentSection(roof)
  }

}


external interface EditModuleStringsProps : Props {
  var project: ResolvedProject
  var moduleLayout: ResolvedModuleLayout
  var inverterConfigurationsToSave: StateInstance<List<ResolvedInverterConfiguration>>
  var stringConfigurationToEdit: StateInstance<InverterConfiguration.StringConfiguration?>
  var editableStatus: EditableStatus
}
