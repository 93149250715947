@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.configuration.moduleLayout.roof

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.UnusableAreas
import com.meistercharts.charts.lizergy.stringsPlanning.ModuleReference
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreaInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreaInformation.UnusableAreaId
import it.neckar.lizergy.model.configuration.moduleLayout.planning.UnusableAreasInformation
import it.neckar.lizergy.model.configuration.moduleLayout.roof.Roof.RoofId
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
data class ModulesString(
  val id: StringId,
  val roofStrings: List<RoofString>,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  val numberOfModules: Int
    get() = roofStrings.sumOf { it.numberOfModules }

  fun forTheseRoofs(roofs: List<RoofId>): ModulesString {
    return copy(roofStrings = roofStrings.filter { roofs.contains(it.roofId) })
  }


  @Serializable
  data class RoofString(
    val id: StringId,
    val roofId: RoofId,
    val stringOfModules: List<ModuleReference>,
  ) : HasUuid {
    override val uuid: Uuid
      get() = id.uuid

    val numberOfModules: Int
      get() = stringOfModules.size
  }


  @Serializable
  data class StringId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): StringId {
        return StringId(randomUuid4())
      }
    }
  }
}


fun UnusableAreas.toRestApiModel(): UnusableAreasInformation {
  return UnusableAreasInformation(
    unusableAreas.map {
      UnusableAreaInformation(
        id = UnusableAreaId(it.uuid),
        location = it.location,
        size = it.size,
        description = it.description,
        rightTriangleType = it.rightTriangleType,
      )
    }
  )
}
