package components.company.user

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProcessStates
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.DeleteUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditConfigurations
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditCustomers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditModuleLayouts
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherCompanies
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOtherUsers
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditOwnCompany
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.EditProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.VerifyProjects
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewAccounting
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarnings
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsBureaucracyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionBlueprintRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsDistributionQuoteRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsElectricityWorkRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsReferrerRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsRoofAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsScaffoldingAssemblyRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningElectricRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewEarningsTechnicalPlanningRoofRow
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewPriceList
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProfits
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewProjectCount
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight.ViewQuoteDump
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.react.common.*
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions

/**
 * Change password form
 */
val ChangeUserInfoForm: FC<ChangeUserInfoFormFormProps> = fc("ChangeUserInfoForm") { props ->
  val user = props::userToEdit.safeGet()

  val userName = useState(user.loginName.value)
  val editorName = useState(user.editorName)

  val companyCode = useState(user.company)

  val editCustomers = useState(user.accessRights.canAccess(EditCustomers))
  val editProjects = useState(user.accessRights.canAccess(EditProjects))
  val editConfigurations = useState(user.accessRights.canAccess(EditConfigurations))
  val editModuleLayouts = useState(user.accessRights.canAccess(EditModuleLayouts))
  val editOtherUsers = useState(user.accessRights.canAccess(EditOtherUsers))
  val editOwnCompany = useState(user.accessRights.canAccess(EditOwnCompany))
  val editOtherCompanies = useState(user.accessRights.canAccess(EditOtherCompanies))
  val deleteProjects = useState(user.accessRights.canAccess(DeleteProjects))
  val verifyProjects = useState(user.accessRights.canAccess(VerifyProjects))
  val deleteUsers = useState(user.accessRights.canAccess(DeleteUsers))
  val viewPriceList = useState(user.accessRights.canAccess(ViewPriceList))
  val viewProjectCount = useState(user.accessRights.canAccess(ViewProjectCount))
  val deleteProcessStates = useState(user.accessRights.canAccess(DeleteProcessStates))
  val viewAccounting = useState(user.accessRights.canAccess(ViewAccounting))
  val viewProfits = useState(user.accessRights.canAccess(ViewProfits))
  val viewQuoteDump = useState(user.accessRights.canAccess(ViewQuoteDump))
  val viewEarnings = useState(user.accessRights.canAccess(ViewEarnings))
  val editEarnings = useState(user.accessRights.canAccess(AccessRights.AccessRight.EditEarnings))

  val viewEarningsReferrerRow = useState(user.accessRights.canAccess(ViewEarningsReferrerRow))
  val viewEarningsDistributionBlueprintRow = useState(user.accessRights.canAccess(ViewEarningsDistributionBlueprintRow))
  val viewEarningsDistributionQuoteRow = useState(user.accessRights.canAccess(ViewEarningsDistributionQuoteRow))
  val viewEarningsTechnicalPlanningRoofRow = useState(user.accessRights.canAccess(ViewEarningsTechnicalPlanningRoofRow))
  val viewEarningsTechnicalPlanningElectricRow = useState(user.accessRights.canAccess(ViewEarningsTechnicalPlanningElectricRow))
  val viewEarningsRoofAssemblyRow = useState(user.accessRights.canAccess(ViewEarningsRoofAssemblyRow))
  val viewEarningsScaffoldingAssemblyRow = useState(user.accessRights.canAccess(ViewEarningsScaffoldingAssemblyRow))
  val viewEarningsElectricityWorkRow = useState(user.accessRights.canAccess(ViewEarningsElectricityWorkRow))
  val viewEarningsBureaucracyRow = useState(user.accessRights.canAccess(ViewEarningsBureaucracyRow))

  val (busy, setBusy) = useState(false)


  val accessRights = AccessRights(
    buildSet {
      if (editCustomers.value) add(EditCustomers)
      if (editProjects.value) add(EditProjects)
      if (editConfigurations.value) add(EditConfigurations)
      if (editModuleLayouts.value) add(EditModuleLayouts)
      if (editOtherUsers.value) add(EditOtherUsers)
      if (editOwnCompany.value) add(EditOwnCompany)
      if (editOtherCompanies.value) add(EditOtherCompanies)
      if (deleteProjects.value) add(DeleteProjects)
      if (verifyProjects.value) add(VerifyProjects)
      if (deleteUsers.value) add(DeleteUsers)
      if (viewPriceList.value) add(ViewPriceList)
      if (viewProjectCount.value) add(ViewProjectCount)
      if (deleteProcessStates.value) add(DeleteProcessStates)
      if (viewAccounting.value) add(ViewAccounting)
      if (viewQuoteDump.value) add(ViewQuoteDump)
      if (viewProfits.value) add(ViewProfits)
      if (viewEarnings.value) add(ViewEarnings)
      if (editEarnings.value) add(AccessRights.AccessRight.EditEarnings)
      if (viewEarningsReferrerRow.value) add(ViewEarningsReferrerRow)
      if (viewEarningsDistributionBlueprintRow.value) add(ViewEarningsDistributionBlueprintRow)
      if (viewEarningsDistributionQuoteRow.value) add(ViewEarningsDistributionQuoteRow)
      if (viewEarningsTechnicalPlanningRoofRow.value) add(ViewEarningsTechnicalPlanningRoofRow)
      if (viewEarningsTechnicalPlanningElectricRow.value) add(ViewEarningsTechnicalPlanningElectricRow)
      if (viewEarningsRoofAssemblyRow.value) add(ViewEarningsRoofAssemblyRow)
      if (viewEarningsScaffoldingAssemblyRow.value) add(ViewEarningsScaffoldingAssemblyRow)
      if (viewEarningsElectricityWorkRow.value) add(ViewEarningsElectricityWorkRow)
      if (viewEarningsBureaucracyRow.value) add(ViewEarningsBureaucracyRow)
    }
  )

  val newUserInformation = useMemo(editorName.value, accessRights, companyCode.value) {
    user.copy(
      editorName = editorName.value,
      accessRights = accessRights,
      company = companyCode.value,
    )
  }

  val plausible = editorName.value.isNotBlank() && (
      editorName.value != user.editorName ||
          companyCode.value != user.company ||
          editCustomers.value != user.accessRights.canAccess(EditCustomers) ||
          editProjects.value != user.accessRights.canAccess(EditProjects) ||
          editConfigurations.value != user.accessRights.canAccess(EditConfigurations) ||
          editModuleLayouts.value != user.accessRights.canAccess(EditModuleLayouts) ||
          editOtherUsers.value != user.accessRights.canAccess(EditOtherUsers) ||
          editOwnCompany.value != user.accessRights.canAccess(EditOwnCompany) ||
          editOtherCompanies.value != user.accessRights.canAccess(EditOtherCompanies) ||
          deleteProjects.value != user.accessRights.canAccess(DeleteProjects) ||
          verifyProjects.value != user.accessRights.canAccess(VerifyProjects) ||
          deleteUsers.value != user.accessRights.canAccess(DeleteUsers) ||
          viewPriceList.value != user.accessRights.canAccess(ViewPriceList) ||
          viewProjectCount.value != user.accessRights.canAccess(ViewProjectCount) ||
          deleteProcessStates.value != user.accessRights.canAccess(DeleteProcessStates) ||
          viewAccounting.value != user.accessRights.canAccess(ViewAccounting) ||
          viewProfits.value != user.accessRights.canAccess(ViewProfits) ||
          viewQuoteDump.value != user.accessRights.canAccess(ViewQuoteDump) ||
          viewEarnings.value != user.accessRights.canAccess(ViewEarnings) ||
          editEarnings.value != user.accessRights.canAccess(AccessRights.AccessRight.EditEarnings) ||
          viewEarningsReferrerRow.value != user.accessRights.canAccess(ViewEarningsReferrerRow) ||
          viewEarningsDistributionBlueprintRow.value != user.accessRights.canAccess(ViewEarningsDistributionBlueprintRow) ||
          viewEarningsDistributionQuoteRow.value != user.accessRights.canAccess(ViewEarningsDistributionQuoteRow) ||
          viewEarningsTechnicalPlanningRoofRow.value != user.accessRights.canAccess(ViewEarningsTechnicalPlanningRoofRow) ||
          viewEarningsTechnicalPlanningElectricRow.value != user.accessRights.canAccess(ViewEarningsTechnicalPlanningElectricRow) ||
          viewEarningsRoofAssemblyRow.value != user.accessRights.canAccess(ViewEarningsRoofAssemblyRow) ||
          viewEarningsScaffoldingAssemblyRow.value != user.accessRights.canAccess(ViewEarningsScaffoldingAssemblyRow) ||
          viewEarningsElectricityWorkRow.value != user.accessRights.canAccess(ViewEarningsElectricityWorkRow) ||
          viewEarningsBureaucracyRow.value != user.accessRights.canAccess(ViewEarningsBureaucracyRow)
      )

  val changeUserInfoAction = useCallback(plausible, newUserInformation) {
    if (plausible.not()) return@useCallback

    launchAndNotify {
      setBusy(true)
      UiActions.changeUserInfo(newUserInformation)
      setBusy(false)
    }
  }


  div {

    onEnter(changeUserInfoAction)

    h3 {
      +"Benutzerdaten"
    }

    userForm(
      userName = userName,
      editorName = editorName,
      companyProfile = companyCode,
      editCustomers = editCustomers,
      editProjects = editProjects,
      editConfigurations = editConfigurations,
      editModuleLayouts = editModuleLayouts,
      editOtherUsers = editOtherUsers,
      editOwnCompany = editOwnCompany,
      editOtherCompanies = editOtherCompanies,
      deleteProjects = deleteProjects,
      verifyProjects = verifyProjects,
      deleteUsers = deleteUsers,
      viewPriceList = viewPriceList,
      viewProjectCount = viewProjectCount,
      deleteProcessStates = deleteProcessStates,
      viewAccounting = viewAccounting,
      existingUser = user,
      viewProfits = viewProfits,
      viewQuoteDump = viewQuoteDump,
      viewEarnings = viewEarnings,
      editEarnings = editEarnings,

      viewEarningsTechnicalPlanningRoofRow = viewEarningsTechnicalPlanningRoofRow,
      viewEarningsTechnicalPlanningElectricRow = viewEarningsTechnicalPlanningElectricRow,
      viewEarningsElectricityWorkRow = viewEarningsElectricityWorkRow,
      viewEarningsDistributionBlueprintRow = viewEarningsDistributionBlueprintRow,
      viewEarningsDistributionQuoteRow = viewEarningsDistributionQuoteRow,
      viewEarningsBureaucracyRow = viewEarningsBureaucracyRow,
      viewEarningsRoofAssemblyRow = viewEarningsRoofAssemblyRow,
      viewEarningsScaffoldingAssemblyRow = viewEarningsScaffoldingAssemblyRow,
      viewEarningsReferrerRow = viewEarningsReferrerRow
    )

    div("my-3") {

      button(classes = "btn btn-primary w-100 btn-lg", type = ButtonType.button) {
        +"Benutzerdaten ändern"

        attrs {
          disabled = plausible.not() || busy
          onClickFunction = { changeUserInfoAction() }
        }
      }
    }

  }

}

external interface ChangeUserInfoFormFormProps : Props {
  var userToEdit: UserInformation
}
