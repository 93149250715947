package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.model.Insets
import it.neckar.geometry.Size
import it.neckar.open.unit.si.mm
import kotlinx.serialization.Serializable

/**
 * Serializable model for the roof planning model
 * This model contains exactly one plan for one roof.
 *
 * It can be converted from/to a [PvModulePlanningModel] using methods defined in this class ([toRestApiModel] and [toMutableModel]/[applyToMutableModel])
 */
@Serializable
data class PvRoofPlanningModelInformation(
  val moduleSize: @mm ModuleSize,

  val roofSize: @mm Size,
  val suggestedRoofInsets: @mm Insets,

  val moduleAreasInformation: ModuleAreasInformation,

  val unusableAreasInformation: UnusableAreasInformation,
) {
  /**
   * Creates a new planning model with the same settings
   */
  fun toMutableModel(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also { targetModel ->
      applyToMutableModel(targetModel)
    }
  }

  /**
   * Applies the settings from this module plan to the given ui model
   */
  fun applyToMutableModel(targetModel: PvRoofPlanningModel) {
    targetModel.roofSize = roofSize
    targetModel.modulesSize = moduleSize
    targetModel.suggestedRoofInsets = suggestedRoofInsets

    targetModel.moduleAreas.set(moduleAreasInformation.toModuleAreas(targetModel.unusableAreas, targetModel.modulesSize))
    targetModel.unusableAreas.set(unusableAreasInformation.toUnusableAreas())
  }
}

/**
 * Converts the planning model to an immutable model that can be used for the REST API
 */
fun PvRoofPlanningModel.toRestApiModel(): PvRoofPlanningModelInformation {
  return PvRoofPlanningModelInformation(
    this.modulesSize,
    this.roofSize,
    this.suggestedRoofInsets,
    this.moduleAreas.toRestApiModel(),
    this.unusableAreas.toRestApiModel(),
  )
}

