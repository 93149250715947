package com.meistercharts.charts.lizergy.stringsPlanning

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.algorithms.painter.fillAndStroke
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandlerBroker
import com.meistercharts.canvas.layout.cache.BoundsMulti2DCache
import com.meistercharts.canvas.layout.cache.CoordinatesMulti2DCache
import com.meistercharts.canvas.layout.cache.StringMulti2DCache
import com.meistercharts.canvas.paintTextBox
import com.meistercharts.canvas.saved
import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningLayer.Mode
import com.meistercharts.color.Color
import com.meistercharts.events.EventConsumption
import com.meistercharts.events.EventConsumption.Consumed
import com.meistercharts.events.EventConsumption.Ignored
import com.meistercharts.model.BorderRadius
import com.meistercharts.style.BoxStyle
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Rectangle
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.observable.ObservableObject
import it.neckar.open.provider.MultiProvider

/**
 * Layer that supports planning of  photo voltaic modules for *one* roof
 */
class PvStringsPlanningLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    model: PvStringsPlanningModel,
    getModuleAtLocation: (coordinates: Coordinates) -> Module?,
    getBoundsForModule: (module: Module) -> @Window Rectangle,
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(model, getModuleAtLocation, getBoundsForModule), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  override val mouseEventHandler: CanvasMouseEventHandler = CanvasMouseEventHandlerBroker().also { broker ->
    /**
     * All other events are handled here
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
        if (configuration.mode == Mode.Rendering) return Ignored

        val coordinates = event.coordinates

        if (coordinates == null) {
          //Moved out of the window
          uiState = uiState.movedOutOfCanvas()
          return Ignored
        }

        //check if over module
        configuration.getModuleAtLocation(coordinates)?.let { module ->
          //mouse over module
          uiState = uiState.hoveringOver(module)
          return Consumed
        }

        //Nothing found under the mouse
        uiState = uiState.moveOverNothing()
        return Consumed
      }

      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        if (configuration.mode == Mode.Rendering) return Ignored

        val coordinates = event.coordinates

        //check if over module
        configuration.getModuleAtLocation(coordinates)?.let { module ->
          //mouse over module
          uiState = uiState.downOn(module)
          return Consumed
        }

        //This method is called *after* the dragging events have been handled
        //All downs on modules and unusable areas have already been consumed
        //remove the selection if necessary
        uiState = uiState.downOnNothing()
        return Ignored
      }

      override fun onUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        //TODO return Consumed???
        return super.onUp(event, chartSupport)
      }
    })
  }

  /**
   * Contains the current ui state
   */
  private var uiStateProperty: ObservableObject<StringsPlanningUiState> = ObservableObject(DefaultState(configuration))
  var uiState: StringsPlanningUiState by uiStateProperty
    private set

  override fun initialize(paintingContext: LayerPaintingContext) {
    val chartSupport = paintingContext.chartSupport

    //Update the mouse cursor depending on the state
    uiStateProperty.consumeImmediately {

      //Update the mouse cursor
      chartSupport.cursor = when (it) {
        is DefaultState, is Hovering -> null
        is PlanningString -> if (it.activeModule != null) MouseCursor.Hand else MouseCursor.CrossHair
      }

      //Always mark as dirty on state change
      chartSupport.markAsDirty(DirtyReason.UiStateChanged)
    }

  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    if (configuration.mode == Mode.Planning) {
      //Paint all module highlights
      val modules = configuration.model.modules()
      modules.fastForEachVisibleModuleIndexed { index, module ->

        uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> {}

            is Hovering -> {
              println("Hovering over module $module")
              if (uiState.activeModule == module) {
                val bounds = configuration.getBoundsForModule(module)
                gc.fillAndStroke(configuration.hoverHighlightStyle)
                gc.fillRect(bounds)
              }
            }

            is PlanningString -> {
              val bounds = configuration.getBoundsForModule(module)
              if (uiState.activeModule == module) {
                gc.fillAndStroke(configuration.undeleteArmedHighlightStyle)
                gc.fillRect(bounds)
              }
              if (configuration.model.selectedString?.contains(module) == true) {
                gc.fillAndStroke(configuration.deleteArmedHighlightStyle)
                gc.fillRect(bounds)
              }
            }

          }
        }
      }
    }

    val moduleStringsCoordinates = paintingVariables().stringCoordinates
    moduleStringsCoordinates.fastForEachWithIndex { stringIndex, stringCoordinatesCaches ->
      val stringColor = configuration.stringColors.valueAt(stringIndex)
      gc.beginPath()

      stringCoordinatesCaches.fastForEachIndexed { _, moduleCoordinatesX, moduleCoordinatesY ->
        gc.lineTo(moduleCoordinatesX, moduleCoordinatesY)
      }

      gc.stroke(stringColor)
      gc.stroke()
    }

    val moduleLabels = paintingVariables().moduleLabels
    val moduleLabelsBounds = paintingVariables().moduleLabelsBounds
    moduleStringsCoordinates.fastForEachWithIndex { stringIndex, stringCoordinates ->
      val labelBoxStyle = configuration.stringLabelBoxStyles.valueAt(stringIndex)
      val textColor = configuration.stringLabelBoxTextColors.valueAt(stringIndex)

      stringCoordinates.fastForEachIndexed { moduleIndexInString, moduleCoordinatesX, moduleCoordinatesY ->
        val moduleLabel = moduleLabels[stringIndex][moduleIndexInString]

        gc.saved {
          gc.translate(moduleCoordinatesX, moduleCoordinatesY)
          val boundingBox = gc.paintTextBox(line = moduleLabel, anchorDirection = Direction.Center, boxStyle = labelBoxStyle, textColor = textColor)
          moduleLabelsBounds[stringIndex][moduleIndexInString] = boundingBox
        }
      }
    }

  }

  override fun paintingVariables(): PvStringsPlanningPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables: PvStringsPlanningPaintingVariables = object : PvStringsPlanningPaintingVariables {

    override val stringCoordinates: @Window CoordinatesMulti2DCache = CoordinatesMulti2DCache()

    override val moduleLabels: StringMulti2DCache = StringMulti2DCache()

    override val moduleLabelsBounds: BoundsMulti2DCache = BoundsMulti2DCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val stringsPlanningModel = configuration.model

      stringCoordinates.prepare(stringsPlanningModel.roofStringsConfiguration.count) //ensure array sizes
      moduleLabels.prepare(stringsPlanningModel.roofStringsConfiguration.count) //ensure array sizes
      moduleLabelsBounds.prepare(stringsPlanningModel.roofStringsConfiguration.count) //ensure array sizes
      stringsPlanningModel.roofStringsConfiguration.roofStrings.fastForEachIndexed { stringIndex, roofString ->

        val moduleCoordinatesCache = stringCoordinates[stringIndex]
        moduleCoordinatesCache.prepare(roofString.count) //ensure array sizes
        val moduleLabelsCache = moduleLabels[stringIndex]
        moduleLabelsCache.prepare(roofString.count) //ensure array sizes

        roofString.modules.fastForEachIndexed { moduleIndex, module ->
          val moduleBounds = configuration.getBoundsForModule(module)
          val centerX = moduleBounds.getX() + moduleBounds.getWidth() / 2.0
          val centerY = moduleBounds.getY() + moduleBounds.getHeight() / 2.0

          moduleCoordinatesCache.set(moduleIndex, centerX, centerY)

          //TODO: Add inverter and MPPT information
          val moduleLabel = "1.${stringIndex + 1}.1.${moduleIndex + 1}"
          moduleLabelsCache[moduleIndex] = moduleLabel
        }
      }
    }
  }


  /**
   * The mode
   */
  enum class Mode {
    /**
     * The roof is rendered (e.g. to display to the customer)
     */
    Rendering,

    /**
     * We are in planning mode - additional information is painted
     */
    Planning
  }

  @ConfigurationDsl
  class Configuration(
    val model: PvStringsPlanningModel,
    val getModuleAtLocation: (coordinates: Coordinates) -> Module?,
    val getBoundsForModule: (module: Module) -> @Window Rectangle,
  ) {
    /**
     * The current mode
     */
    var mode: Mode = Mode.Planning //TODO remove mouse over effects when rendering!

    /**
     * The style when the mouse over an element
     */
    val hoverHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.web("#75b72633"), Color.web("#75b726"))

    /**
     * When hovering above the delete button
     */
    val deleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.red().withAlpha(0.3), Color.red())

    val undeleteArmedHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.green().withAlpha(0.3), Color.green())

    /**
     * Used to style the string labels on each module
     */
    var stringLabelBoxStyles: MultiProvider<StringIndex, BoxStyle> = MultiProvider.forListModulo(
      listOf(
        BoxStyle(fill = Color.yellow, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.red, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.cyan, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.green, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.blue, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.magenta, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.brown, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.pink, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.orange, borderColor = null, radii = BorderRadius.all2, shadow = null),
        BoxStyle(fill = Color.purple, borderColor = null, radii = BorderRadius.all2, shadow = null),
      ),
    )

    var stringColors: MultiProvider<StringIndex, Color> = MultiProvider.invoke { index -> stringLabelBoxStyles.valueAt(index).fill?.invoke() ?: Color.black() }

    var stringLabelBoxTextColors: MultiProvider<StringIndex, Color> = MultiProvider.forListModulo(
      listOf(
        Color.black(),
        Color.black(),
        Color.black(),
        Color.white(),
        Color.white(),
        Color.black(),
        Color.white(),
        Color.black(),
        Color.black(),
        Color.white(),
      )
    )

  }
}
