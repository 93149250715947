package com.meistercharts.charts.lizergy.roofPlanning

import com.benasher44.uuid.Uuid
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.kotlin.lang.requireNotNull
import it.neckar.open.kotlin.lang.toIntFloor
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

/**
 * Represents a grid that can be used to layout modules.
 */
class ModuleArea(
  val id: ModuleAreaId,

  initialLocation: @RoofRelative Coordinates,

  initialSize: @mm Size,

  /**
   * The orientation of all modules in the grid
   */
  initialOrientation: ModuleOrientation,

  /**
   * The size of *all* modules within the grid
   */
  var modulesSize: @mm ModuleSize,

  /**
   * The gap between two modules (vertical and horizontal)
   */
  var gap: @mm Double,

  /**
   * The unusable areas that must not be used for modules
   */
  val unusableAreas: UnusableAreas,

  ) : Resizable {

  /**
   * The modules that are placed within this module area
   */
  val modules: Modules = Modules()


  /**
   * How many modules can be placed side by side (horizontally)
   */
  internal var modulesCountHorizontal: Int = 0

  /**
   * How many modules can be placed on top of each other (vertically)
   */
  internal var modulesCountVertical: Int = 0

  /**
   * The offset relative to the roof.
   *
   * The offset must not be greater than module width/height.
   */
  var locationProperty: ObservableObject<@RoofRelative Coordinates> = ObservableObject(initialLocation)
  override var location: @RoofRelative Coordinates by locationProperty

  /**
   * The size of the module area
   */
  var sizeProperty: ObservableObject<@mm Size> = ObservableObject(initialSize)
  override var size: @mm Size by sizeProperty

  /**
   * The orientation of (all) modules within this module area
   */
  var moduleOrientationProperty: ObservableObject<ModuleOrientation> = ObservableObject(initialOrientation)
  var moduleOrientation: ModuleOrientation by moduleOrientationProperty


  override val minimumSize: Size
    get() = modulesSize.toSize(moduleOrientation)

  init {
    fillGrid(modulesSize)
  }

  /**
   * Returns the module origin for the given index
   */
  fun calculateModuleOrigin(moduleIndex: ModuleIndex): Coordinates {
    val originX = this.location.x + moduleIndex.x * (moduleWidth() + gap)
    val originY = this.location.y + moduleIndex.y * (moduleHeight() + gap)
    return Coordinates(originX, originY)
  }

  /**
   * Returns the max modules count for a given *net* area
   */
  fun maxModulesCountHorizontal(netWidth: @mm Double): Int {
    val netWidthWithCorrection = netWidth + gap
    return (netWidthWithCorrection / (moduleWidth() + gap)).toIntFloor()
  }

  fun maxModulesCountVertical(netHeight: @mm Double): Int {
    val netHeightWithCorrection = netHeight + gap
    return (netHeightWithCorrection / (moduleHeight() + gap)).toIntFloor()
  }

  /**
   * Returns the width of the module - respecting the orientation
   */
  fun moduleWidth(): @mm Int {
    return modulesSize.width(moduleOrientation)
  }

  fun moduleHeight(): @mm Int {
    return modulesSize.height(moduleOrientation)
  }

  /**
   * Calculates the total width (including gaps) for the given number of modules
   */
  fun totalWidth(): @mm Double {
    return modulesCountHorizontal * moduleWidth() + (modulesCountHorizontal - 1) * gap
  }

  fun totalHeight(): @mm Double {
    return modulesCountVertical * moduleHeight() + (modulesCountVertical - 1) * gap
  }

  /**
   * Returns a (new instance) rectangle representing the bounds for the given index
   *
   * Attention: A new object is instantiated every time this method is called
   */
  fun asRect(): Rectangle {
    return Rectangle(location, size)
  }

  fun moduleBounds(): Size {
    val maxX = modules.visibleModules.maxOfOrNull {
      it.modulePlacement.moduleIndex.x
    }.also { if (it == null) return Size.zero }
    val maxY = modules.visibleModules.maxOfOrNull {
      it.modulePlacement.moduleIndex.y
    }.also { if (it == null) return Size.zero }

    val extents = ModuleIndex(maxX!! + 1, maxY!! + 1)

    return Size(
      extents.x * (moduleWidth() + gap),
      extents.y * (moduleHeight() + gap)
    )
  }

  /**
   * Fills the grid with modules - respecting the unusable areas.
   *
   * Keep the existing modules if possible - to retain the "deleted" state
   */
  fun fillGrid(newModuleSize: ModuleSize?) {
    newModuleSize?.let {
      if (modulesSize != newModuleSize) {
        modulesSize = newModuleSize
        //The module size has changed - clear all existing modules
        clear()
      }
    }

    //Calculate the net width for the modules
    modulesCountHorizontal = maxModulesCountHorizontal(size.width)
    modulesCountVertical = maxModulesCountVertical(size.height)

    /**
     * Clear all modules that are outside the grid or in unusable areas
     */
    modules.removeAll { module ->
      val overlapsUnusableArea = unusableAreas.overlaps(module.bounds)

      val isOutside = module.modulePlacement.let {
        it.moduleIndex.x < 0 ||
            it.moduleIndex.y < 0 ||
            it.moduleIndex.x >= modulesCountHorizontal ||
            it.moduleIndex.y >= modulesCountVertical
      }

      overlapsUnusableArea || isOutside
    }

    //add the (missing) modules if possible
    for (y in 0 until modulesCountVertical) {
      for (x in 0 until modulesCountHorizontal) {
        val existingModule = modules.visibleModules.find { module ->
          module.modulePlacement.let {
            it.moduleIndex.x == x && it.moduleIndex.y == y
          }
        }

        if (existingModule == null) {
          val newModule = Module(modulesSize, ModulePlacement(ModuleIndex(x, y), this))
          if (unusableAreas.overlaps(newModule.bounds).not()) {
            modules.add(newModule)
          }
        }
      }
    }
  }

  fun clear() {
    //Clear all grid modules
    modules.clear()
  }

  fun rotate() {
    clear()
    moduleOrientation = moduleOrientation.rotated()
    fillGrid(modulesSize)
  }

  /**
   * Returns the module with the given index
   */
  fun getModule(moduleIndex: ModuleIndex): Module {
    return modules.visibleModules.find { it.modulePlacement.moduleIndex == moduleIndex }.requireNotNull { "No module found for index [$moduleIndex]" }
  }


  @Serializable
  data class ModuleAreaId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ModuleAreaId {
        return ModuleAreaId(randomUuid4())
      }
    }
  }
}

/**
 * Represents a module index. Top left is 0/0
 */
@Serializable
data class ModuleIndex(
  val x: Int,
  val y: Int,
) {
  init {
    require(x >= 0) { "x must be >= 0 but was <$x>" }
    require(y >= 0) { "y must be >= 0 but was <$y>" }
  }

  //TODO maybe add companion methods that cache "normal" values?

  companion object {
    val origin: ModuleIndex = ModuleIndex(0, 0)
  }
}
