package it.neckar.lizergy.model.http

import com.benasher44.uuid.Uuid
import it.neckar.commons.js.CookieName
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.quote.QuoteSnapshot.QuoteSnapshotId
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.open.http.Url
import it.neckar.open.http.UrlParameterName
import it.neckar.rest.jwt.JwtCookies

/**
 * Contains ants for the Planner API.
 *
 * This class does *not* contain the hostname. Just the path.
 */
object PlannerApiPathConstants {

  //TODO: Move the methods to URL support. This object should just contain ants

  private val basePath: Url.Relative = Url.relative("api")

  object Cookies {
    /**
     * Cookie that contains the JWS access token - used for the PDF Preview service
     */
    val accessToken: CookieName = JwtCookies.accessToken
  }

  /**
   * Contains ants for parameters
   */
  object Parameters {
    val searchTerm: UrlParameterName = UrlParameterName("searchTerm")
    val projectId: UrlParameterName = UrlParameterName("projectId")
    val configurationId: UrlParameterName = UrlParameterName("configurationId")
    val quoteSnapshotId: UrlParameterName = UrlParameterName("quoteSnapshotId")
    val Uuid: UrlParameterName = UrlParameterName("Uuid")

    val invoiceNumber: UrlParameterName = UrlParameterName("invoiceNumber")
    val expectedProjectCount: UrlParameterName = UrlParameterName("expectedProjectCount")
    val expectedTotalRevenue: UrlParameterName = UrlParameterName("expectedTotalRevenue")
  }

  object Auth {
    val baseUrl: Url.Relative = basePath + "auth"

    val loginUrl: Url.Relative = baseUrl + "login"
    val refreshAccessToken: Url.Relative = baseUrl + "refresh-access-token"
    val verifyAccessTokenUrl: Url.Relative = baseUrl + "verify/access-token"
    val verifyRefreshTokenUrl: Url.Relative = baseUrl + "verify/refresh-token"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    val changePasswordUrl: Url.Relative = baseUrl + "changePassword"
    val addNewUserUrl: Url.Relative = baseUrl + "add-new-user"
    val changeUserInfoUrl: Url.Relative = baseUrl + "change-user-info"
    val deleteUserUrl: Url.Relative = baseUrl + "delete-user"
    val addNewCompanyUrl: Url.Relative = baseUrl + "add-new-company"
    val changeCompanyInfoUrl: Url.Relative = baseUrl + "change-company-info"
    val listUsersAndCompaniesUrl: Url.Relative = baseUrl + "list-users-and-companies"
  }

  object GeoInformation {
    val baseUrl: Url.Relative = basePath + "geo"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    val geoCodeUrl: Url.Relative = baseUrl + "geocode"

    val geoRouteUrl: Url.Relative = baseUrl + "distance"
  }

  object GeoExternal {
    const val openSourceRoutingMachineUrl: String = "router.project-osrm.org"
    const val openStreetMapUrl: String = "nominatim.openstreetmap.org"
  }

  @Deprecated("Currently not used in production")
  object EuScienceHub {
    val baseUrl: Url.Relative = basePath + "eu-science-hub"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"
  }

  /**
   * PDF Report Service
   */
  object PdfReport {
    val baseUrl: Url.Relative = basePath + "pdf"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    /**
     * URL to a project with a specific ID
     */
    val projects: Url.Relative = baseUrl + "projects"

    val project: Url.Relative = projects + Parameters.projectId
    val projectConfigurations: Url.Relative = project + "configurations"
    val projectConfiguration: Url.Relative = project + "configurations" + Parameters.configurationId

    val quoteOfferForCurrent: Url.Relative = projectConfiguration + "quote-snapshots/current/offer"
    val quoteOfferForSnapshot: Url.Relative = projectConfiguration + "quote-snapshots/" + Parameters.quoteSnapshotId + "/offer"

    val orderConfirmationForSnapshot: Url.Relative = projectConfiguration + "quote-snapshots" + Parameters.quoteSnapshotId + "order-confirmation"

    val assemblyPortfolio: Url.Relative = projectConfiguration + "assembly-portfolio"

    /**
     * Creates the URL to a quote configuration
     */
    fun configuration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return projects + "${projectId}/configurations/${configurationId}"
    }

    fun quoteOfferForCurrent(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/current/offer"
    }

    fun quoteOfferForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/${quoteSnapshotId}/offer"
    }

    fun orderConfirmationForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/${quoteSnapshotId}/order-confirmation"
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configuration(projectId, configurationId) + "assembly-portfolio"
    }
  }

  /**
   * Report Preview Service (provides HTML view)
   */
  object PdfPreview {
    val baseUrl: Url.Relative = basePath + "pdf-preview"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"


    val projects: Url.Relative = baseUrl + "projects"

    /**
     * URL to a project with a specific ID
     */
    val project: Url.Relative = projects + "{projectId}"
    val projectConfigurations: Url.Relative = project + "configurations"
    val projectConfiguration: Url.Relative = project + "configurations/{${Parameters.configurationId}}"

    val quoteOfferForCurrent: Url.Relative = projectConfiguration + "quote-snapshots/current/offer"
    val quoteOfferForSnapshot: Url.Relative = projectConfiguration + "quote-snapshots/" + Parameters.quoteSnapshotId + "/offer"
    val orderConfirmationForSnapshot: Url.Relative = projectConfiguration + "quote-snapshots/" + Parameters.quoteSnapshotId + "/order-confirmation"

    /**
     * Provides the assembly portfolio for a project and configuration
     */
    val assemblyPortfolio: Url.Relative = projectConfiguration + "assembly-portfolio"

    /**
     * Creates the URL to a quote configuration
     */
    fun configuration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return projects + "${projectId}/configurations/${configurationId}"
    }

    /**
     * Creates the URL to a quote offer with existing quote snapshot
     */
    fun quoteOfferForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/${quoteSnapshotId}/offer/index.html" //TODO somehow remove index.html
    }

    fun quoteOfferForCurrent(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/current/offer/index.html" //TODO somehow remove index.html
    }

    fun orderConfirmationForSnapshot(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): Url.Relative {
      return configuration(projectId, configurationId) + "quote-snapshots/${quoteSnapshotId}/order-confirmation/index.html" //TODO somehow remove index.html
    }

    fun assemblyPortfolio(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configuration(projectId, configurationId) + "assembly-portfolio/index.html" //TODO somehow remove index.html
    }


    @Deprecated("old")
    val quotes: Url.Relative = baseUrl + "quotes"


    @Deprecated("old")
    fun quoteConfirmationPreview(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, quoteSnapshotId: QuoteSnapshotId): String {
      return "${quotes}/${projectId}/${configurationId}/${quoteSnapshotId}/quoteOffer.html"
    }
  }

  object Storage {
    val baseUrl: Url.Relative = basePath + "storage"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    val projectsUrl: Url.Relative = baseUrl + "projects"
    val archiveProjectsUrl: Url.Relative = baseUrl + "archive-projects"
    val resolvedProjectsUrl: Url.Relative = baseUrl + "resolved-projects"
    val multipleResolvedProjectsUrl: Url.Relative = baseUrl + "resolved-projects/add-multiple-projects"

    val projectUrl: Url.Relative = projectsUrl + Parameters.projectId
    val archiveProjectUrl: Url.Relative = archiveProjectsUrl + Parameters.projectId
    val resolvedProjectUrl: Url.Relative = resolvedProjectsUrl + Parameters.projectId

    val verifications: Url.Relative = Url.relative("verifications")
    val verificationsUrl: Url.Relative = projectUrl + "$verifications"

    //TODO CHECK!!!
    val blueprint: Url.Relative = Url.relative("blueprint")
    val blueprintUrl: Url.Relative = projectUrl + "$blueprint"

    val orderSpecialMaterial: Url.Relative = Url.relative("orderSpecialMaterial")
    val orderSpecialMaterialUrl: Url.Relative = projectUrl + orderSpecialMaterial
    val gridAssessment: Url.Relative = Url.relative("gridAssessment")
    val gridAssessmentUrl: Url.Relative = projectUrl + gridAssessment
    val assemblyPortfolio: Url.Relative = Url.relative("assemblyPortfolio")
    val assemblyPortfolioUrl: Url.Relative = projectUrl + assemblyPortfolio
    val advanceInvoice: Url.Relative = Url.relative("advanceInvoice")
    val advanceInvoiceUrl: Url.Relative = projectUrl + advanceInvoice
    val assemblyRoof: Url.Relative = Url.relative("assemblyRoof")
    val assemblyRoofUrl: Url.Relative = projectUrl + assemblyRoof
    val assemblyBasement: Url.Relative = Url.relative("assemblyBasement")
    val assemblyBasementUrl: Url.Relative = projectUrl + assemblyBasement
    val switchMeterBox: Url.Relative = Url.relative("switchMeterBox")
    val switchMeterBoxUrl: Url.Relative = projectUrl + switchMeterBox
    val startupOperations: Url.Relative = Url.relative("startupOperations")
    val startupOperationsUrl: Url.Relative = projectUrl + startupOperations
    val finishingUp: Url.Relative = Url.relative("finishingUp")
    val finishingUpUrl: Url.Relative = projectUrl + finishingUp
    val finalAccount: Url.Relative = Url.relative("finalAccount")
    val finalAccountUrl: Url.Relative = projectUrl + finalAccount
    val documentation: Url.Relative = Url.relative("documentation")
    val documentationUrl: Url.Relative = projectUrl + documentation

    val configurationsUrl: Url.Relative = projectUrl + "configurations"
    val quoteConfigurationsUrl: Url.Relative = resolvedProjectUrl + "quote-configurations"

    val configurationUrl: Url.Relative = configurationsUrl + Parameters.configurationId
    val quoteConfigurationUrl: Url.Relative = quoteConfigurationsUrl + Parameters.configurationId

    //TODO CHECK
    val moduleLayouts: Url.Relative = Url.relative("module-layouts")
    val moduleLayoutsUrl: Url.Relative = configurationUrl + moduleLayouts

    val quoteSnapshots: Url.Relative = Url.relative("quote-snapshots")
    val quoteSnapshotsUrl: Url.Relative = configurationUrl + quoteSnapshots
    val quoteSnapshotUrl: Url.Relative = quoteSnapshotsUrl + Parameters.quoteSnapshotId

    val earningsDistributionUrl: Url.Relative = configurationUrl + "earnings-distribution"
    val manualQuoteElementsUrl: Url.Relative = configurationUrl + "manual-quote-elements"


    fun projectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return projectsUrl + projectId.format()
    }

    fun archiveProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return archiveProjectsUrl + projectId.format()
    }

    fun resolvedProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return resolvedProjectsUrl + projectId.format()
    }

    fun configurationsForProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return projectUrl(projectId) + "configurations"
    }

    fun configurationUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationsForProjectUrl(projectId) + configurationId.format()
    }

    fun quoteConfigurationsForProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return resolvedProjectUrl(projectId) + "quote-configurations"
    }

    fun quoteConfigurationUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return quoteConfigurationsForProjectUrl(projectId) + configurationId.format()
    }

    fun quoteSnapshotUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, snapshotId: QuoteSnapshotId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "quote-snapshots/${snapshotId}"
    }

    fun earningsDistributionUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "earnings-distribution"
    }

    fun manualQuoteElementsUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "manual-quote-elements"
    }

  }

  object Comments {
    val baseUrl: Url.Relative = basePath + "comments"

    val commentsForComponentsUrl: Url.Relative = baseUrl + Parameters.Uuid
    val duplicateCommentsUrl: Url.Relative = baseUrl + "duplicate-comments"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    fun commentsForComponentUrl(uuid: Uuid): Url.Relative {
      return baseUrl + "$uuid"
    }
  }

  object ProcessStates {
    val baseUrl: Url.Relative = basePath + "process-states"

    val processStatesForComponentsUrl: Url.Relative = baseUrl + Parameters.Uuid
    val addMultipleProcessStates: Url.Relative = baseUrl + "add-multiple-states"
    val duplicateProcessStatesUrl: Url.Relative = baseUrl + "duplicate-processStates"
    val allProcessStatesUrl: Url.Relative = baseUrl + "all-process-states-url"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    fun processStatesForComponentUrl(uuid: Uuid): Url.Relative {
      return baseUrl + "$uuid"
    }
  }

  object ProjectQuery {
    val baseUrl: Url.Relative = basePath + "query"

    val heartbeatUrl: Url.Relative = baseUrl + "heartbeat"

    val staticPriceList: Url.Relative = baseUrl + "static-price-list"

    val projectsUrl: Url.Relative = baseUrl + "projects"
    val projectsSearchUrl: Url.Relative = projectsUrl + "search"
    val archiveProjectsUrl: Url.Relative = baseUrl + "archive-projects"
    val resolvedProjectsUrl: Url.Relative = baseUrl + "resolved-projects"
    val multipleResolvedProjectsUrl: Url.Relative = resolvedProjectsUrl + "add-multiple-projects"

    val projectUrl: Url.Relative = projectsUrl + Parameters.projectId
    val archiveProjectUrl: Url.Relative = archiveProjectsUrl + Parameters.projectId
    val resolvedProjectUrl: Url.Relative = resolvedProjectsUrl + Parameters.projectId

    val projectPreviewsUrl: Url.Relative = baseUrl + "project-previews"
    val accountingProjectsUrl: Url.Relative = basePath + "accounting-projects"
    val projectCountsUrl: Url.Relative = baseUrl + "project-counts"
    val accountingProjectPreviewsUrl: Url.Relative = baseUrl + "accounting-project-previews"
    val allAccountingProjectPreviewsAsCSVUrl: Url.Relative = baseUrl + "all-accounting-project-previews-as-csv"

    val allRelevantAccountingProjectPreviewsAsCSVUrl: Url.Relative = baseUrl + "all-relevant-accounting-project-previews-as-csv"
    val setInvoiceForAccountingProjectPreviewsUrl: Url.Relative = baseUrl + "set-invoice-for-accounting-project-previews"

    val verifications: Url.Relative = Url.relative("verifications")
    val verificationsUrl: Url.Relative = projectUrl + Storage.verifications

    val blueprint: Url.Relative = Url.relative("blueprint")
    val blueprintUrl: Url.Relative = projectUrl + Storage.blueprint

    val orderSpecialMaterial: Url.Relative = Url.relative("orderSpecialMaterial")
    val orderSpecialMaterialUrl: Url.Relative = projectUrl + orderSpecialMaterial
    val gridAssessment: Url.Relative = Url.relative("gridAssessment")
    val gridAssessmentUrl: Url.Relative = projectUrl + gridAssessment
    val assemblyPortfolio: Url.Relative = Url.relative("assemblyPortfolio")
    val assemblyPortfolioUrl: Url.Relative = projectUrl + assemblyPortfolio
    val advanceInvoice: Url.Relative = Url.relative("advanceInvoice")
    val advanceInvoiceUrl: Url.Relative = projectUrl + advanceInvoice
    val assemblyRoof: Url.Relative = Url.relative("assemblyRoof")
    val assemblyRoofUrl: Url.Relative = projectUrl + assemblyRoof
    val assemblyBasement: Url.Relative = Url.relative("assemblyBasement")
    val assemblyBasementUrl: Url.Relative = projectUrl + assemblyBasement
    val switchMeterBox: Url.Relative = Url.relative("switchMeterBox")
    val switchMeterBoxUrl: Url.Relative = projectUrl + switchMeterBox
    val startupOperations: Url.Relative = Url.relative("startupOperations")
    val startupOperationsUrl: Url.Relative = projectUrl + startupOperations
    val finishingUp: Url.Relative = Url.relative("finishingUp")
    val finishingUpUrl: Url.Relative = projectUrl + finishingUp
    val finalAccount: Url.Relative = Url.relative("finalAccount")
    val finalAccountUrl: Url.Relative = projectUrl + finalAccount
    val documentation: Url.Relative = Url.relative("documentation")
    val documentationUrl: Url.Relative = projectUrl + documentation

    val configurationsUrl: Url.Relative = projectUrl + "configurations"
    val quoteConfigurationsUrl: Url.Relative = resolvedProjectUrl + "quote-configurations"

    val configurationUrl: Url.Relative = configurationsUrl + Parameters.configurationId
    val quoteConfigurationUrl: Url.Relative = quoteConfigurationsUrl + Parameters.configurationId

    val moduleLayouts: Url.Relative = Url.relative("module-layouts")
    val moduleLayoutsUrl: Url.Relative = configurationUrl + moduleLayouts

    val quoteSnapshots: Url.Relative = Url.relative("quote-snapshots")
    val quoteSnapshotsUrl: Url.Relative = configurationUrl + quoteSnapshots
    val quoteSnapshotUrl: Url.Relative = quoteSnapshotsUrl + Parameters.quoteSnapshotId

    val earningsDistributionUrl: Url.Relative = configurationUrl + "earnings-distribution"
    val manualQuoteElementsUrl: Url.Relative = configurationUrl + "manual-quote-elements"

    fun projectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return projectsUrl + projectId.format()
    }

    fun archiveProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return archiveProjectsUrl + projectId.format()
    }

    fun resolvedProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return resolvedProjectsUrl + projectId.format()
    }

    fun configurationsForProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return projectUrl(projectId) + "configurations"
    }

    fun configurationUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationsForProjectUrl(projectId) + configurationId.format()
    }

    fun quoteConfigurationsForProjectUrl(projectId: PhotovoltaicsProjectId): Url.Relative {
      return resolvedProjectUrl(projectId) + "quote-configurations"
    }

    fun quoteConfigurationUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return quoteConfigurationsForProjectUrl(projectId) + configurationId.format()
    }

    fun quoteSnapshotUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId, snapshotId: QuoteSnapshotId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "quote-snapshots/${snapshotId}"
    }

    fun earningsDistributionUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "earnings-distribution"
    }

    fun manualQuoteElementsUrl(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): Url.Relative {
      return configurationUrl(projectId, configurationId) + "manual-quote-elements"
    }

  }
}
