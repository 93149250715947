package com.meistercharts.charts.lizergy.stringsPlanning

import com.meistercharts.charts.lizergy.roofPlanning.Clickable
import com.meistercharts.charts.lizergy.roofPlanning.Module


/**
 * Sealed interface for all possible UI states for the [PvStringsPlanningLayer]
 */
sealed interface StringsPlanningUiState {
  val data: PvStringsPlanningLayer.Configuration

  /**
   * Is called on mouse up event
   */
  fun mouseUp(): StringsPlanningUiState {
    return this
  }

  /**
   * Mouse has been moved out of the canvas
   */
  fun movedOutOfCanvas(): StringsPlanningUiState {
    return this
  }

  /**
   * Move over nothing (but within the canvas)
   */
  fun moveOverNothing(): StringsPlanningUiState {
    return this
  }

  /**
   * Mouse is hovering above the delete button of an unusable area
   */
  fun hoveringOver(clickable: Module): StringsPlanningUiState {
    return Hovering(data, clickable)
  }

  fun downOn(clickable: Module): StringsPlanningUiState {
    return this
  }

  /**
   * Down somewhere without any special areas
   */
  fun downOnNothing(): StringsPlanningUiState {
    return DefaultState(data)
  }

  /**
   * Formats the roof selection
   */
  fun formatSelection(): String {
    return "TODO"
  }
}

/**
 * Base class that holds an active element
 */
sealed class UiStateWithActiveElement(
  override val data: PvStringsPlanningLayer.Configuration,
  val activeModule: Module?,
) : StringsPlanningUiState {

  /**
   * Returns true if the active unusable area matches the given unusable area
   */
  fun isActive(clickable: Clickable): Boolean {
    return this.activeModule == clickable || this.activeModule is Module && this.activeModule.modulePlacement.moduleArea == clickable
  }

  /**
   * Returns true for an exact match or if the grid matches [matchesForGrid]
   */
  fun matchesLoose(module: Module): Boolean {
    return module.deleted.not() && (isActive(module) || (activeModule is Module && activeModule.modulePlacement.moduleArea == module.modulePlacement.moduleArea))
  }

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}

/**
 * Default state that does not have any special elements selected or anything at all
 */
class DefaultState(override val data: PvStringsPlanningLayer.Configuration) : StringsPlanningUiState {
  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}

/**
 * Hovering above an element with a visible buttons:
 * * remove button
 * * rotate for manual modules
 */
class Hovering(
  data: PvStringsPlanningLayer.Configuration,
  hovered: Module,
) : UiStateWithActiveElement(data, hovered), StringsPlanningUiState {

  override fun moveOverNothing(): StringsPlanningUiState {
    return DefaultState(data)
  }

  override fun movedOutOfCanvas(): StringsPlanningUiState {
    return DefaultState(data)
  }

  override fun downOn(clickable: Module): StringsPlanningUiState {
    require(clickable == this.activeModule) {
      "Modules do not match: ${this.activeModule} -- $clickable"
    }

    val selectedString = data.model.selectedString
    if (selectedString == null) {
      val newSelectedString = data.model.roofStringsConfiguration.roofStrings.firstOrNull { it.contains(clickable) }
      if (newSelectedString == null) return this
      data.model.selectedString = newSelectedString
    } else if (selectedString.contains(clickable).not()) {
      selectedString.add(clickable)
    }

    return PlanningString(data, clickable)
  }
}

class PlanningString(
  data: PvStringsPlanningLayer.Configuration,
  hovered: Module?,
) : UiStateWithActiveElement(data, hovered), StringsPlanningUiState {

  override fun hoveringOver(clickable: Module): StringsPlanningUiState {
    return PlanningString(data, clickable)
  }

  override fun moveOverNothing(): StringsPlanningUiState {
    return PlanningString(data, null)
  }

  override fun movedOutOfCanvas(): StringsPlanningUiState {
    return PlanningString(data, null)
  }

  override fun downOn(clickable: Module): StringsPlanningUiState {
    val selectedString = data.model.selectedString
    check(selectedString != null) { "No string selected" }
    if (selectedString.contains(clickable)) {
      selectedString.remove(clickable)
    } else {
      val newSelectedString = data.model.roofStringsConfiguration.roofStrings.firstOrNull { it.contains(clickable) }
      if (newSelectedString == null || newSelectedString == selectedString) {
        selectedString.add(clickable)
      } else {
        data.model.selectedString = newSelectedString
      }
    }
    return PlanningString(data, clickable)
  }

  override fun downOnNothing(): StringsPlanningUiState {
    data.model.selectedString = null
    return DefaultState(data)
  }
}
