package it.neckar.problem

import kotlinx.serialization.Serializable

/**
 * Represents application errors.
 * There are two classes of errors:
 * - [ExpectedApplicationError] for expected errors (e.g., database not available)
 * - [UnexpectedApplicationError] for unexpected errors (bugs)
 */
@Serializable
sealed interface ApplicationError {
  /**
   * The message of the problem - if no translation is available
   */
  val fallbackMessage: String?

  /**
   * URI to the concrete instance of the specific problem
   */
  val instanceUri: String?

  /**
   * The optional stack trace elements
   */
  val stackTrace: String?
}
