package components.project.configuration.layout.strings

import components.form.commentSection
import components.project.QuoteConfigurationTabProps
import components.project.configuration.isConfigurationEditable
import components.project.isProjectEditable
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.react.common.*
import it.neckar.react.common.setter
import it.neckar.react.common.value
import kotlinx.css.Overflow
import kotlinx.css.overflowX
import react.*
import react.dom.*
import router.useConfigurationIdFromUrl
import router.useDocumentTitle
import router.useLoadResolvedProjectFromUrl
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireCompanyForLoggedInUser
import styled.css
import styled.styledDiv

val ModuleStringsFromUrl: FC<Props> = fc("ModuleStringsFromUrl") {
  val resolvedProject = useLoadResolvedProjectFromUrl()
  val configurationId = useConfigurationIdFromUrl()
  val companyName = useRequireCompanyForLoggedInUser().simpleName
  val allProcessStatesForComponents = useLoadProcessStates()

  useDocumentTitle(companyName, "Stringplanung", allProcessStatesForComponents?.let { resolvedProject?.getDisplayName(it) }, resolvedProject?.get(configurationId)?.configurationName)

  busyIfNull(resolvedProject) { loadedProject ->
    val quoteConfiguration = loadedProject[configurationId]
    val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
    val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
    val editableStatus = loadedProject.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

    ModuleStringsOverview {
      attrs {
        this.project = loadedProject
        this.quoteConfiguration = quoteConfiguration
        this.editableStatus = editableStatus
      }
    }
  }
}

val ModuleStringsOverview: FC<QuoteConfigurationTabProps> = fc("ModuleStringsOverview") { props ->
  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val moduleLayouts = quoteConfiguration.moduleLayouts

  val facilityConfiguration = quoteConfiguration.facilityConfiguration.forTheseLayouts(moduleLayouts.validElements.map { it.id })
  val inverterSelection = facilityConfiguration.inverterSelection
  val inverterConfigurations = quoteConfiguration.facilityConfiguration.inverterConfigurations


  val inverterConfigurationsToSave: StateInstance<List<ResolvedInverterConfiguration>> = useState(inverterConfigurations)
  val stringConfigurationToEdit = useState<InverterConfiguration.StringConfiguration?>(null)
  val editInverterConfigurations = useState(false)


  useEffect(quoteConfiguration) {
    //Update the inverter configurations to save
    inverterConfigurationsToSave.setter(quoteConfiguration.facilityConfiguration.inverterConfigurations)
  }

  useMemo(inverterConfigurationsToSave.value) {
    if (inverterConfigurationsToSave.value == inverterConfigurations) return@useMemo
    val updatedQuoteConfiguration = quoteConfiguration.copy(
      facilityConfiguration = quoteConfiguration.facilityConfiguration.copy(
        inverterConfigurations = inverterConfigurationsToSave.value,
      )
    )
    UiActions.saveQuoteConfiguration(project, updatedQuoteConfiguration)
  }


  div("mb-5") {
    h3("mb-2") {
      +"Bemerkungen Dachflächen"
    }
    project.blueprint.roofsAnnotation?.let {
      p { +it }
    }
    commentSection(quoteConfiguration.moduleLayouts)
  }

  styledDiv {
    css {
      overflowX = Overflow.auto
    }

    if (editInverterConfigurations.value) {
      editFacilityConfiguration(moduleLayouts.moduleLayouts, inverterSelection, inverterConfigurationsToSave, editInverterConfigurations, editableStatus)
    } else {
      facilityConfigurationOverview(moduleLayouts.moduleLayouts, inverterSelection, stringConfigurationToEdit, inverterConfigurationsToSave, editInverterConfigurations, editableStatus)
    }
  }

  moduleLayouts.fastForEach { moduleLayout ->
    EditModuleStrings {
      attrs {
        this.project = project
        this.inverterConfigurationsToSave = inverterConfigurationsToSave
        this.moduleLayout = moduleLayout
        this.stringConfigurationToEdit = stringConfigurationToEdit
        this.editableStatus = editableStatus.and(editInverterConfigurations.value.not())
      }
    }
  }
}
