package components.project.configuration.layout.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningGestalt
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningLayer
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.react.meistercharts
import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvRoofPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvStringsPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRoofStringsConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import it.neckar.react.common.value
import kotlinx.atomicfu.atomic
import react.*
import react.dom.div

/**
 * Creates a MeisterCharts module strings planning element
 */
fun RBuilder.moduleStringsPlanning(
  currentModuleLayout: ResolvedModuleLayout,
  stringsPlanningModel: PvStringsPlanningModelInformation,
  roofPlanningModel: PvRoofPlanningModelInformation,
  inverterConfigurations: List<ResolvedInverterConfiguration>,
  stringConfigurationToEdit: StateInstance<InverterConfiguration.StringConfiguration?>,
  editableStatus: EditableStatus,
  changeListener: (updatedModel: PvStringsPlanningModel) -> Unit,
): Unit = child(ModuleStringsPlanning) {
  attrs {
    this.currentModuleLayout = currentModuleLayout
    this.stringsPlanningModel = stringsPlanningModel
    this.roofPlanningModel = roofPlanningModel
    this.inverterConfigurations = inverterConfigurations
    this.stringConfigurationToEdit = stringConfigurationToEdit
    this.editableStatus = editableStatus
    this.changeListener = changeListener
  }
}

val ModuleStringsPlanning: FC<ModuleStringsPlanningProps> = fc("ModuleStringsPlanning") { props ->
  val currentModuleLayout = props::currentModuleLayout.safeGet()
  val stringsPlanningModel = props::stringsPlanningModel.safeGet()
  val roofPlanningModel = props::roofPlanningModel.safeGet()
  val changeListener = props::changeListener.safeGet()
  val inverterConfigurations = props::inverterConfigurations.getNotNull()
  val stringConfigurationToEdit = props::stringConfigurationToEdit.getNotNull()
  val editableStatus = props::editableStatus.safeGet()


  /**
   * The *mutable* model that is updated
   */
  val updatingMutableModelFromReact = useMemo {
    atomic(false)
  }

  val mutableRoofPlanningModel = useMemo {
    PvRoofPlanningModel().also { mutableModel ->
      //Apply the loaded model initially!
      roofPlanningModel.applyToMutableModel(mutableModel)
    }
  }

  val mutableStringsPlanningModel = useMemo {
    PvStringsPlanningModel().also { mutableModel ->
      //Apply the loaded model initially!
      stringsPlanningModel.applyToMutableModel(mutableModel, currentModuleLayout, mutableRoofPlanningModel.moduleAreas)
      val relevantModulesString = stringConfigurationToEdit.value?.modulesStrings?.firstOrNull { it.moduleLayoutId == currentModuleLayout.id }
      mutableModel.set(relevantModulesString?.uuid)

      //Publish the changes
      val publishChanges: (newValue: Any?) -> Unit = {
        if (updatingMutableModelFromReact.value.not()) {
          changeListener(mutableModel)
        }
      }

      mutableModel.roofStringsConfiguration.roofStringsProperty.consume(action = publishChanges)
      mutableModel.roofStringsConfiguration.roofStringsProperty.consumeImmediately { roofStrings ->
        roofStrings.fastForEach {
          it.modulesProperty.consume(action = publishChanges)
        }
      }
      mutableModel.selectedStringProperty.consumeImmediately(action = publishChanges)
    }
  }

  val stringsPlanningGestalt: PvStringsPlanningGestalt = useMemo {
    PvStringsPlanningGestalt(mutableStringsPlanningModel, mutableRoofPlanningModel).also {
      it.pvStringsPlanningLayer.configuration.mode = when (editableStatus) {
        Editable -> PvStringsPlanningLayer.Mode.Planning
        ReadOnly -> PvStringsPlanningLayer.Mode.Rendering
      }
    }
  }

  //Apply the changes
  updatingMutableModelFromReact.value = true
  try {
    roofPlanningModel.applyToMutableModel(mutableRoofPlanningModel)

    mutableStringsPlanningModel.roofStringsConfiguration.set(inverterConfigurations.toRoofStringsConfiguration(currentModuleLayout, mutableRoofPlanningModel.moduleAreas))
    val relevantModulesString = stringConfigurationToEdit.value?.modulesStrings?.firstOrNull { it.moduleLayoutId == currentModuleLayout.id }
    stringsPlanningGestalt.pvStringsPlanningLayer.stringSelected(relevantModulesString?.uuid)

    stringsPlanningGestalt.pvStringsPlanningLayer.configuration.mode = when (editableStatus) {
      Editable -> PvStringsPlanningLayer.Mode.Planning
      ReadOnly -> PvStringsPlanningLayer.Mode.Rendering
    }
  } finally {
    updatingMutableModelFromReact.value = false
  }


  div("row my-5") {
    meistercharts(
      description = "String Planner",
      gestalt = stringsPlanningGestalt,
      // Always mark as dirty on rerender. MeisterCharts is fast!
      callMarkAsDirty = true,
    )
  }

}


external interface ModuleStringsPlanningProps : Props {
  var currentModuleLayout: ResolvedModuleLayout
  var stringsPlanningModel: PvStringsPlanningModelInformation
  var roofPlanningModel: PvRoofPlanningModelInformation
  var changeListener: (updatedModel: PvStringsPlanningModel) -> Unit
  var inverterConfigurations: List<ResolvedInverterConfiguration>
  var stringConfigurationToEdit: StateInstance<InverterConfiguration.StringConfiguration?>
  var editableStatus: EditableStatus
}
