package components.project

import components.project.dashboard.ProcessStatesOverview
import components.project.dashboard.ProjectPhase
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.company.user.AccessRights.AccessRight
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry.DocumentationProcessStates
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.open.collections.fastForEach
import it.neckar.processStates.ProcessStatePhase
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.react.common.*
import kotlinx.html.title
import react.*
import react.dom.*
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useLoadProcessStatesForComponents
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

val ProjectOverviewHeader: FC<ProjectOverviewHeaderProps> = fc("ProjectOverviewHeader") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()

  val project = props::project.safeGet()
  val currentProjectPhase = props::currentProjectPhase.safeGet()
  val processStatesResolver = props::processStatesResolver.safeGet()

  val currentProjectProcessStateEntry = useLoadProcessStatesForComponent(project.uuid)?.current()

  val processStateEntries = useLoadProcessStatesForComponents(project.containedUuids.filter { it != project.uuid })
    .mapNotNull { it?.current() }
  val mostAdvancedProcessStateEntry = processStateEntries.maxOrNull()

  val isArchived = currentProjectProcessStateEntry?.processState?.inPhase == ProcessStatePhase.Paused


  p("mt-0") {

    if (loggedInUser.accessRights.canAccess(AccessRight.DeleteProjects)) {
      div("row") {
        div("col-md-6 mb-3") {
          editProcessStateEntry(
            processStateFor = project,
            availableProcessStates = ProjectProcessStateEntry.ProjectProcessStates.entries,
            editableStatus = project.isProjectEditable(),
          )
        }
        if (loggedInUser.accessRights.canAccess(AccessRight.VerifyProjects)) {
          div("col-md-6 mb-3") {
            if (project.verification != null || isArchived || mostAdvancedProcessStateEntry?.processState == DocumentationProcessStates.Done) {
              if (project.verification == null) {
                verifyModal(project)
              } else {
                unVerifyModal(project)
              }
            }
          }
        }
      }
    } else {
      val maxProcessStateEntries = processStateEntries.filter { mostAdvancedProcessStateEntry == null || it >= mostAdvancedProcessStateEntry }
      if (maxProcessStateEntries.isNotEmpty()) {
        maxProcessStateEntries.fastForEach { processStateEntry ->
          span("me-2") {
            processStatePillWithDateAndEditor(processStateEntry)
          }
        }
      } else {
        this@p.OLDdetailedProcessStateLabel(project.processState?.currentEdit, project.getCurrentQuoteConfiguration(processStatesResolver)?.currentQuoteSnapshot?.quote?.quoteNumber, userResolver)
      }
    }

  }

  p {
    val currentConfiguration = project.getCurrentQuoteConfiguration(processStatesResolver)
    currentConfiguration?.earningsDistribution?.montageDach?.company?.let { montageDachCompany ->
      if (montageDachCompany != project.sellingCompany) {
        span("badge rounded-pill bg-info me-2") {
          attrs {
            title = "Dachmontage von ${companyResolver[montageDachCompany].name} durchgeführt"
          }
          i(FontAwesomeIcons.solarPanel) {}
        }
      }
    }
    currentConfiguration?.earningsDistribution?.elektroInstallation?.company?.let { elektroInstallationCompany ->
      if (elektroInstallationCompany != project.sellingCompany) {
        span("badge rounded-pill bg-warning me-2") {
          attrs {
            title = "Elektroinstallation Haus von ${companyResolver[elektroInstallationCompany].name} durchgeführt"
          }
          i(FontAwesomeIcons.bolt) {}
        }
      }
    }
    if (currentConfiguration?.assemblyConfiguration?.assemblyStatus?.isBeingSelfAssembled == true) {
      span("badge rounded-pill bg-dark me-2") {
        attrs {
          title = "Eigenmontage"
        }
        i(FontAwesomeIcons.screwDriverWrench) {}
      }
    }
  }

  h1 {
    attrs {
      this.title = project.projectId.format()
    }

    +project.getDisplayName(processStatesResolver)
  }

  p("my-3") {
    +project.projectDescription
  }

  p("form-text") {
    +"${project.customer.completeName}, ${project.getLocation(processStatesResolver).address.singleLine()}"
  }

  if (isArchived) {
    div("my-5") {
      hr { }
      archiveReasonsOverview(project, project.archiveReasons)
      hr { }
    }
  }

  div("my-5") {
    ProcessStatesOverview {
      attrs {
        this.project = project
        this.currentProjectPhase = currentProjectPhase
        this.processStatesResolver = processStatesResolver
      }
    }
  }
}


external interface ProjectOverviewHeaderProps : Props {
  var project: ResolvedProject
  var currentProjectPhase: ProjectPhase?
  var processStatesResolver: ProcessStatesResolver
}
