package com.meistercharts.charts.lizergy.stringsPlanning

import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea.ModuleAreaId
import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex
import kotlinx.serialization.Serializable

@Serializable
data class ModuleReference(
  val moduleAreaId: ModuleAreaId,
  val moduleIndex: ModuleIndex,
)
