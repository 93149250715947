package components.project.configuration.layout.moduleLayout.planning

import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningGestalt
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningLayer
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.react.meistercharts
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.moduleLayout.planning.PvStringsPlanningModelInformation
import it.neckar.lizergy.model.configuration.moduleLayout.planning.toRestApiModel
import it.neckar.open.collections.fastForEach
import it.neckar.react.common.form.*
import it.neckar.react.common.form.EditableStatus.*
import kotlinx.atomicfu.atomic
import react.*
import react.dom.*

/**
 * Creates a MeisterCharts module strings planning element
 */
fun RBuilder.moduleStringsPlanning(
  model: PvStringsPlanningModelInformation,
  editableStatus: EditableStatus,
  changeListener: (updatedModel: PvStringsPlanningModelInformation) -> Unit,
): Unit = child(ModuleStringsPlanning) {
  attrs {
    this.model = model
    this.editableStatus = editableStatus
    this.changeListener = changeListener
  }
}

val ModuleStringsPlanning: FC<ModuleStringsPlanningProps> = fc("ModuleStringsPlanning") { props ->
  val model = props::model.safeGet()
  val changeListener = props::changeListener.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  /**
   * The *mutable* model that is updated
   */
  val updatingMutableModelFromReact = useMemo {
    atomic(false)
  }

  val mutableModel = useMemo {
    PvStringsPlanningModel().also { mutableModel ->
      //Apply the loaded model initially!
      model.applyToMutableModel(mutableModel)

      //Publish the changes
      val publishChanges: (newValue: Any?) -> Unit = {
        if (!updatingMutableModelFromReact.value) {
          changeListener(mutableModel.toRestApiModel())
        }
      }


      /**
       * Register the location listeners for unusable areas
       */
      mutableModel.unusableAreas.unusableAreas.fastForEach { unusableArea: UnusableArea ->
        unusableArea.locationProperty.consume(action = publishChanges)
        unusableArea.sizeProperty.consume(action = publishChanges)
        unusableArea.rightTriangleTypeProperty.consume(action = publishChanges)
      }

      mutableModel.unusableAreas.unusableAreasProperty.consume { newUnusableAreas ->
        newUnusableAreas.fastForEach {
          it.locationProperty.consume(action = publishChanges)
          it.sizeProperty.consume(action = publishChanges)
          it.rightTriangleTypeProperty.consume(action = publishChanges)
        }
      }

      mutableModel.moduleAreas.moduleAreas.fastForEach {
        it.locationProperty.consume(action = publishChanges)
        it.sizeProperty.consume(action = publishChanges)
        it.moduleOrientationProperty.consume(action = publishChanges)

        it.modules.visibleModulesProperty.consume(action = publishChanges)
        it.modules.visibleModulesProperty.consume { modules ->
          modules.fastForEach { module ->
            module.deletedProperty.consume(action = publishChanges)
          }
        }
        it.modules.visibleModules.fastForEach { module ->
          module.deletedProperty.consume(action = publishChanges)
        }
      }

      mutableModel.moduleAreas.moduleAreasProperty.consume { newModuleAreas ->
        newModuleAreas.fastForEach {
          it.locationProperty.consume(action = publishChanges)
          it.sizeProperty.consume(action = publishChanges)
          it.moduleOrientationProperty.consume(action = publishChanges)

          it.modules.visibleModulesProperty.consume(action = publishChanges)
          it.modules.visibleModulesProperty.consume { modules ->
            modules.fastForEach { module ->
              module.deletedProperty.consume(action = publishChanges)
            }
          }
          it.modules.visibleModules.fastForEach { module ->
            module.deletedProperty.consume(action = publishChanges)
          }
        }
      }
    }
  }

  //Apply the changes
  updatingMutableModelFromReact.value = true
  try {
    mutableModel.roofSize = model.roofSize
    mutableModel.modulesSize = model.moduleSize
    mutableModel.suggestedRoofInsets = model.suggestedRoofInsets

    //do *NOT* updated the modules and unusable areas
  } finally {
    updatingMutableModelFromReact.value = false
  }

  val stringsPlanningGestalt: PvStringsPlanningGestalt = useMemo {
    PvStringsPlanningGestalt(mutableModel).also {
      it.pvStringsPlanningLayer.configuration.mode = when (editableStatus) {
        Editable -> PvStringsPlanningLayer.Mode.Planning
        ReadOnly -> PvStringsPlanningLayer.Mode.Rendering
      }
    }
  }


  meistercharts(
    description = "String Planner",
    gestalt = stringsPlanningGestalt,
    // Always mark as dirty on rerender. MeisterCharts is fast!
    callMarkAsDirty = true
  )

  div("row") {
    div("col col-md-3") {
      h4("mb-2") {
        +"Modulflächen"
      }
    }
  }
}


external interface ModuleStringsPlanningProps : Props {
  var model: PvStringsPlanningModelInformation
  var changeListener: (updatedModel: PvStringsPlanningModelInformation) -> Unit
  var editableStatus: EditableStatus
}
