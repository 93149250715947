package components.project.configuration.layout.strings

import com.benasher44.uuid.uuid4
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningLayer
import it.neckar.lizergy.model.configuration.moduleLayout.ResolvedModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString
import it.neckar.lizergy.model.configuration.quote.builder.Inverter
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterSelection
import it.neckar.lizergy.model.configuration.quote.builder.withCleared
import it.neckar.lizergy.model.configuration.quote.builder.withDeleted
import it.neckar.lizergy.model.configuration.quote.builder.withReversed
import it.neckar.lizergy.model.configuration.quote.builder.withUpdatedStringConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.react.common.FontAwesome.faAdd
import it.neckar.react.common.FontAwesome.faCancel
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.FontAwesome.faCircleQuestion
import it.neckar.react.common.FontAwesome.faReload
import it.neckar.react.common.FontAwesome.faTrash
import it.neckar.react.common.addClass
import it.neckar.react.common.addClassIf
import it.neckar.react.common.confirm
import it.neckar.react.common.form.EditableStatus
import it.neckar.react.common.form.EditableStatus.ReadOnly
import it.neckar.react.common.setter
import it.neckar.react.common.value
import kotlinx.css.Color
import kotlinx.css.backgroundColor
import kotlinx.css.color
import kotlinx.html.DIV
import kotlinx.html.ThScope
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import kotlinx.html.title
import react.StateInstance
import react.dom.RDOMBuilder
import react.dom.attrs
import react.dom.b
import react.dom.button
import react.dom.p
import react.dom.span
import react.dom.table
import react.dom.tbody
import react.dom.td
import react.dom.th
import react.dom.thead
import react.dom.tr
import styled.css
import styled.styledSpan

fun RDOMBuilder<DIV>.facilityConfigurationOverview(
  moduleLayouts: List<ResolvedModuleLayout>,
  inverterSelection: ResolvedInverterSelection,
  stringConfigurationToEdit: StateInstance<InverterConfiguration.StringConfiguration?>,
  inverterConfigurationsToSave: StateInstance<List<ResolvedInverterConfiguration>>,
  editInverterConfigurations: StateInstance<Boolean>,
  editableStatus: EditableStatus,
) {
  val numberOfModules = moduleLayouts.sumOf { it.modulesCount }
  val numberOfOptimalModules = inverterConfigurationsToSave.value.sumOf { it.numberOfOptimalModules }

  table("table table-hover table-responsive align-middle") {
    thead {
      tr {
        th(ThScope.col, "text-center align-middle") {
          attrs {
            colSpan = "4"
          }

          button(classes = "btn") {
            attrs {
              onClickFunction = {
                editInverterConfigurations.setter { !it }
              }
              disabled = editableStatus == ReadOnly
            }

            if (editInverterConfigurations.value) {
              addClass("btn-primary")
              +"Optimale Module bestätigen"
            } else {
              addClass("btn-outline-primary")
              +"Optimale Module bearbeiten"
            }
          }

          if (numberOfOptimalModules != numberOfModules) {
            p("form-text text-danger") {
              +"Die Anzahl der geplanten Module (${numberOfOptimalModules}) stimmt nicht mit der Anzahl der Module auf den Dächern (${numberOfModules}) überein."
            }
          }
        }

        moduleLayouts.fastForEach { moduleLayout ->
          val facilityConfigurationForLayout = inverterConfigurationsToSave.value.map { it.forTheseLayouts(listOf(moduleLayout.id)) }
          val numberOfPlannedModules = facilityConfigurationForLayout.sumOf { it.numberOfPlannedModules }

          th(ThScope.col) {
            p {
              +moduleLayout.layoutName
              span("ms-2") {
                if (numberOfPlannedModules == moduleLayout.modulesCount) {
                  addClass("text-success")
                  faCircleCheck()
                } else {
                  addClass("text-danger")
                  faCircleExclamation()
                }
              }
            }
            p {
              span("form-text") { +"$numberOfPlannedModules / ${moduleLayout.modulesCount} Module geplant" }
            }
          }
        }
      }
    }

    tbody {

      inverterConfigurationsToSave.value.fastForEachIndexed { inverterIndex, inverterConfiguration ->
        val inverter = inverterConfiguration.inverter
        val previousInverterStringCount = inverterConfigurationsToSave.value.take(inverterIndex).sumOf {
          it.mpptInputConfigurations.sumOf {
            it.stringConfigurations.count {
              val optimalModuleCount = it.optimalModuleCount
              optimalModuleCount != null && optimalModuleCount > 0
            }
          }
        }

        tr {
          th(ThScope.row) {
            +"${inverterIndex + 1}"
          }

          td {
            attrs {
              colSpan = "3"
            }

            b("me-2") {
              +inverter.description
            }
            if (inverter is Inverter && inverterSelection[inverter] > 1) +"#${inverterConfiguration.inverterIndex + 1}"
          }

          moduleLayouts.fastForEach {
            td {}
          }
        }

        val relevantMpptConfigurations = inverterConfiguration.mpptInputConfigurations.filter {
          it.stringConfigurations.any {
            val optimalModuleCount = it.optimalModuleCount
            optimalModuleCount != null && optimalModuleCount > 0
          }
        }
        relevantMpptConfigurations.fastForEachIndexed { mpptInputIndex, mpptInputConfiguration ->
          val previousMpptInputStringCount = relevantMpptConfigurations.take(mpptInputIndex).sumOf {
            it.stringConfigurations.count {
              val optimalModuleCount = it.optimalModuleCount
              optimalModuleCount != null && optimalModuleCount > 0
            }
          }

          tr {
            td {}
            th(ThScope.row) {
              +"${'A' + mpptInputConfiguration.inputIndex}"
            }

            td {
              attrs {
                colSpan = "2"
              }

              b("me-2") {
                +"MPP-Eingang"
              }
              +"${mpptInputConfiguration.inputIndex + 1}"

              if (mpptInputConfiguration.hasLegalOptimalModuleCount.not()) {
                p("form-text text-danger") {
                  +"Pro MPP-Eingang müssen alle Strings die gleiche Anzahl an Modulen haben oder leer sein."
                }
              }
            }

            moduleLayouts.fastForEach {
              td {}
            }
          }


          val relevantStringConfigurations = mpptInputConfiguration.stringConfigurations.filter {
            val optimalModuleCount = it.optimalModuleCount
            optimalModuleCount != null && optimalModuleCount > 0
          }
          relevantStringConfigurations.fastForEachIndexed { stringIndex, stringConfiguration ->
            val optimalModuleCount = stringConfiguration.optimalModuleCount
            val totalModuleCount = stringConfiguration.totalModuleCount
            val globalStringIndex = previousInverterStringCount + previousMpptInputStringCount + stringIndex
            val stringColor = PvStringsPlanningLayer.Configuration.defaultStringColors.valueAt(globalStringIndex)
            val stringLabelBoxTextColor = PvStringsPlanningLayer.Configuration.defaultStringLabelBoxTextColors.valueAt(globalStringIndex)

            tr {
              attrs {
                addClassIf("table-primary") { stringConfiguration == stringConfigurationToEdit.value }
                onClickFunction = {
                  stringConfigurationToEdit.setter(stringConfiguration)
                }
              }

              td {
                attrs {
                  colSpan = "2"
                }
              }
              th(ThScope.row) {
                +"${stringIndex + 1}"
              }

              td {
                styledSpan {
                  attrs {
                    classes = setOf("badge", "me-3")
                  }
                  css {
                    color = Color(stringLabelBoxTextColor.web)
                    backgroundColor = Color(stringColor.web)
                  }

                  +"String #${globalStringIndex + 1}"
                }
                span {
                  span("me-2") {
                    if (totalModuleCount == optimalModuleCount) {
                      addClass("text-success")
                      faCircleCheck()
                    } else if (optimalModuleCount != null) {
                      if (optimalModuleCount > totalModuleCount) {
                        addClass("text-warning")
                        faCircleExclamation()
                      } else if (totalModuleCount > optimalModuleCount) {
                        addClass("text-danger")
                        faCircleExclamation()
                      }
                    } else {
                      addClass("text-danger")
                      faCircleQuestion()
                    }
                  }

                  span("form-text") { +"$totalModuleCount / $optimalModuleCount Module" }
                }
              }

              moduleLayouts.fastForEach { moduleLayout ->
                val modulesString = stringConfiguration.modulesStrings?.firstOrNull { it.moduleLayoutId == moduleLayout.id }

                td {
                  if (modulesString != null) {
                    +"${modulesString.numberOfModules} Module"

                    button(classes = "btn btn-link btn-sm") {
                      attrs {
                        title = "Reihenfolge der Module im String auf diesem Dach umdrehen"
                        onClickFunction = {
                          inverterConfigurationsToSave.setter { old ->
                            old.withReversed(modulesString)
                          }
                        }
                        disabled = editableStatus == ReadOnly
                      }

                      faReload()
                    }

                    button(classes = "btn btn-link btn-sm") {
                      attrs {
                        title = "String für dieses Dach leeren"
                        onClickFunction = {
                          confirm("Soll dieser String für das Dach ${moduleLayout.layoutName} geleert werden?") {
                            inverterConfigurationsToSave.setter { old ->
                              old.withCleared(modulesString)
                            }
                          }
                        }
                        disabled = editableStatus == ReadOnly
                      }

                      faCancel()
                    }

                    button(classes = "btn btn-link btn-sm") {
                      attrs {
                        title = "String für dieses Dach löschen"
                        onClickFunction = {
                          confirm("Soll dieser String für das Dach ${moduleLayout.layoutName} gelöscht werden?") {
                            inverterConfigurationsToSave.setter { old ->
                              old.withDeleted(modulesString)
                            }
                            if (stringConfigurationToEdit.value == modulesString) stringConfigurationToEdit.setter(null)
                          }
                        }
                        disabled = editableStatus == ReadOnly
                      }

                      faTrash()
                    }

                  } else {
                    button(classes = "btn btn-link btn-sm") {
                      attrs {
                        title = "String für dieses Dach hinzufügen"
                        onClickFunction = {
                          val newModulesString = ModulesString(id = ModulesString.StringId(uuid4()), moduleLayoutId = moduleLayout.id, stringOfModules = emptyList())
                          val newStringConfiguration = stringConfiguration.copy(modulesStrings = (stringConfiguration.modulesStrings ?: emptyList()) + newModulesString)
                          inverterConfigurationsToSave.setter { old ->
                            old.withUpdatedStringConfiguration(newStringConfiguration, stringConfiguration)
                          }
                          stringConfigurationToEdit.setter(newStringConfiguration)
                        }

                        disabled = editableStatus == ReadOnly
                      }

                      faAdd()
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
