package components.project

import com.benasher44.uuid.uuid4
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.price.AccountingStatus
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.Verification
import it.neckar.lizergy.model.project.Verification.ConfigurationIdAndProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AdvanceInvoiceProcessStateEntry
import it.neckar.lizergy.model.project.process.state.AssemblyPortfolioProcessStateEntry
import it.neckar.lizergy.model.project.process.state.BlueprintProcessStateEntry
import it.neckar.lizergy.model.project.process.state.DocumentationProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinalAccountProcessStateEntry
import it.neckar.lizergy.model.project.process.state.FinishingUpProcessStateEntry
import it.neckar.lizergy.model.project.process.state.GridAssessmentProcessStateEntry
import it.neckar.lizergy.model.project.process.state.OrderSpecialMaterialProcessStateEntry
import it.neckar.lizergy.model.project.process.state.ProjectProcessStateEntry
import it.neckar.lizergy.model.project.process.state.StartupOperationsProcessStateEntry
import it.neckar.lizergy.model.project.process.state.SwitchMeterBoxProcessStateEntry
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessStateEntry
import it.neckar.open.collections.fastForEach
import it.neckar.open.time.nowMillis
import it.neckar.processStates.ProcessStatePhase
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.faCircleCheck
import it.neckar.react.common.FontAwesome.faCircleExclamation
import it.neckar.react.common.form.*
import plannerI18nConfiguration
import react.*
import react.dom.*
import services.UiActions
import store.hooks.useLoadProcessStates
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectCompanyResolver
import store.hooks.useSelectUserResolver

fun RBuilder.verifyModal(project: ResolvedProject): Unit = child(VerifyModal) {
  attrs {
    this.project = project
  }
}

val VerifyModal: FC<VerifyModalProps> = fc("VerifyModal") { props ->
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()
  val companyResolver = useSelectCompanyResolver()
  val processStatesResolver = useLoadProcessStates()

  val project = props::project.safeGet()
  val verification = project.verification

  val projectProcessStateEntry = useLoadProcessStatesForComponent(project.uuid)?.current()
  val blueprintProcessStateEntry = useLoadProcessStatesForComponent(project.blueprintId)?.current()
  val configurationIdAndProcessStateEntries = project.quoteConfigurations.validElements.mapNotNull { configuration ->
    val processStateEntry = useLoadProcessStatesForComponent(configuration.uuid)?.current() ?: configuration.editor?.let { configuration.processStateEdit?.toNewProcessStateEntry(userResolver[it]) }
    processStateEntry?.let {
      ConfigurationIdAndProcessStateEntry(
        configurationId = configuration.configurationId,
        quoteSnapshotId = configuration.currentQuoteSnapshot?.id,
        processStateEntry = processStateEntry,
      )
    }
  }

  val orderSpecialMaterialProcessStateEntry = useLoadProcessStatesForComponent(project.orderSpecialMaterialId)?.current()
  val gridAssessmentProcessStateEntry = useLoadProcessStatesForComponent(project.gridAssessmentId)?.current()
  val assemblyPortfolioProcessStateEntry = useLoadProcessStatesForComponent(project.assemblyPortfolioId)?.current()
  val advanceInvoiceProcessStateEntry = useLoadProcessStatesForComponent(project.advanceInvoiceId)?.current()
  val assemblyRoofProcessStateEntry = useLoadProcessStatesForComponent(project.assemblyRoofId)?.current()
  val assemblyBasementProcessStateEntry = useLoadProcessStatesForComponent(project.assemblyBasementId)?.current()
  val switchMeterBoxProcessStateEntry = useLoadProcessStatesForComponent(project.switchMeterBoxId)?.current()
  val startupOperationsProcessStateEntry = useLoadProcessStatesForComponent(project.startupOperationsId)?.current()
  val finishingUpProcessStateEntry = useLoadProcessStatesForComponent(project.finishingUpId)?.current()
  val finalAccountProcessStateEntry = useLoadProcessStatesForComponent(project.finalAccountId)?.current()
  val documentationProcessStateEntry = useLoadProcessStatesForComponent(project.documentationId)?.current()

  val currentConfiguration = processStatesResolver?.let { project.getCurrentQuoteConfiguration(it) }

  val archiveReasons = project.archiveReasons

  val additionalInfo = useState(verification?.additionalInfo.orEmpty())

  busyIfNull(processStatesResolver, currentConfiguration) { processStatesResolver, currentConfiguration ->
    val earningsDistribution = currentConfiguration.earningsDistribution
    val quoteElements = currentConfiguration.getQuoteElements()
    val manualQuoteElements = currentConfiguration.manualQuoteElements

    actionButtonWithConfirmationModal(
      icon = FontAwesomeIcons.clipboardCheck,
      confirmationButtonText = "Korrektheit bestätigen",
      cancelButtonText = "Abbrechen",
      buttonClasses = "btn btn-primary",
      buttonContent = { span("ps-2") { +"Projekt überprüfen" } },
      modalTitle = "Bitte überprüfen Sie die Korrektheit der Angaben",
      modalSize = ModalSize.Large,
      modalContent = {

        projectProcessStateEntry?.let { p { processStatePillWithDateAndEditor(processStateEntry = projectProcessStateEntry) } }
        if (projectProcessStateEntry?.processState?.inPhase == ProcessStatePhase.Paused) p { +archiveReasons.format() }

        if (manualQuoteElements.manualSumsForTags.isNotEmpty()) {
          h3 { +"Manuelle Preise" }

          manualQuoteElements.manualSumsForTags.forEach {
            p {
              val currentEdit = it.value.currentEdit
              b { +"${it.key.description}:" }
              +" ${currentEdit.value?.format(plannerI18nConfiguration) ?: "-"} | ${currentEdit.loginName?.let { userResolver[it].editorName } ?: "-"}"
            }
          }
        }

        h3 { +"Rechnungssummen" }

        p("mb-3") {
          b { +"Nettosumme:" }
          +" ${quoteElements.netPrice.format(plannerI18nConfiguration)}"
        }

        quoteElements.sumsForTags.forEach {
          p {
            b { +"${it.key.description}:" }
            +" ${it.value.format(plannerI18nConfiguration)}"
          }
        }

        h3 { +"Kooperationspartner" }

        earningsDistribution.contributingCompanies.fastForEach { companyCode ->
          val company = companyResolver[companyCode]
          p {
            b { +"${company.name}:" }
            +" ${currentConfiguration.getEarningsForCompany(companyCode).format(plannerI18nConfiguration)}"
          }
        }

        p {
          b { +"Neckar IT:" }
          +" ${currentConfiguration.getNeckarITEarnings().format(plannerI18nConfiguration)}"
        }


        div("mt-4") {
          inputArea(
            valueAndSetter = additionalInfo,
            fieldName = "additionalInfo",
            title = "Sonstige Informationen",
            placeHolder = "Sonstige Informationen",
          ) { }
        }


        h3 { +"Abrechnung" }

        p {
          b { +"Tippgeber:" }
          +" ${companyResolver[earningsDistribution.tippgeber.company].name}"
          if (earningsDistribution.tippgeber.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Vertrieb Projekterfassung:" }
          +" ${companyResolver[earningsDistribution.vertriebProjekterfassung.company].name}"
          if (earningsDistribution.vertriebProjekterfassung.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Vertrieb Angebotsvorstellung:" }
          +" ${companyResolver[earningsDistribution.vertriebAngebotsvorstellung.company].name}"
          if (earningsDistribution.vertriebAngebotsvorstellung.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Technische Planung Dach:" }
          +" ${companyResolver[earningsDistribution.technischePlanungDach.company].name}"
          if (earningsDistribution.technischePlanungDach.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Technische Planung Elektrik:" }
          +" ${companyResolver[earningsDistribution.technischePlanungElektrik.company].name}"
          if (earningsDistribution.technischePlanungElektrik.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Montage Dach:" }
          +" ${companyResolver[earningsDistribution.montageDach.company].name}"
          if (earningsDistribution.montageDach.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Montage Gerüst:" }
          +" ${companyResolver[earningsDistribution.montageGeruest.company].name}"
          if (earningsDistribution.montageGeruest.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Elektro Installation:" }
          +" ${companyResolver[earningsDistribution.elektroInstallation.company].name}"
          if (earningsDistribution.elektroInstallation.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Netzvoranfrage:" }
          +" ${companyResolver[earningsDistribution.netzvoranfrage.company].name}"
          if (earningsDistribution.netzvoranfrage.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }
        p {
          b { +"Neckar IT:" }
          +" ${companyResolver[earningsDistribution.neckarITAccountingStatus.company].name}"
          if (earningsDistribution.neckarITAccountingStatus.accountingStatus == AccountingStatus.Accounted) {
            span("text-success ps-2") { faCircleCheck() }
          } else {
            span("text-danger ps-2") { faCircleExclamation() }
          }
        }

        h3 { +"Zustände" }

        documentationProcessStateEntry?.let {
          p {
            b { +"Dokumentation: " }
            processStatePillWithDateAndEditor(processStateEntry = documentationProcessStateEntry)
          }
        }
        finalAccountProcessStateEntry?.let {
          p {
            b { +"Abschlussrechnung: " }
            processStatePillWithDateAndEditor(processStateEntry = finalAccountProcessStateEntry)
          }
        }
        finishingUpProcessStateEntry?.let {
          p {
            b { +"Fertigstellung: " }
            processStatePillWithDateAndEditor(processStateEntry = finishingUpProcessStateEntry)
          }
        }
        startupOperationsProcessStateEntry?.let {
          p {
            b { +"Inbetriebnahme: " }
            processStatePillWithDateAndEditor(processStateEntry = startupOperationsProcessStateEntry)
          }
        }
        switchMeterBoxProcessStateEntry?.let {
          p {
            b { +"Zählertausch: " }
            processStatePillWithDateAndEditor(processStateEntry = switchMeterBoxProcessStateEntry)
          }
        }
        assemblyBasementProcessStateEntry?.let {
          p {
            b { +"Montage Keller: " }
            processStatePillWithDateAndEditor(processStateEntry = assemblyBasementProcessStateEntry)
          }
        }
        assemblyRoofProcessStateEntry?.let {
          p {
            b { +"Montage Dach: " }
            processStatePillWithDateAndEditor(processStateEntry = assemblyRoofProcessStateEntry)
          }
        }
        advanceInvoiceProcessStateEntry?.let {
          p {
            b { +"Anzahlungsrechnung: " }
            processStatePillWithDateAndEditor(processStateEntry = advanceInvoiceProcessStateEntry)
          }
        }
        assemblyPortfolioProcessStateEntry?.let {
          p {
            b { +"Montagemappe: " }
            processStatePillWithDateAndEditor(processStateEntry = assemblyPortfolioProcessStateEntry)
          }
        }
        gridAssessmentProcessStateEntry?.let {
          p {
            b { +"Netzvoranfrage: " }
            processStatePillWithDateAndEditor(processStateEntry = gridAssessmentProcessStateEntry)
          }
        }
        orderSpecialMaterialProcessStateEntry?.let {
          p {
            b { +"Sondermaterialbestellung: " }
            processStatePillWithDateAndEditor(processStateEntry = orderSpecialMaterialProcessStateEntry)
          }
        }
        configurationIdAndProcessStateEntries.fastForEach { configurationIdAndProcessStateEntry ->
          p {
            b { +"${project[configurationIdAndProcessStateEntry.configurationId].configurationName}: " }
            processStatePillWithDateAndEditor(processStateEntry = configurationIdAndProcessStateEntry.processStateEntry)
          }
        }
        blueprintProcessStateEntry?.let {
          p {
            b { +"Projekterfassung: " }
            processStatePillWithDateAndEditor(processStateEntry = blueprintProcessStateEntry)
          }
        }

      },
    ) {
      val newVerification = Verification(
        uuid = uuid4(),
        verifiedBy = loggedInUser.loginName,
        verifiedAt = nowMillis(),
        projectId = project.projectId,
        projectProcessStateEntry = projectProcessStateEntry as ProjectProcessStateEntry?,
        blueprintProcessStateEntry = blueprintProcessStateEntry as BlueprintProcessStateEntry?,
        configurationIdAndProcessStateEntries = configurationIdAndProcessStateEntries,
        orderSpecialMaterialProcessStateEntry = orderSpecialMaterialProcessStateEntry as OrderSpecialMaterialProcessStateEntry?,
        gridAssessmentProcessStateEntry = gridAssessmentProcessStateEntry as GridAssessmentProcessStateEntry?,
        assemblyPortfolioProcessStateEntry = assemblyPortfolioProcessStateEntry as AssemblyPortfolioProcessStateEntry?,
        advanceInvoiceProcessStateEntry = advanceInvoiceProcessStateEntry as AdvanceInvoiceProcessStateEntry?,
        assemblyRoofProcessStateEntry = assemblyRoofProcessStateEntry,
        assemblyBasementProcessStateEntry = assemblyBasementProcessStateEntry,
        switchMeterBoxProcessStateEntry = switchMeterBoxProcessStateEntry as SwitchMeterBoxProcessStateEntry?,
        startupOperationsProcessStateEntry = startupOperationsProcessStateEntry as StartupOperationsProcessStateEntry?,
        finishingUpProcessStateEntry = finishingUpProcessStateEntry as FinishingUpProcessStateEntry?,
        finalAccountProcessStateEntry = finalAccountProcessStateEntry as FinalAccountProcessStateEntry?,
        documentationProcessStateEntry = documentationProcessStateEntry as DocumentationProcessStateEntry?,
        archiveReasons = archiveReasons,
        earningsDistribution = earningsDistribution,
        quoteElements = quoteElements,
        manualQuoteElements = manualQuoteElements,
        additionalInfo = additionalInfo.value,
      )

      UiActions.saveVerification(
        project = project,
        verification = newVerification,
      )

      additionalInfo.setter("")
    }
  }

}


fun RBuilder.unVerifyModal(project: ResolvedProject): Unit = child(UnVerifyModal) {
  attrs {
    this.project = project
  }
}


val UnVerifyModal: FC<VerifyModalProps> = fc("UnVerifyModal") { props ->
  val project = props::project.safeGet()

  actionButtonWithConfirmationModal(
    icon = FontAwesomeIcons.ban,
    buttonClasses = "btn btn-danger",
    buttonContent = { span("ps-2") { +"Prüfung löschen" } },
    modalTitle = "Dieses Projekt wirkich erneut bearbeiten?",
    modalContent = {},
  ) {
    UiActions.deleteVerification(project)
  }

}


external interface VerifyModalProps : Props {
  var project: ResolvedProject
}
