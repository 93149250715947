package components.project.configuration.layout.strings

import it.neckar.commons.kotlin.js.getNotNull
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterWithStringInputs
import it.neckar.lizergy.model.configuration.quote.builder.ResolvedInverterConfiguration
import it.neckar.react.common.*
import it.neckar.react.common.form.*
import react.*
import react.dom.*

val EditInverterConfiguration: FC<EditInverterConfigurationProps> = fc("EditInverterConfiguration") { props ->
  val inverterConfigurationsToSave = props::inverterConfigurationsToSave.getNotNull()
  val stringConfiguration = props::stringConfiguration.safeGet()
  val inverter = props::inverter.safeGet()
  val inverterIndex = props::inverterIndex.safeGet()
  val inputIndex = props::inputIndex.safeGet()
  val multipleStrings = props::multipleStrings.safeGet()
  val first = props::first.safeGet()
  val last = props::last.safeGet()
  val editableStatus = props::editableStatus.safeGet()

  val optimalModuleCount = useState(stringConfiguration.optimalModuleCount)


  useMemo(optimalModuleCount.value) {
    inverterConfigurationsToSave.setter { old ->
      old.map { inverterConfiguration ->
        if (inverterConfiguration.inverter == inverter && inverterConfiguration.inverterIndex == inverterIndex) {
          inverterConfiguration.updateStringConfigurationFor(
            inputIndex = inputIndex,
            stringIndex = stringConfiguration.stringIndex
          ) {
            copy(optimalModuleCount = optimalModuleCount.value)
          }
        } else inverterConfiguration
      }
    }
  }


  nullableFloatingIntInputField(
    valueAndSetter = optimalModuleCount,
    fieldName = uniqueIdMemo("string-${stringConfiguration.stringIndex}"),
    title = "Module (Optimal) [String ${stringConfiguration.stringIndex + 1}]",
    editableStatus = editableStatus,
  ) {
    attrs {
      if (multipleStrings == true) {
        if (last == false) mergedBelow()
        if (first == false) mergedAbove()
      }
    }
  }
}

external interface EditInverterConfigurationProps : Props {
  var inverterConfigurationsToSave: StateInstance<List<ResolvedInverterConfiguration>>
  var stringConfiguration: InverterConfiguration.StringConfiguration
  var inverter: InverterWithStringInputs
  var inverterIndex: Int
  var inputIndex: Int
  var multipleStrings: Boolean?
  var first: Boolean?
  var last: Boolean?
  var editableStatus: EditableStatus
}

