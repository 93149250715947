package it.neckar.customer.company

import kotlinx.serialization.Serializable

sealed interface CompanyTree {
  val companyCode: CompanyCode
  val parentCompanyCode: CompanyCode?
  val childCompanyCodes: Set<CompanyCode>?

  val relevantParentCompanyCode: CompanyCode
    get() = parentCompanyCode ?: companyCode
}

data class ResolvedCompanyTree(
  val company: CompanyInformation,
  val parentCompany: CompanyInformation?,
  val childCompanies: Set<CompanyInformation>?,
) : CompanyTree {
  override val companyCode: CompanyCode
    get() = company.companyProfile.companyCode

  override val parentCompanyCode: CompanyCode?
    get() = parentCompany?.companyProfile?.companyCode

  override val childCompanyCodes: Set<CompanyCode>?
    get() = childCompanies?.map { it.companyProfile.companyCode }?.toSet()
}

@Serializable
data class SerializedCompanyTree(
  override val companyCode: CompanyCode,
  override val parentCompanyCode: CompanyCode?,
  override val childCompanyCodes: Set<CompanyCode>?,
) : CompanyTree
