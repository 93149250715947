package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.Uuid
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.algorithms.painter.fillAndStroke
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandlerBroker
import com.meistercharts.canvas.layout.cache.BoundsMulti2DCache
import com.meistercharts.canvas.layout.cache.CoordinatesMulti2DCache
import com.meistercharts.canvas.layout.cache.StringMulti2DCache
import com.meistercharts.canvas.paintTextBox
import com.meistercharts.canvas.saved
import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.solar.LizergyDesign
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningLayer.Mode
import com.meistercharts.color.Color
import com.meistercharts.color.RgbaColor
import com.meistercharts.events.EventConsumption
import com.meistercharts.events.EventConsumption.Consumed
import com.meistercharts.events.EventConsumption.Ignored
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.font.FontSize
import com.meistercharts.model.BorderRadius
import com.meistercharts.style.BoxStyle
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Rectangle
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.observable.ObservableObject
import it.neckar.open.provider.MultiProvider

/**
 * Layer that supports planning of  photo voltaic modules for *one* roof
 */
class PvStringsPlanningLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    stringsPlanningModel: PvStringsPlanningModel,
    roofPlanningModel: PvRoofPlanningModel,
    getModuleAtLocation: (coordinates: Coordinates) -> Module?,
    getBoundsForModule: (module: Module) -> @Window Rectangle,
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(stringsPlanningModel, roofPlanningModel, getModuleAtLocation, getBoundsForModule), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  fun stringSelected(roofStringUuid: Uuid?) {
    uiState = uiState.stringSelected(roofStringUuid)
  }

  override val mouseEventHandler: CanvasMouseEventHandler = CanvasMouseEventHandlerBroker().also { broker ->
    /**
     * All other events are handled here
     */
    broker.delegate(object : CanvasMouseEventHandler {
      override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
        if (configuration.mode == Mode.Rendering) return Ignored

        val coordinates = event.coordinates

        if (coordinates == null) {
          //Moved out of the window
          uiState = uiState.movedOutOfCanvas()
          return Ignored
        }

        //check if over module
        configuration.getModuleAtLocation(coordinates)?.let { module ->
          //mouse over module
          uiState = uiState.hoveringOver(module, configuration.getBoundsForModule(module).center())
          return Consumed
        }

        //Nothing found under the mouse
        uiState = uiState.moveOverNothing(coordinates)
        return Consumed
      }

      override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
        if (configuration.mode == Mode.Rendering) return Ignored

        val coordinates = event.coordinates

        //check if over module
        configuration.getModuleAtLocation(coordinates)?.let { module ->
          //mouse over module
          uiState = uiState.downOn(module)
          return Consumed
        }

        //This method is called *after* the dragging events have been handled
        //All downs on modules and unusable areas have already been consumed
        //remove the selection if necessary
        uiState = uiState.downOnNothing()
        return Ignored
      }

      override fun onUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
        uiState = uiState.mouseUp()
        //TODO return Consumed???
        return super.onUp(event, chartSupport)
      }
    })
  }

  /**
   * Contains the current ui state
   */
  private var uiStateProperty: ObservableObject<StringsPlanningUiState> = ObservableObject(DefaultState(configuration))
  var uiState: StringsPlanningUiState by uiStateProperty
    private set

  override fun initialize(paintingContext: LayerPaintingContext) {
    val chartSupport = paintingContext.chartSupport

    //Update the mouse cursor depending on the state
    uiStateProperty.consumeImmediately {

      //Update the mouse cursor
      chartSupport.cursor = when (it) {
        is DefaultState, is Hovering -> null
        is PlanningString -> if (it.activeModule != null) MouseCursor.Hand else MouseCursor.CrossHair
      }

      //Always mark as dirty on state change
      chartSupport.markAsDirty(DirtyReason.UiStateChanged)
    }

  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    if (configuration.mode == Mode.Planning) {
      //Paint all module highlights
      val modules = configuration.roofPlanningModel.modules()
      modules.fastForEachVisibleModuleIndexed { index, module ->

        uiState.let { uiState ->
          when (uiState) {
            is DefaultState -> {}

            is Hovering -> {
              if (uiState.activeModule == module) {
                val bounds = configuration.getBoundsForModule(module)
                gc.fillAndStroke(configuration.hoverHighlightStyle)
                gc.fillRect(bounds)
              }
            }

            is PlanningString -> {
              val bounds = configuration.getBoundsForModule(module)

              val selectedString = configuration.stringsPlanningModel.selectedString
              if (selectedString?.contains(module) == true) {
                gc.fillAndStroke(configuration.selectionHighlightStyles.valueAt(selectedString.globalStringIndex.value))
                gc.fillRect(bounds)
              }

              if (uiState.activeModule == module) {
                gc.fillAndStroke(configuration.hoverHighlightStyle)
                gc.fillRect(bounds)
              }
            }

          }
        }
      }
    }

    val moduleStringsCoordinates = paintingVariables().stringCoordinates
    val roofStringsConfiguration = configuration.stringsPlanningModel.roofStringsConfiguration

    moduleStringsCoordinates.fastForEachWithIndex { stringIndex, stringCoordinatesCaches ->
      val roofString = roofStringsConfiguration.roofStrings[stringIndex]
      val stringColorIndex = roofString.globalStringIndex
      val stringColor = configuration.stringColors.valueAt(stringColorIndex.value)
      gc.beginPath()

      stringCoordinatesCaches.fastForEachIndexed { _, moduleCoordinatesX, moduleCoordinatesY ->
        gc.lineTo(moduleCoordinatesX, moduleCoordinatesY)
      }

      uiState.planningLocation?.let { planningLocation ->
        if (configuration.stringsPlanningModel.selectedString == roofString) gc.lineTo(planningLocation)
      }

      gc.stroke(stringColor)
      gc.lineWidth = 5.0
      gc.stroke()
    }

    val moduleLabels = paintingVariables().moduleLabels
    val moduleLabelsBounds = paintingVariables().moduleLabelsBounds
    moduleStringsCoordinates.fastForEachWithIndex { stringIndex, stringCoordinates ->
      val roofString = roofStringsConfiguration.roofStrings[stringIndex]

      val stringColorIndex = roofString.globalStringIndex.value
      val labelBoxStyle = configuration.stringLabelBoxStyles.valueAt(stringColorIndex)
      val textColor = configuration.stringLabelBoxTextColors.valueAt(stringColorIndex)

      stringCoordinates.fastForEachIndexed { moduleIndexInString, moduleCoordinatesX, moduleCoordinatesY ->
        val moduleLabel = moduleLabels[stringIndex][moduleIndexInString]

        gc.saved {
          gc.font(configuration.font)
          gc.translate(moduleCoordinatesX, moduleCoordinatesY)
          val boundingBox = gc.paintTextBox(line = moduleLabel, anchorDirection = Direction.Center, boxStyle = labelBoxStyle, textColor = textColor)
          moduleLabelsBounds[stringIndex][moduleIndexInString] = boundingBox

          gc.font(configuration.fontStringEnd)
          if (moduleIndexInString == 0) {
            gc.translate(0.0, -30.0)
            val sign = if (roofString.previousModulesCount == 0) "+" else "${'A' + stringColorIndex}"
            gc.paintTextBox(line = sign, anchorDirection = Direction.Center, boxStyle = labelBoxStyle, textColor = textColor)
          } else if (moduleIndexInString == stringCoordinates.size - 1) {
            gc.translate(0.0, 30.0)
            val sign = if (roofString.followingModulesCount == 0) "–" else "${'A' + stringColorIndex}"
            gc.paintTextBox(line = sign, anchorDirection = Direction.Center, boxStyle = labelBoxStyle, textColor = textColor)
          }
        }
      }
    }

  }

  override fun paintingVariables(): PvStringsPlanningPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables: PvStringsPlanningPaintingVariables = object : PvStringsPlanningPaintingVariables {

    override val stringCoordinates: @Window CoordinatesMulti2DCache = CoordinatesMulti2DCache()

    override val moduleLabels: StringMulti2DCache = StringMulti2DCache()

    override val moduleLabelsBounds: BoundsMulti2DCache = BoundsMulti2DCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val roofStringsConfiguration = configuration.stringsPlanningModel.roofStringsConfiguration
      val stringCount = roofStringsConfiguration.count

      stringCoordinates.prepare(stringCount) //ensure array sizes
      moduleLabels.prepare(stringCount) //ensure array sizes
      moduleLabelsBounds.prepare(stringCount) //ensure array sizes
      roofStringsConfiguration.roofStrings.fastForEachIndexed { stringIndex, roofString ->
        val moduleCoordinatesCache = stringCoordinates[stringIndex]
        moduleCoordinatesCache.prepare(roofString.count) //ensure array sizes
        val moduleLabelsCache = moduleLabels[stringIndex]
        moduleLabelsCache.prepare(roofString.count) //ensure array sizes

        roofString.modules.fastForEachIndexed { moduleIndex, module ->
          val moduleBounds = configuration.getBoundsForModule(module)
          val centerX = moduleBounds.getX() + moduleBounds.getWidth() / 2.0
          val centerY = moduleBounds.getY() + moduleBounds.getHeight() / 2.0

          moduleCoordinatesCache.set(moduleIndex, centerX, centerY)

          val moduleLabel = "${roofString.inverterIndex + 1}.${roofString.mpptInputIndex + 1}.${roofString.stringIndex.value + 1}.${roofString.previousModulesCount + moduleIndex + 1}"
          moduleLabelsCache[moduleIndex] = moduleLabel
        }
      }
    }
  }


  /**
   * The mode
   */
  enum class Mode {
    /**
     * The roof is rendered (e.g. to display to the customer)
     */
    Rendering,

    /**
     * We are in planning mode - additional information is painted
     */
    Planning,
  }

  @ConfigurationDsl
  class Configuration(
    val stringsPlanningModel: PvStringsPlanningModel,
    val roofPlanningModel: PvRoofPlanningModel,
    val getModuleAtLocation: (coordinates: Coordinates) -> Module?,
    val getBoundsForModule: (module: Module) -> @Window Rectangle,
  ) {
    /**
     * The current mode
     */
    var mode: Mode = Mode.Planning //TODO remove mouse over effects when rendering!

    /**
     * The style when the mouse over an element
     */
    val hoverHighlightStyle: FillAndStrokeStyle = FillAndStrokeStyle(Color.web("#75b72633"), Color.web("#75b726"))

    /**
     * Used to style the string labels on each module
     */
    var stringLabelBoxStyles: MultiProvider<StringIndex, BoxStyle> = defaultStringLabelBoxStyles

    var stringColors: MultiProvider<StringIndex, RgbaColor> = defaultStringColors

    var stringLabelBoxTextColors: MultiProvider<StringIndex, Color> = defaultStringLabelBoxTextColors

    val selectionHighlightStyles: MultiProvider<StringIndex, FillAndStrokeStyle> = MultiProvider.invoke { index ->
      val color = stringLabelBoxStyles.valueAt(index).fill?.invoke()?.toRgba() ?: Color.black()
      FillAndStrokeStyle(color.withAlpha(0.3), color)
    }

    val font: FontDescriptorFragment = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(15.0))
    val fontStringEnd: FontDescriptorFragment = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(25.0))


    companion object {
      val defaultStringLabelBoxStyles: MultiProvider<StringIndex, BoxStyle> = MultiProvider.forListModulo(
        listOf(
          BoxStyle(fill = Color.yellow, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.red, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.cyan, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.green, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.blue, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.magenta, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.brown, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.pink, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.orange, borderColor = null, radii = BorderRadius.all2, shadow = null),
          BoxStyle(fill = Color.purple, borderColor = null, radii = BorderRadius.all2, shadow = null),
        ),
      )

      val defaultStringColors: MultiProvider<StringIndex, RgbaColor> = MultiProvider.invoke { index -> defaultStringLabelBoxStyles.valueAt(index).fill?.invoke()?.toRgba() ?: Color.black() }

      val defaultStringLabelBoxTextColors: MultiProvider<StringIndex, Color> = MultiProvider.forListModulo(
        listOf(
          Color.black(),
          Color.black(),
          Color.black(),
          Color.white(),
          Color.white(),
          Color.black(),
          Color.white(),
          Color.black(),
          Color.black(),
          Color.white(),
        )
      )
    }
  }
}
