package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.roofPlanning.ModulePlacement
import it.neckar.open.observable.ObservableObject

/**
 * Contains the [ModulePlacement]s
 */
class RoofString(val uuid: Uuid, val roofUuid: Uuid, initialModules: List<Module> = emptyList()) {
  /**
   * The [RoofString] property
   */
  val modulesProperty: ObservableObject<List<Module>> = ObservableObject(initialModules)

  /**
   * Getter for the current [Module]
   */
  val modules: List<Module> by modulesProperty

  /**
   * Returns the [Module] count
   */
  val count: Int
    get() = modules.size

  fun clear() {
    modulesProperty.value = emptyList()
  }

  fun add(module: Module) {
    modulesProperty.getAndSet {
      it.plus(module)
    }
  }

  fun addAll(module: Iterable<Module>) {
    modulesProperty.getAndSet {
      it.plus(module)
    }
  }

  fun contains(module: Module): Boolean {
    return modules.contains(module)
  }

  /**
   * Sets the unusableAreas
   */
  fun set(module: Iterable<Module>) {
    modulesProperty.value = module.toMutableList()
  }

  operator fun get(index: Int): Module {
    return modules[index]
  }

  /**
   * Removes the given [Module]
   */
  fun removeAll(toRemove: Set<Module>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (Module) -> Boolean) {
    modulesProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(module: Module) {
    modulesProperty.getAndSet {
      it.minus(module)
    }
  }
}
