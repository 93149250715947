package services.http

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import it.neckar.common.auth.JwtRefreshTokenResponse
import it.neckar.common.redux.dispatch
import it.neckar.ktor.client.JwtTokenClientHandler
import it.neckar.ktor.client.createHttpClient
import it.neckar.ktor.client.installClientIdHeader
import it.neckar.ktor.client.installClientRoleHeader
import it.neckar.ktor.client.installProtocolVersionHeader
import it.neckar.ktor.client.plugin.stats.CompletedRequestDescriptor
import it.neckar.ktor.client.plugin.stats.HttpClientLoadingPlugin
import it.neckar.ktor.client.plugin.stats.LoadingCallback
import it.neckar.ktor.client.plugin.stats.PendingRequestDescriptor
import it.neckar.ktor.client.toBearerTokens
import it.neckar.lizergy.PlannerClientDeploymentZone
import it.neckar.lizergy.model.plannerJsonConfiguration
import it.neckar.open.exceptions.ErrorCode
import it.neckar.rest.ClientRole
import it.neckar.rest.jwt.JwtToken
import it.neckar.rest.version.RestProtocolVersionInfo
import kotlinx.serialization.json.Json
import store.actions.AddCompletedRequest
import store.actions.AddPendingRequest
import store.actions.CheckForCompletion


/**
 * Contains the services that are used in the UI by the client
 */
object PlannerUiServices {
  private var _urlSupport: PlannerUrlSupport? = null

  val urlSupport: PlannerUrlSupport
    get() = _urlSupport ?: throw IllegalStateException("Not initialized. Call initialize()")

  /**
   * Handles the jws token client stuff
   */
  private var _jwtTokenClientHandler: JwtTokenClientHandler? = null

  val jwtTokenClientHandler: JwtTokenClientHandler
    get() = _jwtTokenClientHandler ?: throw IllegalStateException("Not initialized. Call initialize()")


  fun initialize(deploymentZone: PlannerClientDeploymentZone, jwtTokenClientHandler: JwtTokenClientHandler) {
    if (this._urlSupport != null) {
      //The url support is used by reference. It is not possible to reinitialize at the moment
      throw IllegalStateException("Already initialized")
    }

    this._urlSupport = PlannerUrlSupport.forClient(deploymentZone)
    this._jwtTokenClientHandler = jwtTokenClientHandler
  }


  /**
   * HTTP client without authorization.
   * Is used for login
   */
  val httpClientNoAuth: HttpClient = createHttpClient("httpClientNoAuth") {
    installProtocolVersionHeader()
    installClientIdHeader()
    installClientRoleHeader(ClientRole("planner-client"))

    install(ContentNegotiation) {
      json(Json {
        plannerJsonConfiguration()
      })
    }

  }

  /**
   * HTTP client - with authorization
   */
  val httpClientWithAuth: HttpClient = createHttpClient("httpClientWithAuth") {
    installProtocolVersionHeader()
    installClientIdHeader()
    installClientRoleHeader(ClientRole("planner-client"))

    install(HttpClientLoadingPlugin) {
      loadingCallback = object : LoadingCallback {
        override fun requestSent(request: PendingRequestDescriptor) {
          //Clear first, if necessary //TODO: good idea?
          dispatch(CheckForCompletion())
          dispatch(AddPendingRequest(request))
        }

        override fun requestCompleted(request: CompletedRequestDescriptor) {
          dispatch(AddCompletedRequest(request))
          //dispatch(CheckForCompletion())
        }
      }
    }

    install(ContentNegotiation) {
      json(Json {
        plannerJsonConfiguration()
      })
    }

    install(Auth) {
      bearer {
        sendWithoutRequest { true }

        loadTokens {
          jwtTokenClientHandler.loadTokens().toBearerTokens()
        }

        refreshTokens {
          val refreshToken = this.oldTokens?.refreshToken
            ?: //Load the token from the store - refresh has failed once, new login has happened
            return@refreshTokens jwtTokenClientHandler.loadTokens().toBearerTokens()

          return@refreshTokens when (val refreshResult = authenticationService.refreshAccessToken(JwtToken(refreshToken))) {
            is JwtRefreshTokenResponse.Failure -> {
              jwtTokenClientHandler.logout()
              null
            }

            is JwtRefreshTokenResponse.Success -> {
              jwtTokenClientHandler.storeNewTokens(refreshResult.data)
              BearerTokens(refreshResult.data.accessToken.token, refreshResult.data.refreshToken.token)
            }
          }
        }
      }
    }
  }

  /**
   * Can be used to log in and refresh the tokens
   */
  val authenticationService: AuthenticationClientService by lazy { AuthenticationClientService(httpClientWithAuth, httpClientNoAuth, urlSupport) }

  /**
   * Storage service
   */
  //val storageService: StorageClientService by lazy { StorageClientService(httpClientWithAuth, urlSupport) }

  val projectQueryService: ProjectQueryClientService by lazy { ProjectQueryClientService(httpClientWithAuth, urlSupport) }

  val commentsService: CommentsClientService by lazy { CommentsClientService(httpClientWithAuth, urlSupport) }

  val processStatesService: ProcessStatesClientService by lazy { ProcessStatesClientService(httpClientWithAuth, urlSupport) }

  /**
   * PDF Report service
   */
  val pdfReportService: PdfReportClientService by lazy { PdfReportClientService(httpClientWithAuth, urlSupport) }

  /**
   * GeoInformation service
   */
  val geoInformationService: GeoInformationClientService by lazy { GeoInformationClientService(httpClientWithAuth, urlSupport) }

  /**
   * Loads the essentials
   */
  val essentialsClientService: EssentialsClientService by lazy { EssentialsClientService(authenticationService, projectQueryService) }
}
