package com.meistercharts.charts.lizergy.stringsPlanning

import it.neckar.open.observable.ObservableObject

/**
 * Contains the [RoofString]s
 */
class RoofStringsConfiguration(initialRoofStrings: List<RoofString> = emptyList()) {
  /**
   * The [RoofString] property
   */
  val roofStringsProperty: ObservableObject<List<RoofString>> = ObservableObject(initialRoofStrings)

  /**
   * Getter for the current [RoofString]
   */
  val roofStrings: List<RoofString> by roofStringsProperty

  /**
   * Returns the [RoofString] count
   */
  val count: Int
    get() = roofStrings.size

  fun clear() {
    roofStringsProperty.value = emptyList()
  }

  fun add(inverterConfiguration: RoofString) {
    roofStringsProperty.getAndSet {
      it.plus(inverterConfiguration)
    }
  }

  fun addAll(newInverterConfiguration: Iterable<RoofString>) {
    roofStringsProperty.getAndSet {
      it.plus(newInverterConfiguration)
    }
  }

  /**
   * Sets the [RoofString]s
   */
  fun set(inverterConfigurations: Iterable<RoofString>) {
    roofStringsProperty.value = inverterConfigurations.toMutableList()
  }

  operator fun get(index: Int): RoofString {
    return roofStrings[index]
  }

  /**
   * Removes the given [RoofString]
   */
  fun removeAll(toRemove: Set<RoofString>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (RoofString) -> Boolean) {
    roofStringsProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(inverterConfiguration: RoofString) {
    roofStringsProperty.getAndSet {
      it.minus(inverterConfiguration)
    }
  }
}
