package it.neckar.lizergy.model.configuration.quote.builder

import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.MpptInputConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.StringConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import kotlinx.serialization.Serializable
import kotlin.collections.plus

@Serializable
data class ResolvedInverterConfiguration(
  val inverter: InverterWithStringInputs,
  override val inverterIndex: Int,
  override val mpptInputConfigurations: List<MpptInputConfiguration>,
) : InverterConfiguration {

  override val inverterId: InverterId
    get() = inverter.id

  fun updateStringConfigurationFor(inputIndex: Int, stringIndex: Int, update: StringConfiguration.() -> StringConfiguration): ResolvedInverterConfiguration {
    val updatedMpptInputConfigurations = mpptInputConfigurations.map { mpptInputConfiguration ->
      if (mpptInputConfiguration.inputIndex == inputIndex) {
        val updatedStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
          if (stringConfiguration.stringIndex == stringIndex) stringConfiguration.update() else stringConfiguration
        }
        mpptInputConfiguration.copy(stringConfigurations = updatedStringConfigurations)
      } else {
        mpptInputConfiguration
      }
    }
    return copy(mpptInputConfigurations = updatedMpptInputConfigurations)
  }

  fun forTheseLayouts(moduleLayouts: List<ModuleLayoutId>): ResolvedInverterConfiguration {
    return copy(mpptInputConfigurations = mpptInputConfigurations.map { it.forTheseLayouts(moduleLayouts) })
  }

  fun format(): String {
    return buildString {
      append("Wechselrichter ${inverter.description} (${inverterIndex + 1})")
      mpptInputConfigurations.forEach { mpptInputConfiguration ->
        append("\n")
        append(mpptInputConfiguration.format())
      }
    }
  }

  companion object {
    operator fun invoke(inverter: InverterWithStringInputs, inverterIndex: Int): ResolvedInverterConfiguration {
      val mpptInputConfigurations = inverter.mppInputs.map { mppInput ->
        MpptInputConfiguration(mppInput.inputIndex, mppInput.strings.map { stringMppInput ->
          StringConfiguration(stringIndex = stringMppInput.stringIndex, optimalModuleCount = null, modulesStrings = null)
        })
      }
      return ResolvedInverterConfiguration(inverter = inverter, inverterIndex = inverterIndex, mpptInputConfigurations = mpptInputConfigurations)
    }
  }
}


fun List<ResolvedInverterConfiguration>.withAdded(newModulesString: ModulesString, stringConfiguration1: StringConfiguration, mpptInputConfiguration1: MpptInputConfiguration, inverterConfiguration1: ResolvedInverterConfiguration): List<ResolvedInverterConfiguration> {
  return this.map { inverterConfiguration ->
    if (inverterConfiguration != inverterConfiguration1) inverterConfiguration else {
      val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
        if (mpptInputConfiguration != mpptInputConfiguration1) mpptInputConfiguration else {
          val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
            if (stringConfiguration != stringConfiguration1) stringConfiguration else {
              val newModulesStrings = stringConfiguration.modulesStrings?.let { it + newModulesString } ?: listOf(newModulesString)
              stringConfiguration.copy(modulesStrings = newModulesStrings)
            }
          }
          mpptInputConfiguration.copy(stringConfigurations = newStringConfigurations)
        }
      }
      inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
    }
  }
}

fun List<ResolvedInverterConfiguration>.withUpdatedStringConfiguration(newStringConfiguration: StringConfiguration, oldStringConfiguration: StringConfiguration): List<ResolvedInverterConfiguration> {
  return this.map { inverterConfiguration ->
    val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
      val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
        if (stringConfiguration == oldStringConfiguration) newStringConfiguration else stringConfiguration
      }
      mpptInputConfiguration.copy(stringConfigurations = newStringConfigurations)
    }
    inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
  }
}

fun List<ResolvedInverterConfiguration>.withCleared(modulesStringToClear: ModulesString): List<ResolvedInverterConfiguration> {
  return this.map { inverterConfiguration ->
    val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
      val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
        val newRoofStrings = stringConfiguration.modulesStrings?.map { roofString ->
          if (roofString == modulesStringToClear) roofString.copy(stringOfModules = emptyList()) else roofString
        }
        stringConfiguration.copy(modulesStrings = newRoofStrings)
      }
      mpptInputConfiguration.copy(stringConfigurations = newStringConfigurations)
    }
    inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
  }
}

fun List<ResolvedInverterConfiguration>.withReversed(modulesStringToReverse: ModulesString): List<ResolvedInverterConfiguration> {
  return this.map { inverterConfiguration ->
    val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
      val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
        val newModulesStrings = stringConfiguration.modulesStrings?.map { modulesString ->
          if (modulesString == modulesStringToReverse) modulesString.copy(stringOfModules = modulesString.stringOfModules.reversed()) else modulesString
        }
        stringConfiguration.copy(modulesStrings = newModulesStrings)
      }
      mpptInputConfiguration.copy(stringConfigurations = newStringConfigurations)
    }
    inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
  }
}

fun List<ResolvedInverterConfiguration>.withDeleted(modulesStringToDelete: ModulesString): List<ResolvedInverterConfiguration> {
  return this.map { inverterConfiguration ->
    val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
      val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
        val newRoofStrings = stringConfiguration.modulesStrings?.filter { roofString ->
          roofString != modulesStringToDelete
        }?.filter { it.numberOfModules > 0 }?.ifEmpty { null }
        stringConfiguration.copy(modulesStrings = newRoofStrings)
      }
      mpptInputConfiguration.copy(stringConfigurations = newStringConfigurations)
    }
    inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
  }
}
