package router

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.lizergy.model.company.PlannerCompanyInformation
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.ConfigurationItem.ConfigurationItemId
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.project.Blueprint
import it.neckar.lizergy.model.project.ProjectConfiguration
import it.neckar.lizergy.model.project.ProjectConfiguration.PhotovoltaicsProjectId
import it.neckar.react.common.router.*
import it.neckar.user.UserLoginName
import it.neckar.uuid.encodeForUrl

object RouterUrls {
  val home: NavigateUrl = NavigateUrl("/")

  object OverviewTableRouterUrls {
    val projects: NavigateUrl = NavigateUrl("/${PathConstants.OverviewTablePathConstants.projects}")
    val blueprints: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.blueprints}")
    val quotes: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.quotes}")
    val presentations: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.presentations}")
    val orderSpecialMaterial: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.orderSpecialMaterials}")
    val gridAssessment: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.gridInquiries}")
    val assemblyPortfolio: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.assemblyPortfolios}")
    val scheduleAssembly: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.scheduleAssemblies}")
    val advanceInvoice: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.advanceInvoices}")
    val prepareMaterial: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.prepareAssemblies}")
    val assemblyRoof: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.assemblyRoofs}")
    val assemblyBasement: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.assemblyBasements}")
    val switchMeterBox: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.switchMeterBoxes}")
    val startupOperations: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.startups}")
    val finishingUp: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.finishingUps}")
    val finalAccount: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.finalAccounts}")
    val documentation: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.documentations}")
    val verification: NavigateUrl = NavigateUrl("$projects/${PathConstants.OverviewTablePathConstants.verifications}")
  }

  val newProject: NavigateUrl = NavigateUrl("${OverviewTableRouterUrls.projects}/${PathConstants.new}")

  val priceList: NavigateUrl = NavigateUrl("/${PathConstants.priceList}")
  val featureFlags: NavigateUrl = NavigateUrl("/${PathConstants.featureFlags}")
  val createSampleProjects: NavigateUrl = NavigateUrl("/${PathConstants.createSampleProjects}")

  val admin: AdminUrlBuilder = AdminUrlBuilder(NavigateUrl("/${PathConstants.admin}"))

  val diagnosis: NavigateUrl = NavigateUrl("/${PathConstants.diagnosis}")

  fun project(projectId: PhotovoltaicsProjectId): ProjectUrlBuilder {
    return ProjectUrlBuilder(NavigateUrl("${OverviewTableRouterUrls.projects}/${projectId.encodeForUrl()}"))
  }

  fun project(project: ProjectConfiguration): ProjectUrlBuilder {
    return project(project.projectId)
  }

  fun blueprint(projectId: PhotovoltaicsProjectId): BlueprintUrlBuilder {
    return project(projectId).blueprint
  }

  fun blueprint(project: ProjectConfiguration): BlueprintUrlBuilder {
    return blueprint(project.projectId)
  }

  fun blueprint(blueprint: Blueprint): BlueprintUrlBuilder {
    return blueprint(blueprint.projectId)
  }

  fun configuration(projectId: PhotovoltaicsProjectId, configurationId: PhotovoltaicsConfigurationId): ConfigurationUrlBuilder {
    return project(projectId).configuration(configurationId)
  }

  fun configuration(project: ProjectConfiguration, configuration: PhotovoltaicsConfiguration): ConfigurationUrlBuilder {
    return configuration(project.projectId, configuration.configurationId)
  }
}


class ProjectUrlBuilder(val index: NavigateUrl) {
  val blueprint: BlueprintUrlBuilder = BlueprintUrlBuilder(NavigateUrl("$index/${PathConstants.blueprint}"))

  val configurations: NavigateUrl = NavigateUrl("$index/${PathConstants.configurations}")
  fun configurationUrl(configurationId: PhotovoltaicsConfigurationId?): NavigateUrl = configurationId?.let { NavigateUrl("$configurations/${it.encodeForUrl()}") } ?: configurations
  fun configurationUrl(configuration: PhotovoltaicsConfiguration?): NavigateUrl = configurationUrl(configuration?.configurationId)
  fun configuration(configurationId: PhotovoltaicsConfigurationId): ConfigurationUrlBuilder = ConfigurationUrlBuilder(configurationUrl(configurationId))
  fun configuration(configuration: PhotovoltaicsConfiguration): ConfigurationUrlBuilder = configuration(configuration.configurationId)

  val orderSpecialMaterial: NavigateUrl = NavigateUrl("$index/${PathConstants.orderSpecialMaterial}")
  val gridAssessment: NavigateUrl = NavigateUrl("$index/${PathConstants.gridAssessment}")
  val assemblyPortfolio: NavigateUrl = NavigateUrl("$index/${PathConstants.assemblyPortfolio}")
  val scheduleAssembly: NavigateUrl = NavigateUrl("$index/${PathConstants.scheduleAssembly}")
  val advanceInvoice: NavigateUrl = NavigateUrl("$index/${PathConstants.advanceInvoice}")
  val prepareAssembly: NavigateUrl = NavigateUrl("$index/${PathConstants.prepareAssembly}")
  val assemblyRoof: NavigateUrl = NavigateUrl("$index/${PathConstants.assemblyRoof}")
  val assemblyBasement: NavigateUrl = NavigateUrl("$index/${PathConstants.assemblyBasement}")
  val switchMeterBox: NavigateUrl = NavigateUrl("$index/${PathConstants.switchMeterBox}")
  val startupOperations: NavigateUrl = NavigateUrl("$index/${PathConstants.startupOperations}")
  val finishingUp: NavigateUrl = NavigateUrl("$index/${PathConstants.finishingUp}")
  val finalAccount: NavigateUrl = NavigateUrl("$index/${PathConstants.finalAccount}")
  val documentation: NavigateUrl = NavigateUrl("$index/${PathConstants.documentation}")
  val verification: NavigateUrl = NavigateUrl("$index/${PathConstants.verification}")
}

class BlueprintUrlBuilder(val index: NavigateUrl) {
  val customer: NavigateUrl = NavigateUrl("$index/${PathConstants.customer}")
  val maintainer: NavigateUrl = NavigateUrl("$index/${PathConstants.maintainer}")
  val location: NavigateUrl = NavigateUrl("$index/${PathConstants.location}")
  val wallBox: NavigateUrl = NavigateUrl("$index/${PathConstants.wallbox}")

  val moduleLayouts: NavigateUrl = NavigateUrl("$index/${PathConstants.moduleLayouts}")
  fun moduleLayout(moduleLayoutId: ModuleLayoutId): NavigateUrl = NavigateUrl("$moduleLayouts/${moduleLayoutId.encodeForUrl()}")
  fun moduleLayout(moduleLayout: ModuleLayout): NavigateUrl = moduleLayout(moduleLayout.id)

  val profitability: NavigateUrl = NavigateUrl("$index/${PathConstants.profitability}")
  val facility: NavigateUrl = NavigateUrl("$index/${PathConstants.facility}")
  val electricityWorkEffort: NavigateUrl = NavigateUrl("$index/${PathConstants.electricityWorkEffort}")
  val additional: NavigateUrl = NavigateUrl("$index/${PathConstants.additional}")

  val additionalPositions: NavigateUrl = NavigateUrl("$additional/${PathConstants.additionalPositions}")
  fun additionalPosition(configurationItemId: ConfigurationItemId): NavigateUrl = NavigateUrl("$additionalPositions/${configurationItemId.encodeForUrl()}")

  val bestandsAnlagenPV: NavigateUrl = NavigateUrl("$additional/${PathConstants.bestandsAnlagenPV}")
  fun existingPVFacility(positionUuid: Uuid): NavigateUrl = NavigateUrl("$bestandsAnlagenPV/${positionUuid.encodeForUrl()}")

  val bestandsAnlagenBHKW: NavigateUrl = NavigateUrl("$additional/${PathConstants.bestandsAnlagenBHKW}")
  fun existingBHKWFacility(positionUuid: Uuid): NavigateUrl = NavigateUrl("$bestandsAnlagenBHKW/${positionUuid.encodeForUrl()}")

  val finish: NavigateUrl = NavigateUrl("$index/${PathConstants.finish}")
}

class ConfigurationUrlBuilder(val index: NavigateUrl) {
  val description: NavigateUrl = NavigateUrl("$index/${PathConstants.description}")
  val location: NavigateUrl = NavigateUrl("$index/${PathConstants.location}")
  val electricity: NavigateUrl = NavigateUrl("$index/${PathConstants.electricity}")
  val moduleLayouts: NavigateUrl = NavigateUrl("$index/${PathConstants.moduleLayouts}")
  val moduleStrings: NavigateUrl = NavigateUrl("$index/${PathConstants.moduleStrings}")
  val facility: NavigateUrl = NavigateUrl("$index/${PathConstants.facility}")
  val additional: NavigateUrl = NavigateUrl("$index/${PathConstants.additional}")
  val additionalPositions: NavigateUrl = NavigateUrl("$additional/${PathConstants.additionalPositions}")
  val bestandsAnlagenPV: NavigateUrl = NavigateUrl("$additional/${PathConstants.bestandsAnlagenPV}")
  val bestandsAnlagenBHKW: NavigateUrl = NavigateUrl("$additional/${PathConstants.bestandsAnlagenBHKW}")
  val profit: NavigateUrl = NavigateUrl("$index/${PathConstants.profit}")
  val economics: NavigateUrl = NavigateUrl("$index/${PathConstants.economics}")
  val earnings: NavigateUrl = NavigateUrl("$index/${PathConstants.earnings}")

  fun moduleLayout(moduleLayoutId: ModuleLayoutId): NavigateUrl {
    return NavigateUrl("$moduleLayouts/${moduleLayoutId.encodeForUrl()}")
  }

  fun moduleLayout(moduleLayout: ModuleLayout): NavigateUrl {
    return moduleLayout(moduleLayout.id)
  }

  fun additionalPosition(positionUuid: Uuid): NavigateUrl {
    return NavigateUrl("$additionalPositions/${positionUuid.encodeForUrl()}")
  }

  fun existingPVFacility(positionUuid: Uuid): NavigateUrl {
    return NavigateUrl("$bestandsAnlagenPV/${positionUuid.encodeForUrl()}")
  }

  fun existingBHKWFacility(positionUuid: Uuid): NavigateUrl {
    return NavigateUrl("$bestandsAnlagenBHKW/${positionUuid.encodeForUrl()}")
  }
}

class AdminUrlBuilder(val index: NavigateUrl) {
  val accounting: NavigateUrl = NavigateUrl("${index}/${PathConstants.accounting}")

  fun companies(): AdminCompaniesUrlBuilder {
    return AdminCompaniesUrlBuilder(NavigateUrl("${index}/${PathConstants.companies}"))
  }

  fun users(): AdminUsersUrlBuilder {
    return AdminUsersUrlBuilder(NavigateUrl("${index}/${PathConstants.users}"))
  }
}

class AdminCompaniesUrlBuilder(val index: NavigateUrl) {
  val newCompany: NavigateUrl = NavigateUrl("${index}/${PathConstants.newCompany}")

  fun company(companyCode: CompanyCode): AdminCompanyUrlBuilder {
    return AdminCompanyUrlBuilder(NavigateUrl("${index}/$companyCode"))
  }

  fun company(company: PlannerCompanyInformation): AdminCompanyUrlBuilder {
    return company(company.companyCode)
  }
}

class AdminCompanyUrlBuilder(val index: NavigateUrl) {
  val newUser: NavigateUrl = NavigateUrl("${index}/${PathConstants.newUser}")
}

class AdminUsersUrlBuilder(val index: NavigateUrl) {
  fun user(loginName: UserLoginName): NavigateUrl {
    return NavigateUrl("${index}/$loginName")
  }

  fun user(userInformation: UserInformation): NavigateUrl {
    return user(userInformation.loginName)
  }
}
