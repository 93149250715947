package components.project.configuration

import components.project.OLDdetailedProcessStateLabel
import components.project.configuration.profitability.profitability
import components.project.freezableEditButton
import components.project.isProjectEditable
import components.project.processStatePillWithDateAndEditor
import components.project.validationIcon
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.lizergy.model.configuration.energy.PowerRating
import it.neckar.lizergy.model.configuration.quote.QuoteConfiguration
import it.neckar.lizergy.model.project.ResolvedProject
import it.neckar.lizergy.model.project.process.state.current
import it.neckar.lizergy.model.project.process.state.toNewProcessState
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.percentageFormat
import it.neckar.react.common.*
import it.neckar.react.common.router.*
import react.*
import react.dom.*
import react.router.dom.*
import router.RouterUrls
import router.toConfiguration
import services.UiActions
import store.hooks.useLoadProcessStatesForComponent
import store.hooks.useRequireLoggedInUser
import store.hooks.useSelectUserResolver

/**
 * Shows the preview for *one* quote
 */
val ConfigurationPreview: FC<ConfigurationPreviewProps> = fc("ConfigurationPreview") { props ->
  val navigate = useNavigateUrl()
  val loggedInUser = useRequireLoggedInUser()
  val userResolver = useSelectUserResolver()

  val project = props::project.safeGet()
  val quoteConfiguration = props::quoteConfiguration.safeGet()

  val processStatesForConfiguration = useLoadProcessStatesForComponent(quoteConfiguration.uuid)
  val currentProcessStateForConfiguration = processStatesForConfiguration?.current()?.processState ?: quoteConfiguration.processState?.currentValue?.toNewProcessState()
  val editableStatus = project.isProjectEditable().and(currentProcessStateForConfiguration.isConfigurationEditable())

  val inverterSelection = quoteConfiguration.facilityConfiguration.inverterSelection
  val batteryConfiguration = quoteConfiguration.facilityConfiguration.batteryConfiguration
  val totalPowerRating = quoteConfiguration.totalPowerRating


  div("col") {
    div("card h-100") {
      key = quoteConfiguration.uuid.toString()

      div("card-body") {
        p("mt-0") {
          busyIfNull(processStatesForConfiguration) { loadedProcessStates ->
            val processStateEntry = loadedProcessStates.current()
            if (processStateEntry != null) {
              processStatePillWithDateAndEditor(processStateEntry)
            } else {
              this@p.OLDdetailedProcessStateLabel(quoteConfiguration.processState?.currentEdit, quoteConfiguration.currentQuoteSnapshot?.quote?.quoteNumber, userResolver)
            }
          }
          validationIcon(quoteConfiguration.validationProblems) {
            it.addClass("ps-2")
          }
        }

        busyIfNull(processStatesForConfiguration) { loadedProcessStates ->
          h2("card-title mt-0 mb-3") {
            Link {
              attrs {
                toUrl = RouterUrls.project(project).configuration(quoteConfiguration).index
              }

              +quoteConfiguration.configurationName
            }

            div(classes = "btn-group") {

              freezableEditButton(editableStatus) {
                navigate.toConfiguration(project.projectId, quoteConfiguration)
              }

              actionButtonWithConfirmationModal(
                icon = FontAwesomeIcons.copy,
                modalTitle = "Soll die Konfiguration dupliziert werden?",
              ) {
                UiActions.duplicateQuoteConfiguration(project, quoteConfiguration, loggedInUser).also { newQuoteConfiguration ->
                  navigate.toConfiguration(project.projectId, newQuoteConfiguration)
                }
              }

              actionButtonWithConfirmationModal(
                icon = FontAwesomeIcons.trash,
                modalTitle = "Soll das Angebot gelöscht werden?",
                confirmationButtonClasses = "btn btn-danger",
                modalButtonPosition = ModalButtonPosition.OkCancel,
              ) {
                UiActions.removeQuoteConfiguration(project, quoteConfiguration)
              }

            }

          }
        }

        h5("mt-2") {
          +"Stromverbrauch / -Preise"
        }

        p {
          +"Stromverbrauch: ${quoteConfiguration.powerUsageScenario.yearlyPowerConsumptionValue.format()} (${percentageFormat.format(quoteConfiguration.powerConsumptionDistribution.selfProducedPercentage)} durch PV gedeckt)"
        }
        p {
          +"Bezugspreis: ${quoteConfiguration.pricesTrendScenario.consumptionPrice.format()}"
        }

        hr { }

        h5 {
          +"Modul-Flächen"
        }

        quoteConfiguration.moduleLayouts.validElements.fastForEach { moduleLayout ->

          div {
            p {
              key = moduleLayout.id.format()
              +"${moduleLayout.layoutName} (${moduleLayout.modulesCount} ${moduleLayout.moduleType.description})"
            }

            invalidIf {
              batteryConfiguration?.lifeCycleState?.isEndOfLife() == true
            }
            if (batteryConfiguration?.lifeCycleState?.isEndOfLife() == true) {
              p("text-danger") {
                +"Das Modul dieses Modulplans ist nicht mehr verfügbar!"
              }
            }
          }
        }

        p("mt-3") {
          +"Anlagenleistung: ${totalPowerRating.formatKiloWattPeak()}"

          invalidIf {
            totalPowerRating > PowerRating.EEGLimit
          }
        }

        p {
          +"Jährliche Produktion: ${quoteConfiguration.totalAnnualProduction.format()}"
        }

        hr { }

        div {
          h5 {
            +"Batteriespeicher"
          }

          batteryConfiguration?.battery?.let { battery ->
            p {
              +"${battery.description} (${battery.storageCapacity.format()})"
            }
          }
          batteryConfiguration?.extension?.let { extension ->
            p {
              +"Erweiterung: ${extension.description} (${extension.storageCapacity.format()})"
            }
          }

          p("mt-3") {
            +"Gesamtspeicherkapazität: ${batteryConfiguration?.totalStorage?.format() ?: "-"}"

            invalidIf {
              batteryConfiguration == null
            }
          }

          invalidIf {
            batteryConfiguration?.lifeCycleState?.isEndOfLife() == true
          }
          if (batteryConfiguration?.lifeCycleState?.isEndOfLife() == true) {
            p("text-danger") {
              +"Die ausgewählte Batterie ist nicht mehr verfügbar!"
            }
          }
        }

        hr { }

        h5("mt-2") {
          +"Konfiguration"
        }

        p {
          +"Wechselrichter: ${inverterSelection.format()}"

          invalidIf {
            inverterSelection.isEmpty()
          }
        }

        p {
          +"E-Auto: ${quoteConfiguration.wallboxSelection.format()}"
        }

        hr { }

        profitability(quoteConfiguration)

      }

      div("card-footer") {
        div("row") {
          div("btn-group col my-2") {
            quoteOfferPreviewButton(project, quoteConfiguration)
          }
        }
      }
    }
  }

}

external interface ConfigurationPreviewProps : Props {
  var project: ResolvedProject
  var quoteConfiguration: QuoteConfiguration
}
