package it.neckar.problem

import it.neckar.open.exceptions.ApplicationException
import it.neckar.open.exceptions.ErrorCode
import it.neckar.open.kotlin.serializers.AnySerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents an (expected) problem.
 *
 * All strings are in english.
 * use the [errorCode] for translation on the client side.
 */
@Serializable
@SerialName("ExpectedError")
data class ExpectedApplicationError(
  /**
   * The error code for this problem
   */
  val errorCode: ErrorCode,

  /**
   * A short, human-readable summary of the problem type.
   * *NOT translated*.
   *
   * Translation should be done on the client side - using the [errorCode] as key.
   */
  override val fallbackMessage: String?,

  override val instanceUri: String?,

  /**
   * Optional, additional attributes
   */
  val attributes: Map<String, @Serializable(with = AnySerializer::class) Any?>?,

  override val stackTrace: String?,
) : ApplicationError

/**
 * Converts an application problem to an expected problem
 */
fun ApplicationException.toExpectedProblem(): ExpectedApplicationError {
  return ExpectedApplicationError(
    errorCode = errorCode,
    fallbackMessage = fallbackMessage,
    instanceUri = null,
    attributes = this.parameters,
    stackTrace = this.stackTraceToString()
  )
}

@Deprecated("Convert to expected problem instead", level = DeprecationLevel.ERROR)
fun ApplicationException.toUnexpectedProblem(): UnexpectedApplicationError {
  throw UnsupportedOperationException("Must not convert to UnexpectedProblem.")
}
