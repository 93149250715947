package it.neckar.lizergy.model.configuration.moduleLayout.planning

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.RoofRelative
import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType
import it.neckar.geometry.Size
import it.neckar.open.unit.si.mm
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

@Serializable
data class UnusableAreaInformation(
  val id: UnusableAreaId = UnusableAreaId.random(),

  /**
   * The location (bottom left) of the unusable area
   */
  val location: @RoofRelative Coordinates,
  /**
   * The size of the unusable area
   */
  val size: @mm Size,

  /**
   * The description of the unusable area
   */
  val description: String,

  /**
   * The type of the right triangle - or null if this area has the shape of a rectangle
   */
  val rightTriangleType: RightTriangleType?,
) : HasUuid {

  override val uuid: Uuid
    get() = id.uuid

  @Serializable
  data class UnusableAreaId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): UnusableAreaId {
        return UnusableAreaId(randomUuid4())
      }
    }
  }
}


