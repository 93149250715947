package it.neckar.rest.version

import it.neckar.open.exceptions.ErrorCode
import it.neckar.open.version.VersionInformation

/**
 * Holds information about the rest protocol version
 */
object RestProtocolVersionInfo {
  /**
   * Header for the version number of the client
   */
  const val VersionHeaderKey: String = "X-Protocol-Version"

  /**
   * The version (the git commit)
   */
  const val Version: String = VersionInformation.gitCommit

  object ErrorCodes {
    val MissingClientVersion: ErrorCode = ErrorCode.create("CVE", 100)
    val InvalidClientVersion: ErrorCode = ErrorCode.create("CVE", 101)
  }
}
