package it.neckar.rest.version

import it.neckar.open.exceptions.ApplicationException
import it.neckar.open.exceptions.ErrorCode
import it.neckar.open.version.Version
import it.neckar.open.version.VersionRange

/**
 * Is thrown if there is a problem with the protocol version
 */
class RestProtocolVersionProblemException(
  val details: Cause,
  cause: Throwable? = null,
  override val fallbackMessage: String,
  override val parameters: Map<String, Any>? = null,
) : ApplicationException(cause) {

  override val errorCode: ErrorCode
    get() = details.errorCode

  /**
   * The cause for the client version problem
   */
  enum class Cause(
    val errorCode: ErrorCode,
  ) {
    MissingClientVersion(ErrorCode.create("PVE", 100)),
    InvalidClientVersion(ErrorCode.create("PVE", 101)),
    ;
  }

  companion object {
    fun missingClientVersion(
      clientHeaderKey: String,
      cause: Throwable? = null,
    ): RestProtocolVersionProblemException {
      return RestProtocolVersionProblemException(
        details = Cause.MissingClientVersion,
        cause = cause,
        fallbackMessage = "Client not supported. Missing client version header (${clientHeaderKey})",
        parameters = mapOf(
          "clientHeaderKey" to clientHeaderKey
        ),
      )
    }

    fun invalidClientVersion(
      expectedVersionRange: VersionRange,
      clientVersion: Version,
      cause: Throwable? = null,
    ): RestProtocolVersionProblemException {
      return RestProtocolVersionProblemException(
        Cause.InvalidClientVersion,
        cause = cause,
        fallbackMessage = "Client not supported. Expected client version ${expectedVersionRange.format()} but got $clientVersion",
        parameters = mapOf(
          "expectedVersion" to expectedVersionRange.format(),
          "clientVersion" to clientVersion,
        )
      )
    }

    fun invalidClientVersion(
      expectedVersion: Version,
      clientVersion: Version,
      cause: Throwable? = null,
    ): RestProtocolVersionProblemException {
      return invalidClientVersion(expectedVersion.format(), clientVersion.format(), cause)
    }

    fun invalidClientVersion(
      expectedVersionString: String,
      clientVersionString: String,
      cause: Throwable? = null,
    ): RestProtocolVersionProblemException {
      return RestProtocolVersionProblemException(
        Cause.InvalidClientVersion,
        cause = cause,
        fallbackMessage = "Client not supported. Expected client version $expectedVersionString but got $clientVersionString",
        parameters = mapOf(
          "expectedVersion" to expectedVersionString,
          "clientVersion" to clientVersionString,
        )
      )
    }
  }
}
