package it.neckar.lizergy.model.configuration.quote.builder

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.stringsPlanning.ModuleReference
import com.meistercharts.charts.lizergy.stringsPlanning.RoofStringsConfiguration
import it.neckar.lizergy.model.configuration.moduleLayout.ModuleLayout.ModuleLayoutId
import it.neckar.lizergy.model.configuration.moduleLayout.roof.ModulesString
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.MpptInputConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterConfiguration.StringConfiguration
import it.neckar.lizergy.model.configuration.quote.builder.InverterType.InverterId
import it.neckar.open.collections.fastForEach
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.plus
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable

interface InverterConfiguration : HasUuid {
  val inverterId: InverterId

  val inverterIndex: Int

  val mpptInputConfigurations: List<MpptInputConfiguration>


  val id: InverterConfigurationId
    get() = InverterConfigurationId(inverterId.uuid + inverterIndex)

  override val uuid: Uuid
    get() = id.uuid

  val numberOfOptimalModules: Int
    get() = mpptInputConfigurations.sumOf { it.numberOfOptimalModules }

  val numberOfPlannedModules: Int
    get() = mpptInputConfigurations.sumOf { it.stringConfigurations.sumOf { it.totalModuleCount } }


  @Serializable
  data class MpptInputConfiguration(val inputIndex: Int, val stringConfigurations: List<StringConfiguration>) {

    val numberOfOptimalModules: Int
      get() = stringConfigurations.sumOf { it.optimalModuleCount ?: 0 }

    val hasLegalOptimalModuleCount: Boolean
      get() {
        val nonNullStringConfigurations = stringConfigurations.filter { it.optimalModuleCount != null }
        return nonNullStringConfigurations.all { stringConfiguration -> nonNullStringConfigurations.all { otherStringConfiguration -> otherStringConfiguration.optimalModuleCount == stringConfiguration.optimalModuleCount } }
      }

    fun forTheseLayouts(moduleLayouts: List<ModuleLayoutId>): MpptInputConfiguration {
      return copy(stringConfigurations = stringConfigurations.map { it.forTheseLayouts(moduleLayouts) })
    }

    fun format(): String {
      return buildString {
        append("MPP-Eingang ${inputIndex + 1} (${'A' + inputIndex}): ${stringConfigurations.size} Strings")
        stringConfigurations.forEach { stringConfiguration ->
          append("\n")
          append(stringConfiguration.format())
        }
      }
    }
  }

  @Serializable
  data class StringConfiguration(val stringIndex: Int, val optimalModuleCount: Int?, val modulesStrings: List<ModulesString>?) {

    val totalModuleCount: Int
      get() = modulesStrings?.sumOf { it.numberOfModules } ?: 0

    fun forTheseLayouts(moduleLayouts: List<ModuleLayoutId>): StringConfiguration {
      return copy(modulesStrings = modulesStrings?.filter { moduleLayouts.contains(it.moduleLayoutId) })
    }

    fun format(): String {
      return buildString {
        append("String ${stringIndex + 1}")
        append(" - ${optimalModuleCount ?: "Keine"} Module (Optimal)")
        modulesStrings?.fastForEach { roofString ->
          append("\n")
          append("${roofString.moduleLayoutId}: ${roofString.numberOfModules} Module")
        }
      }
    }
  }

  @Serializable
  data class InverterConfigurationId(@Serializable(with = UuidSerializer::class) val uuid: Uuid) {

    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): InverterConfigurationId {
        return InverterConfigurationId(randomUuid4())
      }
    }
  }
}


fun RoofStringsConfiguration.toRestApiModel(inverterConfigurations: List<ResolvedInverterConfiguration>): List<ResolvedInverterConfiguration> {
  return inverterConfigurations.map { inverterConfiguration ->
    val newMpptInputConfigurations = inverterConfiguration.mpptInputConfigurations.map { mpptInputConfiguration ->
      val newStringConfigurations = mpptInputConfiguration.stringConfigurations.map { stringConfiguration ->
        val newModulesStrings = stringConfiguration.modulesStrings?.map { modulesString ->
          val meisterchartsRoofStrings = this.roofStrings.firstOrNull { it.uuid == modulesString.uuid } ?: return@map modulesString
          val stringOfModules = meisterchartsRoofStrings.modules.map { module ->
            ModuleReference(
              moduleAreaId = module.modulePlacement.moduleArea.id,
              moduleIndex = module.modulePlacement.moduleIndex,
            )
          }
          modulesString.copy(stringOfModules = stringOfModules)
        }
        StringConfiguration(stringConfiguration.stringIndex, stringConfiguration.optimalModuleCount, newModulesStrings)
      }
      MpptInputConfiguration(mpptInputConfiguration.inputIndex, newStringConfigurations)
    }
    inverterConfiguration.copy(mpptInputConfigurations = newMpptInputConfigurations)
  }
}
