package it.neckar.lizergy.version

import it.neckar.open.version.Version
import it.neckar.open.version.VersionInformation

/**
 * Application related information
 *
 */
object LizergyVersions {
  const val monorepoVersion: String = VersionInformation.version

  /**
   * The build date (only day - not the time)
   */
  const val buildDateDay: String = VersionInformation.buildDateDay

  const val gitCommit: String = VersionInformation.gitCommit

  /**
   * Verbose version string that contains the git information
   */
  val gitVersionAsStringVerbose: String
    get() = "$buildDateDay - $gitCommit"

  /**
   * Used for the storage service
   */
  val storage: Version = Version(3, 5, 9)

  val auth: Version = Version(1, 1, 7)

  val comments: Version = Version(1, 0, 0)

  val processStates: Version = Version(1, 0, 3)
}
