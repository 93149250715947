package com.meistercharts.charts.lizergy.stringsPlanning

import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel

/**
 * Represents a model for a roof planning
 */
class PvStringsPlanningModel : PvRoofPlanningModel() {

  val roofStringsConfiguration: RoofStringsConfiguration = RoofStringsConfiguration()

  var selectedString: RoofString? = null


  fun addRoofString(roofString: RoofString) {
    roofStringsConfiguration.add(roofString)
    selectedString = roofString
  }

}
