package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.DomainRelative
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.StrokeLocation
import com.meistercharts.canvas.devicePixelRatio
import com.meistercharts.canvas.layout.cache.BoundsCache
import com.meistercharts.canvas.layout.cache.BoundsMultiCache
import com.meistercharts.canvas.layout.cache.TriangleBoundsMultiCache
import com.meistercharts.canvas.paintable.MultiSizePaintable
import com.meistercharts.canvas.paintable.ObjectFit
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.saved
import com.meistercharts.canvas.stroke
import com.meistercharts.color.Color
import com.meistercharts.range.LinearValueRange
import com.meistercharts.range.ValueRange
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.geometry.AxisOrientationX
import it.neckar.geometry.AxisOrientationY
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.http.Url
import it.neckar.open.kotlin.lang.abs
import it.neckar.open.unit.number.MayBeNegative
import it.neckar.open.unit.si.mm

/**
 * Layer that supports planning of  photo voltaic modules for *one* roof
 */
class PvRoofPlanningLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    model: PvRoofPlanningModel,
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(model), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  /**
   * Finds the (top most) [ModuleArea] at the given location
   */
  fun getModuleAreaAtLocation(location: @Window Coordinates): ModuleArea? {
    return paintingVariables().moduleAreaBounds.findLastIndex(location)?.let { moduleAreaIndex ->
      configuration.model.moduleAreas.moduleAreas[moduleAreaIndex]
    }
  }

  fun getBoundsForModuleArea(moduleArea: ModuleArea): @Window Rectangle {
    val moduleIndex = configuration.model.moduleAreas.moduleAreas.indexOf(moduleArea)
    return paintingVariables().moduleAreaBounds.asRect(moduleIndex)
  }

  /**
   * Finds the (top most) [Module] at the given location
   */
  fun getModuleAtLocation(location: @Window Coordinates): Module? {
    return paintingVariables().moduleBounds.findLastIndex(location)?.let { moduleIndex ->
      configuration.model.modules().visibleModules[moduleIndex]
    }
  }

  fun getBoundsForModule(module: Module): @Window Rectangle {
    val moduleIndex = configuration.model.modules().visibleModules.indexOf(module)
    return paintingVariables().moduleBounds.asRect(moduleIndex)
  }

  /**
   * Finds the (top most) [UnusableArea] at the given location
   */
  fun getUnusableAreaAtLocation(location: @Window Coordinates): UnusableArea? {
    paintingVariables().unusableAreaBounds.findLastIndex(location)?.let { foundIndex ->
      return configuration.model.unusableAreas.unusableAreas[foundIndex]
    }

    paintingVariables().unusableTriangleAreaBounds.findLastIndex(location)?.let { foundIndex ->
      return configuration.model.unusableAreas.unusableAreas[foundIndex]
    }

    return null
  }

  fun getBoundsForUnusableArea(unusableArea: UnusableArea): @Window Rectangle {
    val unusableAreaIndex = configuration.model.unusableAreas.unusableAreas.indexOf(unusableArea)
    val unusableTriangleAreaBounds = paintingVariables().unusableTriangleAreaBounds
    return if (unusableTriangleAreaBounds.rightTriangleType(unusableAreaIndex) != null) {
      unusableTriangleAreaBounds.asRect(unusableAreaIndex)
    } else {
      paintingVariables().unusableAreaBounds.asRect(unusableAreaIndex)
    }
  }

  fun getValueRangeX(): @Domain @mm LinearValueRange {
    return paintingVariables().valueRangeX
  }

  fun getValueRangeY(): @Domain @mm LinearValueRange {
    return paintingVariables().valueRangeY
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator

    //Paint the roof insets
    if (configuration.mode == Mode.Planning) {
      paintingVariables().suggestedRoofArea.let { suggestedRoofArea: @Window BoundsCache ->
        gc.saved {
          //Stroke the area around
          gc.fill(configuration.suggestedRoofInsetsFill)

          gc.beginPath()

          //Outside, counter clockwise

          //Top left
          gc.moveTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )
          //Bottom left
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(1.0),
          )
          //bottom right
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(1.0),
            chartCalculator.contentAreaRelative2windowY(1.0),
          )
          //Top right
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(1.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )
          //Top left
          gc.lineTo(
            chartCalculator.contentAreaRelative2windowX(0.0),
            chartCalculator.contentAreaRelative2windowY(0.0),
          )

          //
          //stamp out the inner part
          //

          //stroke the complete rect (counter clockwise)
          //Top left
          gc.moveTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y,
          )
          //Bottom left
          gc.lineTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y + suggestedRoofArea.height,
          )
          //Bottom right
          gc.lineTo(
            suggestedRoofArea.x + suggestedRoofArea.width,
            suggestedRoofArea.y + suggestedRoofArea.height,
          )
          //Top right
          gc.lineTo(
            suggestedRoofArea.x + suggestedRoofArea.width,
            suggestedRoofArea.y,
          )
          //Top left
          gc.lineTo(
            suggestedRoofArea.x,
            suggestedRoofArea.y,
          )

          gc.closePath()
          gc.fill()
        }

        //Stroke the bounds
        configuration.suggestedRoofInsets.apply(gc)
        gc.strokeRect(suggestedRoofArea.x, suggestedRoofArea.y, suggestedRoofArea.width, suggestedRoofArea.height)
      }
    }

    //Paint all modules
    val modules = configuration.model.modules().visibleModules
    paintingVariables().moduleBounds.fastForEachIndexed { index, x, y, width: @MayBeNegative Double, height: @MayBeNegative Double ->
      val module = modules[index]

      if (module.deleted.not()) {
        gc.saved {
          val multiSizePaintable: MultiSizePaintable = if (module.isVertical()) configuration.panelPaintableVertical else configuration.panelPaintableHorizontal

          val devicePixelRatio = paintingContext.chartSupport.devicePixelRatio
          val paintable: Paintable = multiSizePaintable.sameOrLarger((width * devicePixelRatio).abs(), (height * devicePixelRatio).abs(), paintingContext)

          paintable.paintInBoundingBox(paintingContext, x, y, Direction.TopLeft, 0.0, 0.0, width, height, objectFit = ObjectFit.Fill)
        }
      }

      if (module.deleted.not()) {
        //paint a white border
        gc.stroke(Color.white)
        gc.strokeRect(x, y, width, height, StrokeLocation.Inside)
      }
    }

    //Paint the unusable areas
    val unusableAreas = configuration.model.unusableAreas.unusableAreas
    paintingVariables().unusableAreaBounds.fastForEachIndexed { index, xRectangle, yRectangle, widthRectangle, heightRectangle ->
      val unusableArea = unusableAreas[index]

      //TODO different images

      val triangles = paintingVariables().unusableTriangleAreaBounds
      val x: Double
      val y: Double
      val width: Double
      val height: Double

      if (triangles.rightTriangleType(index) == null) {
        x = xRectangle
        y = yRectangle
        width = widthRectangle
        height = heightRectangle
      } else {
        x = triangles.x(index)
        y = triangles.y(index)
        width = triangles.width(index)
        height = triangles.height(index)
      }

      gc.saved {
        configuration.unusableAreaPainter.paint(paintingContext, x, y, width, height, unusableArea, UnusableAreaPainter.Mode.Default)
      }
    }

  }

  override fun paintingVariables(): PvRoofPlanningPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : PvRoofPlanningPaintingVariables {

    /**
     * The value range for the x axis
     */
    override var valueRangeX: @Domain @mm LinearValueRange = ValueRange.default
    override var valueRangeY: @Domain @mm LinearValueRange = ValueRange.default

    /**
     * The suggested area where the modules should be placed within
     */
    override val suggestedRoofArea: @Window BoundsCache = BoundsCache()

    /**
     * Contains the bounds for the modules
     */
    override val moduleBounds: @Window BoundsMultiCache = BoundsMultiCache()

    /**
     * Contains the bounds for the unusable rectangle areas
     */
    override val unusableAreaBounds: @Window BoundsMultiCache = BoundsMultiCache()

    /**
     * Contains the bounds for the unusable triangle areas
     */
    override val unusableTriangleAreaBounds: @Window TriangleBoundsMultiCache = TriangleBoundsMultiCache()

    override val moduleAreaBounds: @Window BoundsMultiCache = BoundsMultiCache()


    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartCalculator = paintingContext.chartCalculator
      val chartState = paintingContext.chartState

      val stringsPlanningModel = configuration.model
      val roofSize = stringsPlanningModel.roofSize

      valueRangeX = ValueRange.linear(0.0, roofSize.width)
      valueRangeY = ValueRange.linear(0.0, roofSize.height)

      //The roof insets
      val roofInsets = stringsPlanningModel.suggestedRoofInsets
      @DomainRelative val roofInsetsX = 0.0 + valueRangeX.deltaToDomainRelative(
        when (chartState.axisOrientationX) {
          AxisOrientationX.OriginAtLeft -> roofInsets.left
          AxisOrientationX.OriginAtRight -> roofInsets.right
        }
      )

      @DomainRelative val roofInsetsY = 0.0 + valueRangeY.deltaToDomainRelative(
        //Depending on the axis orientation bottom or top should be used
        when (chartState.axisOrientationY) {
          AxisOrientationY.OriginAtBottom -> roofInsets.bottom
          AxisOrientationY.OriginAtTop -> roofInsets.top
        }
      )
      @DomainRelative val roofInsetsWidth = 1 - valueRangeX.deltaToDomainRelative(roofInsets.offsetWidth)
      @DomainRelative val roofInsetsHeight = 1 - valueRangeY.deltaToDomainRelative(roofInsets.offsetHeight)

      suggestedRoofArea.x = chartCalculator.domainRelative2windowX(roofInsetsX)
      suggestedRoofArea.y = chartCalculator.domainRelative2windowY(roofInsetsY)
      suggestedRoofArea.width = chartCalculator.domainRelativeDelta2ZoomedX(roofInsetsWidth)
      suggestedRoofArea.height = chartCalculator.domainRelativeDelta2ZoomedY(roofInsetsHeight)

      //Calculate the module bounds
      val moduleAreas = stringsPlanningModel.moduleAreas
      moduleBounds.ensureSize(moduleAreas.moduleCount) //ensure array sizes
      moduleAreas.fastForEachModuleIndexed { index, module ->
        val moduleLocation: @RoofRelative Coordinates = module.location

        val x = chartCalculator.domain2windowX(moduleLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(module.width.toDouble(), valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(module.height.toDouble(), valueRangeY)

        moduleBounds.x(index, x)
        moduleBounds.y(index, y)

        moduleBounds.width(index, width)
        moduleBounds.height(index, height)
      }

      //Calculate the bounds for the unusable areas
      unusableAreaBounds.ensureSize(stringsPlanningModel.unusableAreas.count) //ensure array sizes
      unusableTriangleAreaBounds.ensureSize(stringsPlanningModel.unusableAreas.count) //ensure array sizes
      stringsPlanningModel.unusableAreas.unusableAreas.fastForEachIndexed { index, unusableArea ->
        val unusableAreaLocation: @RoofRelative Coordinates = unusableArea.location

        val x = chartCalculator.domain2windowX(unusableAreaLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(unusableAreaLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(unusableArea.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(unusableArea.size.height, valueRangeY)

        if (unusableArea.rightTriangleType != null) {
          unusableTriangleAreaBounds.x(index, x)
          unusableTriangleAreaBounds.y(index, y)
          unusableTriangleAreaBounds.rightTriangleType(index, unusableArea.rightTriangleType ?: return@fastForEachIndexed)

          unusableTriangleAreaBounds.width(index, width)
          unusableTriangleAreaBounds.height(index, height)
        } else {
          unusableAreaBounds.x(index, x)
          unusableAreaBounds.y(index, y)

          unusableAreaBounds.width(index, width)
          unusableAreaBounds.height(index, height)
        }
      }

      moduleAreaBounds.ensureSize(moduleAreas.count) //ensure array sizes
      moduleAreas.moduleAreas.fastForEachIndexed { index, moduleGrid ->
        val moduleGridLocation: @RoofRelative Coordinates = moduleGrid.location

        val x = chartCalculator.domain2windowX(moduleGridLocation.x, valueRangeX)
        val y = chartCalculator.domain2windowY(moduleGridLocation.y, valueRangeY)
        val width = chartCalculator.domainDelta2zoomedX(moduleGrid.size.width, valueRangeX)
        val height = chartCalculator.domainDelta2zoomedY(moduleGrid.size.height, valueRangeY)

        moduleAreaBounds.x(index, x)
        moduleAreaBounds.y(index, y)
        moduleAreaBounds.width(index, width)
        moduleAreaBounds.height(index, height)
      }

    }
  }


  /**
   * The mode
   */
  enum class Mode {
    /**
     * The roof is rendered (e.g. to display to the customer)
     */
    Rendering,

    /**
     * We are in planning mode - additional information is painted
     */
    Planning
  }

  @ConfigurationDsl
  class Configuration(val model: PvRoofPlanningModel) {
    /**
     * The current mode
     */
    var mode: Mode = Mode.Planning //TODO remove mouse over effects when rendering!

    /**
     * The insets for the suggested roof insets
     */
    val suggestedRoofInsetsFill: Color = Color.web("#ff000088")

    /**
     * The line style for the roof insets
     */
    val suggestedRoofInsets: LineStyle = LineStyle(Color.red)

    /**
     * The paintable for the panel
     */
    var panelPaintableVertical: MultiSizePaintable = MultiSizePaintable(
      listOf(
        LocalResourcePaintable(Url.relative("solar/panel-vertical.png"), Size(1334.0, 1860.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_50.png"), Size(930.0, 567.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_25.png"), Size(465.0, 284.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_10.png"), Size(186.0, 113.0)),
        LocalResourcePaintable(Url.relative("solar/panel-vertical_5.png"), Size(93.0, 57.0)),
      )
    )

    var panelPaintableHorizontal: MultiSizePaintable = MultiSizePaintable(
      listOf(
        LocalResourcePaintable(Url.relative("solar/panel-horizontal.png"), Size(1334.0, 1860.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_50.png"), Size(930.0, 567.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_25.png"), Size(465.0, 284.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_10.png"), Size(186.0, 113.0)),
        LocalResourcePaintable(Url.relative("solar/panel-horizontal_5.png"), Size(93.0, 57.0)),
      )
    )

    /**
     * The paintable for unusable areas
     */
    var unusableAreaPainter: UnusableAreaPainter = UnusableAreaPainter()
  }
}
