@file:UseSerializers(UuidSerializer::class)

package it.neckar.lizergy.model.project.previews

import com.benasher44.uuid.Uuid
import it.neckar.customer.company.CompanyCode
import it.neckar.editHistory.PositionEditHistory
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.sum
import it.neckar.lizergy.model.company.UserResolver
import it.neckar.lizergy.model.company.user.UserInformation
import it.neckar.lizergy.model.configuration.PhotovoltaicsConfiguration.PhotovoltaicsConfigurationId
import it.neckar.lizergy.model.configuration.components.BatteryConfiguration.BatteryConfigurationId
import it.neckar.lizergy.model.configuration.moduleLayout.ModulesReport
import it.neckar.lizergy.model.configuration.quote.builder.InverterSelection
import it.neckar.lizergy.model.income.IncomePercentage
import it.neckar.lizergy.model.income.IncomePercentageCategory
import it.neckar.lizergy.model.location.LocationInformation
import it.neckar.lizergy.model.price.EarningsDistribution
import it.neckar.lizergy.model.price.ManualQuoteElements
import it.neckar.lizergy.model.project.BelongsToCompany
import it.neckar.lizergy.model.project.Blueprint.BlueprintId
import it.neckar.lizergy.model.project.OLDProcessState
import it.neckar.lizergy.model.project.PhotovoltaicsProject
import it.neckar.lizergy.model.project.process.state.LizergyProcessStateEntry
import it.neckar.lizergy.model.project.process.state.toNewProcessStateEntry
import it.neckar.lizergy.model.stumps.RestArbeiten
import it.neckar.lizergy.model.validation.ProblemType
import it.neckar.open.kotlin.lang.percent
import it.neckar.processStates.ProcessStatesResolver
import it.neckar.processStates.current
import it.neckar.user.UserLoginName
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Contains a project and some preview values
 *
 * Can be used to load many projects - that can be shown in a table
 */
interface ProjectPreview : PhotovoltaicsProject {
  val blueprintPreview: BlueprintPreview
  val configurationPreviews: List<ConfigurationPreview>
  val restArbeiten: RestArbeiten

  override val blueprintId: Uuid
    get() = blueprintPreview.blueprintId.uuid

  override val configurationIds: List<Uuid>
    get() = configurationPreviews.map { it.configurationId.uuid }
}


@Serializable
sealed interface BlueprintPreview : HasUuid {
  val blueprintId: BlueprintId
  val location: LocationInformation
  val editor: UserLoginName?
  val worstProblem: ProblemType?
  val formattedProblems: String

  override val uuid: Uuid
    get() = blueprintId.uuid
}

@Serializable
data class SerializedBlueprintPreview(
  override val blueprintId: BlueprintId,
  override val location: LocationInformation,
  @Deprecated("Replaced by new Process State Service")
  override val editor: UserLoginName?,
  override val worstProblem: ProblemType? = null,
  override val formattedProblems: String = "",
  @Deprecated("Replaced by new Process State Service")
  val processStateEditHistory: PositionEditHistory<OLDProcessState>?,
) : BlueprintPreview {
  fun resolve(processStatesResolver: ProcessStatesResolver, userResolver: UserResolver): ResolvedBlueprintPreview {
    return ResolvedBlueprintPreview(
      blueprintId = blueprintId,
      location = location,
      editorInformation = editor?.let { userResolver[it] },
      worstProblem = worstProblem,
      formattedProblems = formattedProblems,
      processStateEntries = processStatesResolver.getOrNull(blueprintId.uuid)?.validProcessStateEntries as? List<LizergyProcessStateEntry>,
      processStateEditHistory = processStateEditHistory,
      userResolver = userResolver,
    )
  }
}

@Serializable
data class ResolvedBlueprintPreview(
  override val blueprintId: BlueprintId,
  override val location: LocationInformation,
  override val worstProblem: ProblemType? = null,
  override val formattedProblems: String = "",
  val processStateEntries: List<LizergyProcessStateEntry>?,
  val editorInformation: UserInformation?,
) : BlueprintPreview {

  val processStateEntry: LizergyProcessStateEntry?
    get() = processStateEntries?.current() as? LizergyProcessStateEntry

  constructor(
    blueprintId: BlueprintId,
    location: LocationInformation,
    editorInformation: UserInformation?,
    worstProblem: ProblemType? = null,
    formattedProblems: String = "",
    processStateEntries: List<LizergyProcessStateEntry>?,
    processStateEditHistory: PositionEditHistory<OLDProcessState>? = null,
    userResolver: UserResolver,
  ) : this(
    blueprintId = blueprintId,
    location = location,
    worstProblem = worstProblem,
    formattedProblems = formattedProblems,
    processStateEntries = processStateEntries ?: processStateEditHistory?.allEdits?.map { it.toNewProcessStateEntry(editorInformation) },
    editorInformation = processStateEntries?.current()?.let { userResolver[it.assignedTo] } ?: editorInformation,
  )

  override val editor: UserLoginName?
    get() = editorInformation?.loginName

}

interface ConfigurationPreview : HasUuid, BelongsToCompany {
  val configurationId: PhotovoltaicsConfigurationId
  val location: LocationInformation
  val editor: UserLoginName?
  val modulesReport: ModulesReport
  val inverterSelection: InverterSelection
  val batteryConfigurationId: BatteryConfigurationId?
  val earningsDistribution: EarningsDistribution
  val eigenmontage: Boolean
  val quoteElements: PreviewQuoteElements?
  val manualQuoteElements: ManualQuoteElements
  val worstProblem: ProblemType?
  val formattedProblems: String

  override val uuid: Uuid
    get() = configurationId.uuid

  override val belongsToCompanies: Set<CompanyCode>
    get() = buildSet {
      add(sellingCompany)
      add(earningsDistribution.tippgeber.company)
      add(earningsDistribution.vertriebProjekterfassung.company)
      add(earningsDistribution.vertriebAngebotsvorstellung.company)
      add(earningsDistribution.technischePlanungDach.company)
      add(earningsDistribution.technischePlanungElektrik.company)
      add(earningsDistribution.montageDach.company)
      add(earningsDistribution.montageGeruest.company)
      add(earningsDistribution.elektroInstallation.company)
      add(earningsDistribution.netzvoranfrage.company)
    }

  fun getQuoteElements(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): PreviewQuoteElements {
    val calculatedQuoteElements = getCalculatedQuoteElements(howDoYouLikeYourQuoteElements)
    return PreviewQuoteElements(
      sumsForTags = IncomePercentageCategory.entries.associateWith { incomePercentageCategory ->
        manualQuoteElements.manualSumsForTags[incomePercentageCategory]?.currentValue ?: calculatedQuoteElements?.subTotals(incomePercentageCategory) ?: Money.Zero
      },
      discountPercentage = calculatedQuoteElements?.discountPercentage ?: 0.percent,
    )
  }

  fun getCalculatedQuoteElements(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): PreviewQuoteElements?

  fun getGrossPrice(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return getQuoteElements(howDoYouLikeYourQuoteElements).total
  }

  fun getNetPrice(howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return getQuoteElements(howDoYouLikeYourQuoteElements).netPrice
  }

  fun getNetPrice(category: IncomePercentageCategory, howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return getQuoteElements(howDoYouLikeYourQuoteElements).netPrices(category)
  }

  fun getEarningsForIncomePercentage(incomePercentage: IncomePercentage, howDoYouLikeYourQuoteElements: AccountingProjectPreview.QuoteElements): Money {
    return IncomePercentageCategory.entries.map { category ->
      getNetPrice(category, howDoYouLikeYourQuoteElements) * incomePercentage.getPercentage(category)
    }.sum()
  }
}
